import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: `${i18n.t('nav.main')} | LKB2B`,
    },
  },
  {
    path: '/verify',
    name: 'UserVerify',
    component: () => import('@/views/userVerify/UserVerify.vue'),
    meta: {
      title: 'Подтверждение пользователя | LKB2B',
    },
  },
  {
    path: '/ctns',
    name: 'Ctns',
    component: () => import('@/views/ctns/Ctns.vue'),
    meta: {
      title: 'Управление Номерами | LKB2B',
    },
  },
  {
    path: '/ctns/:id',
    name: 'UserCtns',
    component: () => import('@/views/ctns/Ctns.vue'),
    meta: {
      title: 'Управление Номерами | LKB2B',
    },
  },
  {
    path: '/ctns/ctn-info/:id&:ban',
    name: 'CtnInfo',
    component: () => import('@/views/ctnInfo/CtnInfo.vue'),
    meta: {
      title: 'Информация по номеру | LKB2B',
    },
  },
  {
    path: '/ctns/ctn-info/:id&:ban/roaming',
    name: 'Roaming',
    component: () => import('@/views/roaming/Roaming'),
    meta: {
      title: 'Роуминг | LKB2B',
    },
  },
  {
    path: '/ctns/ctn-info/:id&:ban/detalization',
    name: 'CtnDetalization',
    component: () => import('@/views/detalization/CtnDetalization.vue'),
    meta: {
      title: 'Детализация | LKB2B',
    },
  },
  {
    path: '/ctns/ctn-info/:id&:ban/change-esim',
    name: 'change-esim',
    component: () => import('@/views/changeEsim/CtnChangeEsim.vue'),
    meta: {
      title: 'Замена e-SIM | LKB2B',
    },
  },
  {
    path: '/ctns/ctn-info/:id/services',
    name: 'CtnServices',
    component: () => import('@/views/ctnServices/CtnServices.vue'),
    meta: {
      title: 'Подключение услуг | LKB2B',
    },
  },
  {
    path: '/finance',
    name: 'FinanceInfo',
    component: () => import('@/views/finance/Finance.vue'),
    meta: {
      title: 'Финансовая информация | LKB2B',
    },
  },
  {
    path: '/treports',
    name: 'Reports',
    component: () => import('@/views/reports/Reports.vue'),
    meta: {
      title: 'Отчеты | LKB2B',
    },
  },
  {
    path: '/requests-history',
    name: 'RequestsHistory',
    component: () => import('@/views/requestsHistory/RequestsHistory.vue'),
    meta: {
      title: 'История действий | LKB2B',
    },
  },
  {
    path: '/requests-history/:id',
    name: 'RequestDetails',
    component: () => import('@/views/requestDetails/RequestDetails.vue'),
    meta: {
      title: 'История действий | LKB2B',
    },
  },
  {
    path: '/profile-edit',
    name: 'ProfileEdit',
    component: () => import('@/views/profile/ProfileEdit.vue'),
    meta: {
      title: 'Редактирование Профиля | LKB2B',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Auth.vue'),
    meta: {
      title: 'Авторизация | LKB2B',
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      title: 'FAQ | LKB2B',
    },
  },
  {
    path: '/404',
    name: 'Page Not Found',
    component: () => import('@/views/pageNotFound/PageNotFound.vue'),
    meta: {
      title: 'Страница не найдена | LKB2B',
    },
  },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Navigation Guard
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'LKB2B'
  if (to.name !== 'Login' && !store.getters['profile/isUserLoggedIn']) {
    localStorage.removeItem('token')
    localStorage.removeItem('refresh')
    localStorage.removeItem('user')
    localStorage.removeItem('impersonalize')
    if (to.name === 'UserVerify' && to.query.key)
      next()
    else if (to.name === 'Page Not Found')
      next()
    else
      next({ name: 'Login' })
  }
  else if (store.getters['profile/isUserLoggedIn']) {
    if (to.name === 'Login')
      next('/')
  }
  else {
    next()
  }
  next()
})

export default router
