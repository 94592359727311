import { bus } from '@/main'
import router from '@/router'
import i18n from '@/i18n'

function initialData() {
  return {
    currentSoc: {},
    activationDate: '',
    expirationDate: '',
    isError: false,
    conflictMsg: '',
    isServiceLoading: false,
  }
}

export default {
  namespaced: true,
  state: {
    availableSocs: [],
    userConnectedServices: [],
    agreements: [],
    modalActive: false,
    modalName: '',
    isLoading: false,
    modalTitle: '',
    modalText: '',
    isSuccess: '',
    ...initialData(),
  },
  getters: {
    userConnectedServices: state => state.userConnectedServices,
    conflictMsg: state => state.conflictMsg,
    agreements: state => state.agreements,
    activationDate: state => state.activationDate,
    expirationDate: state => state.expirationDate,
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    isServiceLoading: state => state.isServiceLoading,
    availableSocs: state => state.availableSocs,
    currentSoc: state => state.currentSoc,
    isLoading: state => state.isLoading,
    isError: state => state.isError,
    modalTitle: state => state.modalTitle,
    modalText: state => state.modalText,
    isSuccess: state => state.isSuccess,
    today() {
      return +new Date()
    },
    yesterday() {
      return +new Date(new Date().setDate(new Date().getDate() - 1))
    },
  },
  mutations: {
    MUT_setServicesList(state, services) {
      state.userConnectedServices = services
    },
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_setConflictMsg(state, payload) {
      state.conflictMsg = payload
    },
    MUT_setModalName(state, name) {
      state.modalName = name
    },
    MUT_setCurrentSoc(state, soc) {
      state.currentSoc = soc
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setModalTitle(state, text) {
      state.modalTitle = text
    },
    MUT_setModalText(state, text) {
      state.modalText = text
    },
    MUT_setModalType(state, text) {
      state.isSuccess = text
    },
    MUT_setAvailableSocs(state, socs) {
      state.availableSocs = socs
    },
    MUT_setServiceLoading(state, value) {
      state.isServiceLoading = value
    },
    MUT_setAgreementList(state, payload) {
      state.agreements = payload
    },
    MUT_setSocDate(state, [type, value]) {
      state[type] = value
      value ? (state[type] = bus.dateFromTimeStamp(value)) : (state[type] = '')
    },
    MUT_setError(state, payload) {
      state.isError = payload
    },
    MUT_resetState(state) {
      Object.assign(state, initialData())
    },
  },
  actions: {
    async ACT_getUserServicesList({ commit }, obj) {
      let agreementList = []
      let socList = []
      // Get user socs list
      const socs = await bus.getServicesList({
        subscriberNo: obj.subscriberNo,
        ban: obj.ban,
      })
      if (socs.status === 200)
        socList = [...socs.data.map(item => item.socs).flat(1)]

      // Get user agreement list
      const agreements = await bus.getAgreementsList({
        subscriberNo: obj.subscriberNo,
        ban: obj.ban,
      })
      if (agreements.status === 200)
        agreementList = [...agreements.data]

      commit('MUT_setServicesList', [...socList, ...agreementList])
    },
    async ACT_addUserServices({ commit }, payload) {
      const { status, data } = await bus.checkSocStatus({
        subscriberNo: payload.id,
      })

      if (status === 200) {
        if (data[0].available) {
          await router.push({
            name: 'CtnServices',
            params: { id: payload.id },
            query: { ban: payload.ban },
          })
        }
        else {
          commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
          commit('ctnInfo/MUT_setModalType', false, { root: true })
          commit('ctnInfo/MUT_setErrorTitle', i18n.t('errors.socErrorTitle'), {
            root: true,
          })
          commit('ctnInfo/MUT_setErrorText', i18n.t('errors.socErrorDesc'), {
            root: true,
          })
          commit('ctnInfo/MUT_setModal', true, { root: true })
        }
      }
      else {
        commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
        commit('ctnInfo/MUT_setModalType', false, { root: true })
        commit('ctnInfo/MUT_setErrorTitle', i18n.t('errors.serverErrorTitle'), {
          root: true,
        })
        commit('ctnInfo/MUT_setErrorText', i18n.t('errors.tryAgainLater'), {
          root: true,
        })
        commit('ctnInfo/MUT_setModal', true, { root: true })
      }
    },
    async ACT_removeUserService({ commit, dispatch }, payload) {
      const { status, data } = await bus.checkSocStatus({
        subscriberNo: payload.id,
      })

      if (status === 200) {
        if (data[0].available) {
          dispatch('ACT_removeService', payload.soc)
        }
        else {
          commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
          commit('ctnInfo/MUT_setModalType', false, { root: true })
          commit(
            'ctnInfo/MUT_setErrorTitle',
            i18n.t('errors.socDisconnectErrorTitle'),
            {
              root: true,
            },
          )
          commit(
            'ctnInfo/MUT_setErrorText',
            i18n.t('errors.socDisconnectErrorDesc'),
            { root: true },
          )
          commit('ctnInfo/MUT_setModal', true, { root: true })
        }
      }
      else {
        commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
        commit('ctnInfo/MUT_setModalType', false, { root: true })
        commit('ctnInfo/MUT_setErrorTitle', i18n.t('errors.serverErrorTitle'), {
          root: true,
        })
        commit('ctnInfo/MUT_setErrorText', i18n.t('errors.tryAgainLater'), {
          root: true,
        })
        commit('ctnInfo/MUT_setModal', true, { root: true })
      }
    },
    ACT_setSocDate({ commit }, payload) {
      commit('MUT_setSocDate', [payload.type, payload.value])
    },
    async ACT_getAvailableServices({ commit, dispatch }, obj) {
      commit('MUT_setLoading', true)
      await dispatch('ACT_getAvailableList', obj)
      await dispatch('ACT_getAvailableAgreementList', obj)
      commit('MUT_setLoading', false)
    },
    async ACT_getAvailableAgreementList({ commit }, obj) {
      await bus
        .getAvailableAgreementList(obj)
        .then((res) => {
          commit('MUT_setAgreementList', res.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_connectSoc({ commit, dispatch }, payload) {
      if (payload.soc.agreement) {
        commit('MUT_setCurrentSoc', payload.soc)
        commit('MUT_setModal', true)
        commit('MUT_setModalName', 'add_service')
      }
      else {
        dispatch('ACT_checkFeatureConflicts', payload)
      }
    },
    async ACT_getAvailableList({ commit }, obj) {
      const result = await bus.getAvailableList(obj)
      if (result.status === 200) {
        // Concat same objects by categoryName
        const output = []

        result.data.forEach((item) => {
          const existing = output.filter((v) => {
            return v.categoryName === item.categoryName
          })
          if (existing.length) {
            const existingIndex = output.indexOf(existing[0])
            output[existingIndex].socs = output[existingIndex].socs.concat(
              item.socs,
            )
          }
          else {
            output.push(item)
          }
        })

        commit('MUT_setAvailableSocs', output)
      }
      else {
        commit('MUT_setAvailableSocs', [])
      }
    },
    ACT_addService({ commit }, soc) {
      commit('MUT_setCurrentSoc', soc)
      commit('MUT_setModal', true)
      commit('MUT_setModalName', 'add_service')
    },
    async ACT_removeService({ commit }, soc) {
      if (soc.removeInd === 'N')
        return
      commit('MUT_setCurrentSoc', soc)
      commit('ctnInfo/MUT_setModalName', 'remove_service', { root: true })
      commit('ctnInfo/MUT_setModal', true, { root: true })
    },
    async ACT_removeSocService({ getters, commit, dispatch }, data) {
      if (!getters.expirationDate) {
        commit('MUT_setError', true)
        return
      }
      else {
        commit('MUT_setError', false)
      }

      commit('MUT_setServiceLoading', true)

      const result = await bus.removeSocService({
        banAndSubNoList: [
          {
            ban: data.ban,
            subscriberNo: data.subscriberNo,
          },
        ],
        effectiveDate: bus.dateFromTimeStamp(getters.expirationDate),
        socName: getters.currentSoc.soc,
      })

      const date = new Date(getters.expirationDate)

      if (result.status === 200) {
        if (result.data[0].success) {
          if (date.getTime() > getters.today) {
            commit('ctnInfo/MUT_setModalType', true, { root: true })
            commit(
              'ctnInfo/MUT_setErrorTitle',
              `${i18n.t('services.socDisconnect')} ${bus.dateToDDMMYY(date)}`,
              {
                root: true,
              },
            )
            commit(
              'ctnInfo/MUT_setErrorText',
              `${i18n.t('services.onNumber')} ${data.subscriberNo} ${i18n.t(
                'services.socDisconnectLabel',
              )} «${getters.currentSoc.name || '-'}»`,
              { root: true },
            )
          }
          else {
            commit('ctnInfo/MUT_setModalType', true, { root: true })
            commit(
              'ctnInfo/MUT_setErrorTitle',
              i18n.t('services.socDisconnectSuccess'),
              {
                root: true,
              },
            )
            commit(
              'ctnInfo/MUT_setErrorText',
              `${i18n.t('services.onNumber')} ${data.subscriberNo} ${i18n.t(
                'services.socDisconnectToday',
              )} «${getters.currentSoc.name || '-'}»`,
              { root: true },
            )
          }
        }
        else {
          commit('ctnInfo/MUT_setModalType', false, { root: true })
          commit('ctnInfo/MUT_setErrorTitle', i18n.t('errors.title'), {
            root: true,
          })
          commit(
            'ctnInfo/MUT_setErrorText',
            i18n.t('errors.socDisconnectUnexpectedError'),
            { root: true },
          )
        }
        dispatch('ACT_getUserServicesList', {
          subscriberNo: data.subscriberNo,
          ban: data.ban,
        })
      }
      else {
        commit('ctnInfo/MUT_setModalType', false, { root: true })
        commit('ctnInfo/MUT_setErrorTitle', i18n.t('errors.serverErrorTitle'), {
          root: true,
        })
        commit(
          'ctnInfo/MUT_setErrorText',
          i18n.t('errors.socDisconnectUnexpectedError'),
          { root: true },
        )
      }

      commit('MUT_setServiceLoading', false)
      commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
      commit('ctnInfo/MUT_setModal', true, { root: true })
    },
    async ACT_addAgreement({ getters, commit, dispatch }, data) {
      commit('MUT_setServiceLoading', true)

      const result = await bus.addSocAgreement({
        banAndSubNoList: [
          {
            ban: data.ban,
            subscriberNo: data.subscriberNo,
          },
        ],
        productType: getters.currentSoc.agreementProductType,
        effectiveDate: getters.activationDate,
        socName: getters.currentSoc.agreement,
      })

      commit('MUT_setServiceLoading', false)
      dispatch('ACT_setAddSocModalInfo', { result, data })
    },
    async ACT_checkFeatureConflicts({ commit, dispatch }, data) {
      commit('MUT_setLoading', true)

      const result = await bus.checkFeatureConflicts({
        subscriberNo: data.subscriberNo,
        soc: data.soc.soc,
      })

      commit('MUT_setLoading', false)

      if (result.data[0].message === 'conflict_not_found') {
        dispatch('ACT_addService', data.soc)
      }
      else if (result.data[0].conflictType === '1') {
        commit(
          'MUT_setConflictMsg',
          `${i18n.t('services.socConflictWarning')} «${
            result.data[0].name || '-'
          }»`,
        )
        dispatch('ACT_addService', data.soc)
      }
      else if (result.data[0].message === 'soc_not_available') {
        commit('MUT_setModalName', 'notification')
        commit('MUT_setModal', true)
        commit('MUT_setModalType', false)
        commit('MUT_setModalTitle', i18n.t('services.socNotAvailable'))
        commit('MUT_setModalText', i18n.t('services.socNotificationText'))
      }
    },
    async ACT_addSocService({ getters, commit, dispatch }, data) {
      if (getters.activationDate && getters.expirationDate) {
        if (getters.activationDate > getters.expirationDate) {
          commit('MUT_setError', true)
          return
        }
        else {
          commit('MUT_setError', false)
        }
      }

      commit('MUT_setServiceLoading', true)
      const body = {
        banAndSubNoList: [
          {
            ban: data.ban,
            subscriberNo: data.subscriberNo,
          },
        ],
        effectiveDate: getters.activationDate,
        expirationDate:
          getters.expirationDate.length > 0
            ? bus.dateFromTimeStamp(getters.expirationDate)
            : '',
        socName: getters.currentSoc.soc,
      }

      if (getters.currentSoc.soc === 'ABANDONBO')
        delete body.expirationDate

      const result = await bus.addSocService(body)

      commit('MUT_setServiceLoading', false)
      dispatch('ACT_setAddSocModalInfo', { result, data })
    },
    ACT_setAddSocModalInfo({ getters, commit, dispatch }, payload) {
      const date = new Date(getters.activationDate)

      if (payload.result.status === 200) {
        commit('MUT_setModalName', 'notification')
        if (payload.result.data[0].success) {
          if (date.getTime() > getters.today) {
            commit('MUT_setModalType', true)
            commit(
              'MUT_setModalTitle',
              `${i18n.t('services.socConnect')} ${bus.dateToDDMMYY(date)}!`,
            )
            commit(
              'MUT_setModalText',
              `${i18n.t('services.onNumber')} ${
                payload.data.subscriberNo
              } ${i18n.t('services.socConnectLabel')} «${
                getters.currentSoc.name
              }»`,
            )
          }
          else {
            commit('MUT_setModalType', true)
            commit('MUT_setModalTitle', i18n.t('services.socConnectSuccess'))
            commit(
              'MUT_setModalText',
              `${i18n.t('services.onNumber')} ${
                payload.data.subscriberNo
              } ${i18n.t('services.socConnectDesc')} «${
                getters.currentSoc.name
              }»`,
            )
          }
        }
        else {
          commit('MUT_setModalType', false)
          if (payload.result.data[0].message === 'SOC already present, ER015') {
            commit('MUT_setModalTitle', i18n.t('services.socAlreadyPresent'))
            commit(
              'MUT_setModalText',
              i18n.t('services.socAlreadyPresentDesc'),
            )
          }
          else if (payload.result.data[0].message === 'soc_not_available') {
            commit('MUT_setModalTitle', i18n.t('services.socNotAvailable'))
            commit('MUT_setModalText', i18n.t('services.socNotificationText'))
          }
          else if (
            getters.currentSoc.agreement
            && payload.result.data[0].message === 'unavailable'
          ) {
            commit(
              'MUT_setModalTitle',
              `${i18n.t('services.socRequest1')} ${
                getters.currentSoc.name
              } ${i18n.t('services.socRequest2')} `,
            )
            commit('MUT_setModalText', i18n.t('services.socRequestOption'))
          }
          else {
            commit('MUT_setModalTitle', i18n.t('errors.title'))
            commit(
              'MUT_setModalText',
              i18n.t('errors.socConnectUnexpectedError'),
            )
          }
        }
        dispatch('ACT_getAvailableServices', payload.data)
      }
      else {
        commit('MUT_setModalName', 'notification')
        commit('MUT_setModalType', false)
        commit('MUT_setModalTitle', i18n.t('errors.title'))
        commit('MUT_setModalText', i18n.t('errors.socConnectUnexpectedError'))
      }
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
  },
}
