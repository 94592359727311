import { GET, POST } from '@/helpers/base'

const endpoints = {
  checkEsimStatus: '/api/v1/esim/status',
  checkFreeSim: '/api/v1/esim/available',
  confirmEsimEmail: '/api/v1/esim/replace',
  confirmEsimInstruction: '/api/v1/esim/reserve',
  sendQr: '/api/v1/esim/sendQr',
  cancelEsim: '/api/v1/esim/cancel',
  qrReceived: '/api/v1/esim/complete',
}

const ESimApi = {
  methods: {
    checkEsimStatus(data) {
      const endpoint = endpoints.checkEsimStatus

      return GET(`${endpoint}`, data)
    },
    checkFreeSim(data) {
      const endpoint = endpoints.checkFreeSim

      return GET(`${endpoint}`, data)
    },
    qrReceived(data) {
      const endpoint = endpoints.qrReceived

      return POST(`${endpoint}`, data)
    },
    sendQr(data) {
      const endpoint = endpoints.sendQr

      return POST(`${endpoint}`, data)
    },
    confirmEsimEmail(data) {
      const endpoint = endpoints.confirmEsimEmail

      return POST(`${endpoint}`, data)
    },
    confirmEsimInstruction(data) {
      const endpoint = endpoints.confirmEsimInstruction

      return POST(`${endpoint}`, data)
    },
    cancelEsim(data) {
      const endpoint = endpoints.cancelEsim

      return POST(`${endpoint}`, data)
    },
  },
}

export { ESimApi }
