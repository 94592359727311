import { bus } from '@/main'
import i18n from '@/i18n'

const initialData = {
  questions: [
    {
      title: i18n.t('faq.section1.title'),
      sections: [
        {
          title: i18n.t('faq.section1.info1.title'),
          text: i18n.t('faq.section1.info1.text'),
        },
        {
          title: i18n.t('faq.section1.info2.title'),
          text: i18n.t('faq.section1.info2.text'),
        },
      ],
    },
    {
      title: i18n.t('faq.section2.title'),
      sections: [
        {
          title: i18n.t('faq.section2.info1.title'),
          text: i18n.t('faq.section2.info1.text'),
        },
        {
          title: i18n.t('faq.section2.info2.title'),
          text: i18n.t('faq.section2.info2.text'),
        },
      ],
    },
    {
      title: i18n.t('faq.section3.title'),
      sections: [
        {
          title: i18n.t('faq.section3.info1.title'),
          text: i18n.t('faq.section3.info1.text'),
        },
        {
          title: i18n.t('faq.section3.info2.title'),
          text: i18n.t('faq.section3.info2.text'),
        },
        {
          title: i18n.t('faq.section3.info3.title'),
          text: i18n.t('faq.section3.info3.text'),
        },
        {
          title: i18n.t('faq.section3.info4.title'),
          text: i18n.t('faq.section3.info4.text'),
        },
        {
          title: i18n.t('faq.section3.info5.title'),
          text: i18n.t('faq.section3.info5.text'),
        },
        {
          title: i18n.t('faq.section3.info6.title'),
          text: i18n.t('faq.section3.info6.text'),
        },
        {
          title: i18n.t('faq.section3.info7.title'),
          text: i18n.t('faq.section3.info7.text'),
        },
        {
          title: i18n.t('faq.section3.info8.title'),
          text: i18n.t('faq.section3.info8.text'),
        },
        {
          title: i18n.t('faq.section3.info9.title'),
          text: i18n.t('faq.section3.info9.text'),
        },
      ],
    },
    {
      title: i18n.t('faq.section4.title'),
      sections: [
        {
          title: i18n.t('faq.section4.info1.title'),
          text: i18n.t('faq.section4.info1.text'),
        },
        {
          title: i18n.t('faq.section4.info2.title'),
          text: i18n.t('faq.section4.info2.text'),
        },
        {
          title: i18n.t('faq.section4.info3.title'),
          text: i18n.t('faq.section4.info3.text'),
        },
        {
          title: i18n.t('faq.section4.info4.title'),
          text: i18n.t('faq.section4.info4.text'),
        },
        {
          title: i18n.t('faq.section4.info5.title'),
          text: i18n.t('faq.section4.info5.text'),
        },
        {
          title: i18n.t('faq.section4.info6.title'),
          text: i18n.t('faq.section4.info6.text'),
        },
        {
          title: i18n.t('faq.section4.info7.title'),
          text: i18n.t('faq.section4.info7.text'),
        },
      ],
    },
  ],
  currentSection: {
    title: i18n.t('faq.section1.info1.title'),
    text: i18n.t('faq.section1.info1.text'),
  },
  currentTab: 0,
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    questions: state => state.questions,
    currentSection: state => state.currentSection,
    currentTab: state => state.currentTab,
  },
  mutations: {
    MUT_setCurrentSection(state, payload) {
      state.currentSection = payload
    },
    MUT_setCurrentTab(state, payload) {
      state.currentTab = payload
    },
  },
  actions: {
    ACT_setFaqMark() {
      bus.setFaqMark().catch((error) => {
        return Promise.reject(error)
      })
    },
    ACT_setCurrentTab({ commit }, payload) {
      commit('MUT_setCurrentTab', payload)
    },
    ACT_setCurrentSection({ commit }, section) {
      commit('MUT_setCurrentSection', section)
    },
  },
}
