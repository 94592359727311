import { bus } from '@/main'
import router from '@/router'

const initialData = {
  date: null,
  items: [],
  currentPage: 1,
  totalItems: 0,
  isLoading: true,
  isReportLoading: false,
  isCurrentMonth: false,
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    date: state => state.date,
    items: state => state.items,
    currentPage: state => state.currentPage,
    totalItems: state => state.totalItems,
    isLoading: state => state.isLoading,
    isReportLoading: state => state.isReportLoading,
    isCurrentMonth: state => state.isCurrentMonth,
  },
  mutations: {
    MUT_setDate(state, value) {
      state.date = value
    },
    MUT_setTotalItems(state, payload) {
      state.totalItems = payload
    },
    MUT_setDetalizationItems(state, payload) {
      state.items = payload
    },
    MUT_setPageNumber(state, value) {
      state.currentPage = value
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setReportLoading(state, value) {
      state.isReportLoading = value
    },
    MUT_setIsCurrentMonth(state, value) {
      state.isCurrentMonth = value
    },
  },
  actions: {
    async ACT_getCtnDetalization({ getters, commit }) {
      const params = {
        ban: router.currentRoute.params.ban,
        month: getters.date.month + 1,
        page: getters.currentPage,
        size: 20,
        subscriberNo: router.currentRoute.params.id,
        year: getters.date.year,
      }

      const res = await bus.getCtnUsages(params)

      if (res.status === 200) {
        commit('MUT_setDetalizationItems', res.data.items)
        if (getters.currentPage === 1)
          commit('MUT_setTotalItems', res.data.total)
      }
      else {
        commit('MUT_setDetalizationItems', [])
      }

      commit('MUT_setLoading', false)
    },
    async ACT_setPageNumber({ commit, dispatch }, value) {
      commit('MUT_setLoading', true)
      commit('MUT_setPageNumber', value)
      await dispatch('ACT_getCtnDetalization')
    },
    ACT_setDate({ commit, dispatch }, payload) {
      commit('MUT_setLoading', true)
      commit('MUT_setPageNumber', 1)
      commit('MUT_setDate', payload)
      if (payload.month !== undefined)
        dispatch('ACT_getCtnDetalization')
    },
    async ACT_getDetalisationReport({ getters }) {
      const body = {
        ban: router.currentRoute.params.ban,
        subscriberNo: router.currentRoute.params.id,
        year: getters.date.year,
        month: getters.date.month + 1,
      }
      return await bus.getDetalisationReport(body)
    },
  },
}
