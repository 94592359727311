import axios from 'axios'

const endpoints = {
  sendOtp: '/api/v1/otp/send',
  checkOtp: '/api/v1/otp/check',
}

const OtpApi = {
  methods: {
    sendOtp(data) {
      const endpoint = endpoints.sendOtp

      return axios.post(`${endpoint}`, data)
    },
    checkOtp(data) {
      const endpoint = endpoints.checkOtp

      return axios.post(`${endpoint}`, data)
    },
  },
}

export { OtpApi }
