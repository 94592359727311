import { PUT } from '@/helpers/base'

const endpoints = {
  replaceSim: '/api/v1/sim',
}

const SimApi = {
  methods: {
    replaceSim(data) {
      const endpoint = endpoints.replaceSim

      return PUT(`${endpoint}`, data)
    },
  },
}

export { SimApi }
