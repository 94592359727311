import axios from 'axios'
import { setUserData } from '@/helpers/base'

const endpoints = {
  signIn: '/api/v1/auth/login',
  logOut: '/api/v1/auth/logout',
  resetCredentials: '/api/v1/auth/reset',
  checkUserKey: '/api/v1/user/check-key',
  isExist: '/api/v1/auth/exist',
  isMockShow: '/api/v1/configs/authForm',
}

const AuthApi = {
  methods: {
    isExist({ login, recaptcha }) {
      const endpoint = endpoints.isExist

      return axios.get(`${endpoint}/${login}`, {
        headers: {
          'X-ReCaptcha': recaptcha,
        },
      })
    },
    async signIn({ data, recaptcha }) {
      const endpoint = endpoints.signIn

      const response = await axios.post(`${endpoint}`, data, {
        headers: {
          'X-ReCaptcha': recaptcha,
        },
      })

      if (
        response.status === 200
        && response.data.accessToken
        && response.data.refreshToken
      )
        await setUserData(response.data)

      return response
    },
    logOut(data) {
      const endpoint = endpoints.logOut

      return axios.post(`${endpoint}`, data)
    },
    resetCredentials(data) {
      const endpoint = endpoints.resetCredentials

      return axios.post(`${endpoint}`, data)
    },
    checkUserKey(data) {
      const endpoint = endpoints.checkUserKey

      return axios.post(`${endpoint}`, data)
    },
    isMockShow() {
      const endpoint = endpoints.isMockShow

      return axios.get(`${endpoint}`)
    },
  },
}

export { AuthApi }
