<template>
  <div class="notification">
    <Loader v-if="isLoading" />
    <div>
      <h1 class="notification__title">
        {{ $t("globalNotifications.deleteTitle") }}
      </h1>
      <p class="notification__desc">
        {{ $t("globalNotifications.deleteDesc") }}
      </p>
      <div class="btns__flex">
        <BeeButton
          class="btn btns-flex__item"
          :label="$t('btn.cancel')"
          size="m"
          type="secondary"
          @onClick="MUT_setDeleteModal(false)"
        />
        <BeeButton
          class="btn btns-flex__item"
          :label="$t('btn.delete')"
          size="m"
          type="danger"
          @onClick="ACT_deleteNotification(id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import loader from '@/components/loader/Loader'

export default {
  name: 'NotificationDelete',
  components: {
    Loader: loader,
  },
  props: {
    id: {
      required: true,
    },
  },
  methods: {
    ...mapActions('globalNotifications', ['ACT_deleteNotification']),
    ...mapMutations('globalNotifications', ['MUT_setDeleteModal']),
  },
  computed: {
    ...mapGetters('globalNotifications', ['isLoading']),
  },
}
</script>

<style lang="scss" scoped>
.notification {
  width: 406px;
  overflow: hidden;
  position: relative;
  background: $color-white;
  padding: 20px;
  border-radius: 12px;
  &__title {
    font-size: 18px;
    line-height: 22px;
    color: $color-black;
  }
  &__desc {
    line-height: 22px;
    color: $color-dark-grey;
    margin: 16px 0 24px 0;
  }
}
.btns__flex {
  @include flexbox();
  @include align-items(center);
  gap: 30px;
  .btn {
    font-family: "Roboto", sans-serif;
  }
}
@media (max-width: 992px) {
  .notification {
    width: 100%;
  }
}
</style>
