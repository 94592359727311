const ACCESS_TOKEN = 'token'
const REFRESH_TOKEN = 'refresh'
const USER_ROLE = 'role'
const USER = 'user'

function setAccessToken(value) {
  localStorage.setItem(ACCESS_TOKEN, value)
}

function setRefreshToken(value) {
  localStorage.setItem(REFRESH_TOKEN, value)
}

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN)
}

function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN)
}

function getUserRole() {
  return localStorage.getItem(USER_ROLE)
}
function getUser() {
  return localStorage.getItem(USER)
}

export {
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
  getUserRole,
  getUser,
}
