import { GET, PUT } from '@/helpers/base'

const endpoints = {
  checkCreditLimit: '/api/v1/credit-limit/status',
  changeCreditLimit: '/api/v1/credit-limit',
}

const CreditLimitApi = {
  methods: {
    checkCreditLimit(bans) {
      const endpoint = endpoints.checkCreditLimit

      return GET(`${endpoint}`, bans)
    },
    changeCreditLimit(body) {
      const endpoint = endpoints.changeCreditLimit

      return PUT(`${endpoint}`, body)
    },
  },
}

export { CreditLimitApi }
