import i18n from '../../../i18n'
import { bus } from '@/main'

const initialData = {
  ctns: [],
  searchText: '',
  pageNumber: 1,
  totalCtns: 0,
  size: 10,
  sort: {
    name: '',
    by: '',
  },
  statuses: [
    {
      name: i18n.t('ctns.status.all'),
      value: '',
    },
    {
      name: i18n.t('ctns.status.active'),
      value: 'A',
    },
    {
      name: i18n.t('ctns.status.blocked'),
      value: 'S',
    },
  ],
  selectedCtns: [],
  isAllSelected: false,
  isSelectDisabled: false,
  isCtnsLoading: false,
  cities: [],
  filterArray: [],
  filter: {
    creditLimit: {
      min: '',
      max: '',
    },
    tmpCreditLimit: {
      min: '',
      max: '',
    },
    budget: {
      min: '',
      max: '',
    },
    selectedCities: [],
    status: '',
  },
  modalActive: false,
  modalName: '',
  isBansToChangeLoading: false,
  availableToChangeBans: [],
  blockedToChangeBans: [],
  successfullyChangedBans: [],
  unableToChangedBans: [],
  tmpLimitAmount: '',
  amtLimitAmount: '',
  isCreditlimitSuccess: false,
  creditLimitModalTitle: '',
  creditLimitModalText: '',
  changeInd: 'Y',
  date: '',
  creditLimitStep: 'bans',
  isImpersonalize: false,
  impersonalize: {
    fullName: '',
    role: '',
    hierarchyId: '',
  },
  numbersForReport: [],
  reportType: '',
  email: '',
  errorDate: false,
  typeError: false,
  fromDate: '',
  selectedType: '',
  toDate: '',
  types: [
    {
      name: i18n.t('reports.REPORT_TYPE_1'),
      value: 'REPORT_TYPE_1',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_2'),
      value: 'REPORT_TYPE_2',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_3'),
      value: 'REPORT_TYPE_3',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_4'),
      value: 'REPORT_TYPE_4',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_5'),
      value: 'REPORT_TYPE_5',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_6'),
      value: 'REPORT_TYPE_6',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_7'),
      value: 'REPORT_TYPE_7',
    },
  ],
}

export default {
  namespaced: true,
  state: {
    ...initialData,
    pageNumberBreadCrambs: 1,
    tableColumnSettings: {
      budget: false,
      initCrdlmt: false,
    },
    isColumnSettingsShow: false,
  },
  getters: {
    filterArray: state => state.filterArray,
    creditLimitStep: state => state.creditLimitStep,
    reportType: state => state.reportType,
    errorDate: state => state.errorDate,
    isCreditlimitSuccess: state => state.isCreditlimitSuccess,
    typeError: state => state.typeError,
    types: state => state.types,
    email: state => state.email,
    fromDate: state => state.fromDate,
    selectedType: state => state.selectedType,
    toDate: state => state.toDate,
    date: state => state.date,
    modalName: state => state.modalName,
    selectedCities: state => state.filter.selectedCities,
    changeInd: state => state.changeInd,
    tmpLimitAmount: state => state.tmpLimitAmount,
    amtLimitAmount: state => state.amtLimitAmount,
    isBansToChangeLoading: state => state.isBansToChangeLoading,
    availableToChangeBans: state => state.availableToChangeBans,
    blockedToChangeBans: state => state.blockedToChangeBans,
    creditLimitMin: state => state.filter.creditLimit.min,
    creditLimitMax: state => state.filter.creditLimit.max,
    tmpCreditLimitMin: state => state.filter.tmpCreditLimit.min,
    tmpCreditLimitMax: state => state.filter.tmpCreditLimit.max,
    budgetMin: state => state.filter.budget.min,
    budgetMax: state => state.filter.budget.max,
    status: state => state.filter.status,
    statuses: state => state.statuses,
    pageNumber: state => state.pageNumber,
    pageNumberBreadCrambs: state => state.pageNumberBreadCrambs,
    numbersForReport: state => state.numbersForReport,
    size: state => state.size,
    sort: state => state.sort,
    cities: state => state.cities,
    isCtnsLoading: state => state.isCtnsLoading,
    isAllSelected: state => state.isAllSelected,
    isSelectDisabled: state => state.isSelectDisabled,
    selectedCtns: state => state.selectedCtns,
    searchText: state => state.searchText,
    ctns: state => state.ctns,
    successfullyChangedBans: state => state.successfullyChangedBans,
    unableToChangedBans: state => state.unableToChangedBans,
    creditLimitModalTitle: state => state.creditLimitModalTitle,
    creditLimitModalText: state => state.creditLimitModalText,
    totalCtns: state => state.totalCtns,
    modalActive: state => state.modalActive,
    impersonalize: state => state.impersonalize,
    isImpersonalize: state => state.isImpersonalize,
    tableColumnSettings: state => state.tableColumnSettings,
    isColumnSettingsShow: state => state.isColumnSettingsShow,
  },
  mutations: {
    MUT_validateDate(state) {
      state.errorDate = !state.fromDate.length || !state.toDate.length
    },
    MUT_setType(state, type) {
      state.selectedType = type
    },
    MUT_validateType(state) {
      !state.selectedType.length
        ? (state.typeError = true)
        : (state.typeError = false)
    },
    MUT_setSuccessChangedBans(state, value) {
      state.successfullyChangedBans = value
    },
    MUT_setUnableToChangedBans(state, value) {
      state.unableToChangedBans = value
    },
    MUT_setCreditLimitStep(state, step) {
      state.creditLimitStep = step
    },
    MUT_clearFilter(state) {
      state.filterArray = []
      state.filter = {
        creditLimit: {
          min: '',
          max: '',
        },
        tmpCreditLimit: {
          min: '',
          max: '',
        },
        budget: {
          min: '',
          max: '',
        },
        selectedCities: [],
        status: '',
      }
    },
    MUT_setPageNumber(state, value) {
      state.pageNumber = value
    },
    MUT_setPageNumberBreadcrambs(state, value) {
      state.pageNumberBreadCrambs = value
    },
    MUT_setSearchText(state, value) {
      state.searchText = value
    },
    MUT_setCtns(state, data) {
      state.ctns = data
    },
    MUT_setReportType(state, value) {
      state.reportType = value
    },
    MUT_setFromDate(state, date) {
      state.fromDate = bus.dateFromTimeStamp(date)
    },
    MUT_setToDate(state, date) {
      state.toDate = bus.dateFromTimeStamp(date)
    },
    MUT_setCities(state, cities) {
      state.cities = cities
    },
    MUT_setLoadingState(state, value) {
      state.isCtnsLoading = value
    },
    MUT_setTotalCtns(state, amount) {
      state.totalCtns = amount
    },
    MUT_setSelectedCities(state, array) {
      state.filter.selectedCities = array
    },
    MUT_selectCtn(state, ctn) {
      const existingArr = state.selectedCtns.map(a => a.subscriberNo)

      if (existingArr.includes(ctn.subscriberNo)) {
        const itemIndexToDelete = existingArr.findIndex(
          i => i === ctn.subscriberNo,
        )
        state.selectedCtns.splice(itemIndexToDelete, 1)
      }
      else {
        state.selectedCtns.push(ctn)
      }
    },
    MUT_selectAllCtns(state, ctns) {
      state.selectedCtns = [...ctns]
    },
    MUT_clearSelectedCtns(state) {
      state.isAllSelected = false
      state.selectedCtns = []
    },
    MUT_isAllSelected(state) {
      const curentArr = state.ctns.map(i => i.subscriberNo)
      const selectedCtnsArray = state.selectedCtns.map(i => i.subscriberNo)
      function contains() {
        for (let i = 0; i < curentArr.length; i++) {
          if (!selectedCtnsArray.includes(curentArr[i]))
            return false
        }

        return true
      }
      if (contains())
        state.isAllSelected = true
      else
        state.isAllSelected = false
    },
    MUT_isSelectDisabled(state) {
      const curentArr = state.ctns.map(i => i.subscriberNo)
      const selectedCtnsArray = state.selectedCtns.map(i => i.subscriberNo)

      function contains() {
        for (let i = 0; i < curentArr.length; i++) {
          if (!selectedCtnsArray.includes(curentArr[i]))
            return false
        }

        return true
      }
      if (
        state.isAllSelected
        && state.selectedCtns.length === 10
        && contains()
      )
        state.isSelectDisabled = false
      else if (state.selectedCtns.length === 10)
        state.isSelectDisabled = true
      else if (
        !state.isAllSelected
        && state.selectedCtns.length < 10
        && state.page === 1
      )
        state.isSelectDisabled = false
      else
        state.isSelectDisabled = false
    },
    MUT_setEmail(state, email) {
      state.email = email
    },
    MUT_setSortBy(state, value) {
      state.sort.by = value
    },
    MUT_setSortName(state, value) {
      state.sort.name = value
    },
    MUT_setImpersonalization(state, data) {
      state.isImpersonalize = true
      state.impersonalize.fullName = data.name
      state.impersonalize.role = data.role
      state.impersonalize.hierarchyId = data.hierarchyId
    },
    MUT_setCreditLimitMin(state, value) {
      state.filter.creditLimit.min = value
    },
    MUT_setCreditLimitMax(state, value) {
      state.filter.creditLimit.max = value
    },
    MUT_setTmpCreditLimitMin(state, value) {
      state.filter.tmpCreditLimit.min = value
    },
    MUT_setTmpCreditLimitMax(state, value) {
      state.filter.tmpCreditLimit.max = value
    },
    MUT_setBudgetMin(state, value) {
      state.filter.budget.min = value
    },
    MUT_setBudgetMax(state, value) {
      state.filter.budget.max = value
    },
    MUT_changeStatus(state, value) {
      state.filter.status = value
    },
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_setModalName(state, name) {
      state.modalName = name
    },
    MUT_setBansLoading(state, value) {
      state.isBansToChangeLoading = value
    },
    MUT_setAvailableToChangeBans(state, array) {
      state.availableToChangeBans = array
    },
    MUT_setBlockedToChangeBans(state, array) {
      state.blockedToChangeBans = array
    },
    MUT_setTmpLimitAmount(state, value) {
      state.tmpLimitAmount = value
    },
    MUT_setAmtLimitAmount(state, value) {
      state.amtLimitAmount = value
    },
    MUT_setCreditLimitModalTitle(state, value) {
      state.creditLimitModalTitle = value
    },
    MUT_setCreditLimitModalText(state, value) {
      state.creditLimitModalText = value
    },
    MUT_setLimitDate(state, date) {
      date ? (state.date = bus.dateFromTimeStamp(date)) : (state.date = '')
    },
    MUT_setLimitType(state, value) {
      state.changeInd = value
    },
    MUT_applyFilters(state) {
      state.filterArray = []
      if (state.filter.creditLimit.min && state.filter.creditLimit.max) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.amtLimit'),
          text: `${state.filter.creditLimit.min} - ${state.filter.creditLimit.max}`,
        })
      }
      else if (
        state.filter.creditLimit.min
        && !state.filter.creditLimit.max
      ) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.amtLimit'),
          text: `${state.filter.creditLimit.min}`,
        })
      }
      else if (
        !state.filter.creditLimit.min
        && state.filter.creditLimit.max
      ) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.amtLimit'),
          text: `${state.filter.creditLimit.max}`,
        })
      }
      if (state.filter.tmpCreditLimit.min && state.filter.tmpCreditLimit.max) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.tmpLimit'),
          text: `${state.filter.tmpCreditLimit.min} - ${state.filter.tmpCreditLimit.max}`,
        })
      }
      else if (
        state.filter.tmpCreditLimit.min
        && !state.filter.tmpCreditLimit.max
      ) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.tmpLimit'),
          text: `${state.filter.tmpCreditLimit.min}`,
        })
      }
      else if (
        !state.filter.tmpCreditLimit.min
        && state.filter.tmpCreditLimit.max
      ) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.tmpLimit'),
          text: `${state.filter.tmpCreditLimit.max}`,
        })
      }
      if (state.filter.budget.min && state.filter.budget.max) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.budget'),
          text: `${state.filter.budget.min} - ${state.filter.budget.max}`,
        })
      }
      else if (state.filter.budget.min && !state.filter.budget.max) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.budget'),
          text: `${state.filter.budget.min}`,
        })
      }
      else if (!state.filter.budget.min && state.filter.budget.max) {
        state.filterArray.push({
          title: i18n.t('ctns.filter.budget'),
          text: `${state.filter.budget.max}`,
        })
      }
      if (state.filter.status) {
        if (state.filter.status === 'S') {
          state.filterArray.push({
            title: i18n.t('ctns.filter.status'),
            text: i18n.t('ctns.status.blocked'),
          })
        }
        else {
          state.filterArray.push({
            title: i18n.t('ctns.filter.status'),
            text: i18n.t('ctns.status.active'),
          })
        }
      }
      if (state.filter.selectedCities.length > 0) {
        const names = []
        state.filter.selectedCities.map((city) => {
          names.push(city.name)
        })

        state.filterArray.push({
          title: i18n.t('ctns.filter.region'),
          text: names.join(', '),
        })
      }
    },
    MUT_setCreditLimitResultStatus(state, payload) {
      state.isCreditlimitSuccess = payload
    },
    MUT_setNumbersForReport(state, data) {
      state.numbersForReport = data
    },
    MUT_clearReports(state) {
      state.toDate = ''
      state.fromDate = ''
      state.errorDate = false
      state.selectedType = ''
      state.numbersForReport = []
      state.typeError = false
      state.types = [
        {
          name: i18n.t('reports.REPORT_TYPE_1'),
          value: 'REPORT_TYPE_1',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_2'),
          value: 'REPORT_TYPE_2',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_3'),
          value: 'REPORT_TYPE_3',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_4'),
          value: 'REPORT_TYPE_4',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_5'),
          value: 'REPORT_TYPE_5',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_6'),
          value: 'REPORT_TYPE_6',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_7'),
          value: 'REPORT_TYPE_7',
        },
      ]
    },
    MUT_deleteFilterItem(state, name) {
      state.filterArray = state.filterArray.filter((obj) => {
        return obj.title !== name
      })
      switch (name) {
        case i18n.t('ctns.filter.amtLimit'):
          state.filter.creditLimit = {
            min: '',
            max: '',
          }
          break
        case i18n.t('ctns.filter.tmpLimit'):
          state.filter.tmpCreditLimit = {
            min: '',
            max: '',
          }
          break
        case i18n.t('ctns.filter.budget'):
          state.filter.budget = {
            min: '',
            max: '',
          }
          break
        case i18n.t('ctns.filter.region'):
          state.filter.selectedCities = []
          break
        case i18n.t('ctns.filter.status'):
          state.filter.status = ''
          break
      }
    },
    MUT_resetState(state) {
      Object.assign(state, initialData)
    },
    MUT_setTableColumnSettings(state, value) {
      state.tableColumnSettings = value
    },
    MUT_isColumnSettingsShow(state, bool) {
      state.isColumnSettingsShow = bool
    },
  },
  actions: {
    ACT_setSearchText({ commit, dispatch }, payload) {
      commit('MUT_setSearchText', payload)
      if (!payload.length)
        dispatch('ACT_setPageNumber', 1)
    },
    async ACT_getCtns({ getters, commit, dispatch }) {
      commit('MUT_setLoadingState', true)

      let marketCode = []
      if (getters.selectedCities.length > 0) {
        getters.selectedCities.map(item =>
          marketCode.push(item.codes.join(',')),
        )
        marketCode = marketCode.join(',')
      }
      const user = JSON.parse(localStorage.getItem('impersonalize'))
      if (user)
        dispatch('ACT_getUserInfoById', user.id)

      const params = {
        page: getters.pageNumber - 1,
        size: getters.size,
        ...(getters.sort.name.length && {
          sort: `${getters.sort.name},${getters.sort.by}`,
        }),
        ...(getters.creditLimitMin.length && {
          creditLimitAmtFrom: getters.creditLimitMin,
        }),
        ...(getters.creditLimitMax.length && {
          creditLimitAmtTo: getters.creditLimitMax,
        }),
        ...(getters.tmpCreditLimitMin.length && {
          tmpInitCrdlmtFrom: getters.tmpCreditLimitMin,
        }),
        ...(getters.tmpCreditLimitMax.length && {
          tmpInitCrdlmtTo: getters.tmpCreditLimitMax,
        }),
        ...(getters.budgetMin.length && {
          limitOptionAmtFrom: getters.budgetMin,
        }),
        ...(getters.budgetMax.length && {
          limitOptionAmtTo: getters.budgetMax,
        }),
        ...(getters.status.length && {
          subStatus: getters.status,
        }),
        ...(getters.selectedCities.length && {
          marketCode,
        }),
        ...(getters.searchText.length && {
          banOrSubscriberNo: getters.searchText.replace(/\s/g, ''),
        }),
        ...(user && {
          login: user.login,
        }),
      }

      await bus
        .getCtns(params)
        .then((res) => {
          commit('MUT_setLoadingState', false)
          commit('MUT_setCtns', res.data.items)
          commit('MUT_setTotalCtns', res.data.total)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
      commit('MUT_isAllSelected')
      commit('MUT_isSelectDisabled')
    },

    async ACT_deleteFilterItem({ commit, dispatch }, name) {
      commit('MUT_deleteFilterItem', name)
      commit('MUT_clearSelectedCtns')
      await dispatch('ACT_getCtns')
    },
    async ACT_getDetalisationReport({ getters }) {
      let marketCode = []
      if (getters.selectedCities.length > 0) {
        getters.selectedCities.map(item =>
          marketCode.push(item.codes.join(',')),
        )
        marketCode = marketCode.join(',')
      }
      const user = JSON.parse(localStorage.getItem('impersonalize'))

      const params = {
        page: getters.pageNumber - 1,
        size: getters.size,
        ...(getters.sort.name.length && {
          sort: `${getters.sort.name},${getters.sort.by}`,
        }),
        ...(getters.creditLimitMin.length && {
          creditLimitAmtFrom: getters.creditLimitMin,
        }),
        ...(getters.creditLimitMax.length && {
          creditLimitAmtTo: getters.creditLimitMax,
        }),
        ...(getters.tmpCreditLimitMin.length && {
          tmpInitCrdlmtFrom: getters.tmpCreditLimitMin,
        }),
        ...(getters.tmpCreditLimitMax.length && {
          tmpInitCrdlmtTo: getters.tmpCreditLimitMax,
        }),
        ...(getters.budgetMin.length && {
          limitOptionAmtFrom: getters.budgetMin,
        }),
        ...(getters.budgetMax.length && {
          limitOptionAmtTo: getters.budgetMax,
        }),
        ...(getters.status.length && {
          subStatus: getters.status,
        }),
        ...(getters.selectedCities.length && {
          marketCode,
        }),
        ...(getters.searchText.length && {
          banOrSubscriberNo: getters.searchText.replace(/\s/g, ''),
        }),
        ...(user && {
          login: user.login,
        }),
      }

      const res = await bus.getCtnsDetalisationReport(params)

      return res
    },
    async ACT_confirmCreditLimit({ getters, commit }) {
      let bans = []

      if (getters.changeInd === 'Y') {
        bans = [...getters.availableToChangeBans]
      }
      else {
        bans = getters.availableToChangeBans.map((item) => {
          return { ...item, creditLimitAmt: +getters.amtLimitAmount }
        })
      }

      const data = {
        banAndAmtList: bans,
        changeInd: getters.changeInd,
        tmpInitCrdLmt: +getters.tmpLimitAmount,
        tmpInitCrdLmtDate: getters.date,
      }

      commit('MUT_setBansLoading', true)

      const result = await bus.changeCreditLimit(data)

      if (result.status === 200) {
        const changedBans = []
        const unchangedBans = []

        result.data.forEach((item) => {
          (item.success ? changedBans : unchangedBans).push(item)
        })

        commit('MUT_setSuccessChangedBans', changedBans)
        commit('MUT_setUnableToChangedBans', unchangedBans)

        if (changedBans.length > 0) {
          commit(
            'MUT_setCreditLimitModalTitle',
            i18n.t('ctns.creditLimit.creditLimitChanged'),
          )
          commit(
            'MUT_setCreditLimitModalText',
            `${i18n.t('ctns.label.onBans')} ${changedBans
              .map(e => e.ban)
              .join(', ')} ${i18n.t('ctns.label.changesApliedTo')} ${
              getters.changeInd === 'Y'
                ? i18n.t('ctns.label.tmpLimit')
                : i18n.t('ctns.label.amtLimit')
            } ${i18n.t('ctns.label.creditLimitText')}`,
          )
          commit('MUT_setCreditLimitResultStatus', true)
        }
        else {
          commit(
            'MUT_setCreditLimitModalTitle',
            i18n.t('errors.creditLimitErrorTitle'),
          )
          commit(
            'MUT_setCreditLimitModalText',
            `${i18n.t('errors.creditLimitError')} ${bans
              .map(e => e.ban)
              .join(', ')}`,
          )
          commit('MUT_setCreditLimitResultStatus', false)
        }
      }
      else {
        commit('MUT_setCreditLimitModalTitle', i18n.t('errors.title'))
        commit(
          'MUT_setCreditLimitModalText',
          `${i18n.t('errors.creditLimitError')} ${bans
            .map(e => e.ban)
            .join(', ')}`,
        )
        commit('MUT_setCreditLimitResultStatus', false)
      }

      commit('MUT_setCreditLimitStep', 'notification')
    },
    async ACT_searchCtnsByText({ commit, dispatch }) {
      commit('MUT_setPageNumber', 1)
      commit('MUT_clearSelectedCtns')
      await dispatch('ACT_getCtns')
    },
    async ACT_clearFilter({ commit, dispatch }) {
      commit('MUT_clearFilter')
      commit('MUT_setPageNumber', 1)
      await dispatch('ACT_getCtns')
    },
    async ACT_requestDoc({ getters, commit }) {
      commit('MUT_validateDate')
      if (getters.reportType === 'report')
        commit('MUT_validateType')

      if (!getters.errorDate && !getters.typeError) {
        const numbersArray = []
        getters.numbersForReport.map(item =>
          numbersArray.push(item.subscriberNo),
        )
        const data = {
          fromDate: getters.fromDate,
          toDate: getters.toDate,
          numbers: numbersArray,
          ...(getters.reportType === 'report' && {
            type: getters.selectedType,
          }),
        }
        if (getters.reportType === 'invoice') {
          const result = await bus.requestInvoice(data)
          if (result.status === 200) {
            commit('MUT_setEmail', result.data.email)
            commit('MUT_setModalName', 'notification')
          }
          else {
            commit('MUT_setModalName', 'error')
          }
        }
        else if (getters.reportType === 'report') {
          const result = await bus.requestReport(data)
          if (result.status === 200) {
            commit('MUT_setEmail', result.data.email)
            commit('MUT_setModalName', 'notification')
          }
          else {
            commit('MUT_setModalName', 'error')
          }
        }
      }
    },
    async ACT_changeCreditLimit({ commit }, data) {
      commit('MUT_setBansLoading', true)
      commit('MUT_setModal', true)
      commit('MUT_setModalName', 'creditLimit')
      let bans = data.map(item => item.ban)

      // Remove dublicated bans
      bans = [...new Set(bans)]

      bans = bans.join(',')

      await bus
        .checkCreditLimit({ bans })
        .then((res) => {
          let available = []
          const blocked = []

          res.data.forEach((item) => {
            (item.availableToChange ? available : blocked).push(item)
          })

          available = data
            .filter(o1 => available.some(o2 => o1.ban === o2.ban))
            .map(item => ({
              ban: item.ban,
              creditLimitAmt: item.creditLimitAmt,
            }))

          // Remove dublicated objects with same ban
          available = available.filter(
            (value, index, self) =>
              index === self.findIndex(item => item.ban === value.ban),
          )

          commit('MUT_setBansLoading', false)
          commit('MUT_setAvailableToChangeBans', available)
          commit('MUT_setBlockedToChangeBans', blocked)
          commit('MUT_clearSelectedCtns')
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_applySortFilter({ getters, commit, dispatch }, data) {
      if (getters.sort.name === data.name && getters.sort.by === data.value)
        return

      commit('MUT_setSortName', data.name)
      commit('MUT_setSortBy', data.value)
      commit('MUT_clearSelectedCtns')
      await dispatch('ACT_getCtns')
    },
    async ACT_setPageNumber({ commit, dispatch }, value) {
      commit('MUT_setPageNumber', value)
      commit('MUT_setPageNumberBreadcrambs', value)
      await dispatch('ACT_getCtns')
      commit('MUT_isAllSelected')
      commit('MUT_isSelectDisabled')
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
    ACT_clearReports({ commit }) {
      commit('MUT_clearReports')
    },
    async ACT_applyFilters({ commit, dispatch }) {
      commit('MUT_applyFilters')
      commit('MUT_setPageNumber', 1)
      commit('MUT_clearSelectedCtns')
      await dispatch('ACT_getCtns')
    },
    async ACT_getUserInfoById({ commit }, id) {
      await bus
        .getUserById(id)
        .then(({ data }) => {
          commit('MUT_setImpersonalization', {
            name: `${data.secondName} ${data.firstName} ${data.middleName}`,
            role: data.role,
            hierarchyId: data.hierarchyId,
          })
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getCities({ commit }) {
      await bus
        .getCities()
        .then((res) => {
          commit('MUT_setCities', res.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ACT_requestReport({ commit }, data) {
      commit('MUT_setReportType', 'report')
      commit('MUT_setNumbersForReport', data)
      commit('MUT_setModal', true)
      commit('MUT_setModalName', 'report')
    },
    ACT_requestInvoice({ commit }, data) {
      commit('MUT_setReportType', 'invoice')
      commit('MUT_setNumbersForReport', data)
      commit('MUT_setModal', true)
      commit('MUT_setModalName', 'report')
    },
    ACT_selectCtn({ state, commit, dispatch }, ctn) {
      const existingArr = state.selectedCtns.map(a => a.subscriberNo)
      if (
        existingArr.length < 10
        || (existingArr.includes(ctn.subscriberNo) && existingArr.length === 10)
      ) {
        commit('MUT_selectCtn', ctn)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.selectedCtnMaxLength') },
          { root: true },
        )
      }
      commit('MUT_isAllSelected')
      commit('MUT_isSelectDisabled')
    },
    async ACT_selectAllCtns({ state, commit, dispatch }) {
      const isCtnsEqual
        = state.selectedCtns.toString() === state.ctns.toString()
      const curentArr = state.ctns.map(i => i.subscriberNo)
      const selectedCtnsArray = state.selectedCtns.map(i => i.subscriberNo)
      if (state.selectedCtns.length === 0) {
        commit('MUT_selectAllCtns', [...state.ctns])
      }
      else if (
        state.isAllSelected
        && state.selectedCtns.length === state.ctns.length
        && isCtnsEqual
      ) {
        commit('MUT_selectAllCtns', [])
      }
      else if (state.selectedCtns.length < 10 && !state.isAllSelected) {
        const selectedCtn = [...state.selectedCtns]
        for (let i = 0; i < curentArr.length; i++) {
          if (selectedCtn.length !== 10) {
            if (!selectedCtnsArray.includes(state.ctns[i].subscriberNo))
              selectedCtn.push(state.ctns[i])
          }
          else {
            dispatch(
              'ACT_setNotification',
              { type: 'error', text: i18n.t('errors.selectedCtnMaxLength') },
              { root: true },
            )
            break
          }
        }
        commit('MUT_selectAllCtns', selectedCtn)
      }
      else if (state.isAllSelected) {
        const selectedCtn = [...state.selectedCtns]
        for (let i = 0; i < curentArr.length; i++) {
          const mapSelectedCtns = selectedCtn.map(i => i.subscriberNo)
          if (mapSelectedCtns.includes(curentArr[i]))
            selectedCtn.splice(mapSelectedCtns.indexOf(curentArr[i]), 1)
        }
        commit('MUT_selectAllCtns', selectedCtn)
      }
      commit('MUT_isAllSelected')
      commit('MUT_isSelectDisabled')
    },
    ACT_setTableColumnSettings({ commit }, data) {
      localStorage.setItem('tableColumnSettings', JSON.stringify(data))
      commit('MUT_setTableColumnSettings', data)
    },
  },
}
