import { bus } from '@/main'
import i18n from '@/i18n'

const initialData = {
  ctn: {},
  modalName: '',
  modalType: false,
  modalActive: false,
  isLoading: true,
  errorTitle: '',
  errorText: '',
  balance: '',
  currentPricePlan: '',
  creditLimitAmt: '',
  limitOptionAmt: '',
  tmpInitCrdlmt: '',
  tmpInitCrdlmtDt: '',
  realBalance: '',
  restsLoading: false,
  rests: [],
  priPack: [],
  customerInfo: [],
  customerInfoName: '',
  customerInfoEmail: '',
  deviceInfo: '',
  emailValidationError: false,
  budget: [],
  pinPuk: {},
  imeiNo: '',
  imeiRegStatus: '',
  imeiStep: 1,
  userImeiInfo: {},
  abortReq: false,
  trafficBalance: {
    isActive: false,
    textInfo: '',
  },
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    ctn: state => state.ctn,
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    modalType: state => state.modalType,
    ban: state => state.ctn.ban,
    serialNo: state => state.ctn.serialNo,
    simType: state => state.ctn.simType,
    accountType: state => state.ctn.accountType,
    imeiNo: state => state.imeiNo,
    imeiRegStatus: state => state.imeiRegStatus,
    imeiStep: state => state.imeiStep,
    restsLoading: state => state.restsLoading,
    payerBan: state => state.ctn.payerBan,
    payerBanClientName: state => state.ctn.payerBanClientName,
    clientName: state => state.ctn.clientName,
    region: state => state.ctn.marketInfo.name,
    currentPricePlan: state => state.currentPricePlan,
    subStatus: state => state.ctn.subStatus,
    subStatusRsn: state => state.ctn.subStatusRsn,
    subscriberNo: state => state.ctn.subscriberNo,
    isLoading: state => state.isLoading,
    errorText: state => state.errorText,
    errorTitle: state => state.errorTitle,
    balance: state => state.balance,
    realBalance: state => state.realBalance,
    trafficBalance: state => state.trafficBalance,
    creditLimitAmt: state => state.creditLimitAmt,
    limitOptionAmt: state => state.limitOptionAmt,
    tmpInitCrdlmt: state => state.tmpInitCrdlmt,
    tmpInitCrdlmtDt: state => state.tmpInitCrdlmtDt,
    internet: state => state.rests.internet,
    smsMms: state => state.rests.smsMms,
    voice: state => state.rests.voice,
    priPack: state => state.priPack,
    customerInfo: state => state.customerInfo,
    customerInfoName: state => state.customerInfoName,
    customerInfoEmail: state => state.customerInfoEmail,
    deviceInfo: state => state.deviceInfo,
    emailValidationError: state => state.emailValidationError,
    budget: state => state.budget,
    marketCode: state => state.ctn.marketInfo.code,
    pinPuk: state => state.pinPuk,
    userImeiInfo: state => state.userImeiInfo,
    abortReq: state => state.abortReq,
  },
  mutations: {
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_setModalName(state, name) {
      state.modalName = name
    },
    MUT_setModalType(state, value) {
      state.modalType = value
    },
    MUT_setCtnInfo(state, data) {
      state.ctn = data
    },
    MUT_resetState(state) {
      Object.assign(state, initialData)
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setErrorText(state, text) {
      state.errorText = text
    },
    MUT_setRestsLoading(state, value) {
      state.restsLoading = value
    },
    MUT_setErrorTitle(state, text) {
      state.errorTitle = text
    },
    MUT_setCtnRests(state, payload) {
      state.rests = payload
    },
    MUT_setCurrentPricePlan(state, currentPricePlan) {
      state.currentPricePlan = currentPricePlan
    },
    MUT_setCtnBalance(state, data) {
      state.balance = data.balance
      state.creditLimitAmt = data.creditLimitAmt
      state.limitOptionAmt = data.limitOptionAmt
      state.tmpInitCrdlmt = data.tmpInitCrdlmt
      state.tmpInitCrdlmtDt = data.tmpInitCrdlmtDt
      state.realBalance = data.realBalance
    },
    MUT_setPriInfo(state, data) {
      state.priPack = data
    },
    MUT_setCustomerInfo(state, data) {
      state.customerInfo = data
    },

    MUT_setCustomerInfoName(state, val) {
      state.customerInfoName = val
    },
    MUT_setCustomerInfoEmail(state, val) {
      state.customerInfoEmail = val.replace(/\s/g, '')
    },

    MUT_setDeviceInfo(state, val) {
      state.deviceInfo = val
    },
    MUT_setEmailValidationError(state, val) {
      state.emailValidationError = val
    },
    MUT_setBudget(state, val) {
      state.budget = val
    },
    MUT_setPinPuk(state, val) {
      state.pinPuk = val
    },
    MUT_setImeiNo(state, val) {
      state.imeiNo = val
    },
    MUT_setImeiRegStatus(state, val) {
      state.imeiRegStatus = val
    },
    MUT_setImeiStep(state, val) {
      state.imeiStep = val
    },
    MUT_setUserImeiInfo(state, val) {
      state.userImeiInfo = val
    },
    MUT_setAbortReq(state, val) {
      state.abortReq = val
    },
    MUT_setTrafficBalance(state) {
      state.ctn.subStatusRsn.code === 'USO'
        ? (state.trafficBalance.isActive = true)
        : (state.trafficBalance.isActive = false)
      if (state.ctn.accountType === '16' || state.ctn.accountType === '416') {
        state.trafficBalance.textInfo = `${i18n.t(
          'ctnInfo.trafficWarning',
        )} ${Math.ceil(-1 * (state.realBalance - state.tmpInitCrdlmt))} тг`
      }
      else {
        state.trafficBalance.textInfo = `${i18n.t(
          'ctnInfo.trafficWarning1',
        )} ${Math.ceil(-1 * (state.realBalance - state.tmpInitCrdlmt))} тг`
      }
    },
    MUT_validateCustomerEmail(state) {
      !bus.validateEmail(state.customerInfoEmail)
      || state.customerInfoEmail.length > 50
        ? (state.emailValidationError = true)
        : (state.emailValidationError = false)
    },
  },
  actions: {
    ACT_setEmailValue({ commit }, email) {
      commit('MUT_setCustomerInfoEmail', email)
      commit('MUT_validateCustomerEmail', email)
    },
    ACT_blockNumber({ getters, commit, dispatch }) {
      const data = []
      data.push(getters.ctn)

      dispatch('blockUnblockCtn/ACT_blockCtns', data, { root: true })
      commit('MUT_setModalName', 'unblockCtns')
      commit('MUT_setModal', true)
    },
    ACT_unblockNumber({ getters, commit, dispatch }) {
      const data = []
      data.push(getters.ctn)

      dispatch('blockUnblockCtn/ACT_unblockCtns', data, { root: true })
      commit('MUT_setModalName', 'unblockCtns')
      commit('MUT_setModal', true)
    },
    async ACT_getTariffDetails({ commit }, obj) {
      await bus
        .getTariffDetails({ subscriberNo: obj.subscriberNo, ban: obj.ban })
        .then((res) => {
          commit('MUT_setCurrentPricePlan', res.data.currentPricePlan)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getCtnRests({ commit }, subscriberNo) {
      commit('MUT_setRestsLoading', true)
      await bus
        .getCtnRests(subscriberNo)
        .then(({ data }) => {
          commit('MUT_setCtnRests', data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
      commit('MUT_setRestsLoading', false)
    },
    async ACT_getCtnInfo({ commit }, obj) {
      await bus
        .getCtnInfo(obj.subscriberNo, obj.ban)
        .then(({ data }) => {
          commit('MUT_setCtnInfo', data)
          commit('MUT_setLoading', false)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ACT_showPinPuk({ commit }, obj) {
      bus
        .getPinPuk(obj.subscriberNo, obj.ban)
        .then((res) => {
          commit('MUT_setPinPuk', res.data)
          commit('MUT_setModalName', 'pin')
          commit('MUT_setModal', true)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getCtnBalance({ commit }, obj) {
      const result = await bus.getCtnBalance(obj.subscriberNo, obj.ban)
      if (result.status === 200) {
        commit('MUT_setCtnBalance', result.data)
        commit('MUT_setTrafficBalance')
      }
    },
    ACT_changeSimCard({ getters, commit }) {
      if (getters.ctn.subStatus !== 'A') {
        commit(
          'changeSim/MUT_setError',
          {
            title: i18n.t('errors.changeSimNotAvailable'),
            text: i18n.t('errors.changeSimDesc'),
          },
          { root: true },
        )
        commit('changeSim/MUT_changeStep', 'error', { root: true })
      }
      commit('MUT_setModalName', 'changeSim')
      commit('MUT_setModal', true)
    },
    ACT_onRedirect({ commit }) {
      commit('MUT_setModalName', 'redirect')
      commit('MUT_setModal', true)
    },
    ACT_closeModal({ commit }) {
      commit('MUT_setModal', false)
      commit('changeSim/MUT_changeStep', 'selectSimType', { root: true })
    },
    ACT_changeBudget({ getters, commit, dispatch }) {
      dispatch(
        'budget/ACT_changeBudget',
        [{ ban: getters.ban, subscriberNo: getters.subscriberNo }],
        { root: true },
      )
      commit('MUT_setModalName', 'budget')
      commit('MUT_setModal', true)
    },
    ACT_changeLimit({ getters, commit, dispatch }) {
      const data = [
        {
          ban: +getters.ban,
          creditLimitAmt: getters.creditLimitAmt,
          limitOptionAmt: getters.limitOptionAmt,
          tmpInitCrdlmt: getters.tmpInitCrdlmt,
          tmpInitCrdlmtDt: getters.tmpInitCrdlmtDt,
        },
      ]
      dispatch('ctns/ACT_changeCreditLimit', data, { root: true })
      commit('MUT_setModalName', 'credit_limit')
      commit('MUT_setModal', true)
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
    async ACT_getPriInfo({ commit }, ban) {
      await bus
        .getPriInfo(ban)
        .then(({ data }) => {
          commit('MUT_setPriInfo', data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getCustomerInfo({ commit }, obj) {
      await bus
        .getCustomerInfo(obj.subscriberNo, obj.login)
        .then(({ data }) => {
          commit('MUT_setCustomerInfo', data)
          if (data.name !== null)
            commit('MUT_setCustomerInfoName', data.name)
          else
            commit('MUT_setCustomerInfoName', '')

          if (data.email !== null)
            commit('MUT_setCustomerInfoEmail', data.email)
          else
            commit('MUT_setCustomerInfoEmail', '')
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ACT_changeCustomerInfoModal({ commit }) {
      commit('MUT_setModalName', 'changeCustomerInfo')
      commit('MUT_setModal', true)
    },
    ACT_setCustomerInfo({ commit, dispatch }, obj) {
      commit('MUT_setModalName', 'notification')
      commit('MUT_setModal', true)

      if (obj.status === 200) {
        commit('MUT_setModalType', true)
        commit(
          'MUT_setErrorTitle',
          i18n.t('ctnInfo.userChangeInfoSuccessTitle'),
        )
        commit(
          'MUT_setErrorText',
          `${i18n.t('ctnInfo.userChangeInfoSuccessDesc')} ${obj.subscriberNo}`,
        )
        dispatch('ACT_getCustomerInfo', obj)
      }
      else {
        commit('MUT_setModalType', false)
        commit('MUT_setErrorTitle', i18n.t('errors.title'))
        commit('MUT_setErrorText', i18n.t('errors.dataChangeError'))
      }
    },
    async ACT_getDeviceInfo({ commit }, subscriberNo) {
      await bus
        .getDeviceInfo(subscriberNo)
        .then((res) => {
          commit('MUT_setDeviceInfo', res.data.model)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getBudget({ commit }, obj) {
      await bus
        .getBudget(obj)
        .then((res) => {
          commit('MUT_setBudget', res.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getImei({ commit }, subscriberNo) {
      await bus
        .getImei(subscriberNo)
        .then((res) => {
          commit('MUT_setImeiNo', res.data.imei)
          commit('MUT_setImeiRegStatus', res.data.status)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_getUserImeiInfo({ commit, getters }, subscriberNo) {
      commit('MUT_setModalName', 'imeiActions')
      commit('MUT_setModal', true)
      commit('MUT_setImeiStep', 2)
      await bus
        .getUserImeiInfo(subscriberNo)
        .then((res) => {
          if (res.status === 200) {
            const body = res.data
            body.subscriberNo = subscriberNo
            getters.imeiRegStatus === 0
              ? (body.action = 'UNREGISTER')
              : (body.action = 'REGISTER')
            commit('MUT_setUserImeiInfo', body)
            commit('MUT_setImeiStep', 3)
          }
          else {
            commit('MUT_setModalName', 'notification')
            commit('MUT_setModal', true)
            commit('MUT_setModalType', false)
            commit('MUT_setErrorTitle', i18n.t('imei.userInfoErrorTitle'))
            commit('MUT_setErrorText', i18n.t('imei.userInfoErrorDesc'))
            commit('MUT_setImeiStep', 1)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ACT_ImeiActions({ commit, getters, dispatch }) {
      commit('MUT_setAbortReq', false)
      bus.imeiRegAction(getters.userImeiInfo).then(() => {
        commit('MUT_setImeiStep', 4)
        dispatch('ACT_getImeiRegStatus')
      })
    },
    ACT_getImeiRegStatus({ commit, getters, dispatch }) {
      if (!getters.abortReq) {
        bus.getImeiRegStatus(getters.subscriberNo).then((res) => {
          if (res.data.ready) {
            if (res.data.success) {
              commit('MUT_setModalType', true)
              if (getters.userImeiInfo.action === 'UNREGISTER')
                commit('MUT_setErrorTitle', i18n.t('imei.successUnregister'))
              else
                commit('MUT_setErrorTitle', i18n.t('imei.successRegister'))
            }
            else {
              commit('MUT_setModalType', false)
              if (getters.userImeiInfo.action === 'UNREGISTER')
                commit('MUT_setErrorTitle', i18n.t('imei.errorUnregister'))
              else
                commit('MUT_setErrorTitle', i18n.t('imei.errorRegister'))
            }
            commit('MUT_setModalName', 'notification')
            commit('MUT_setModal', true)
            commit('MUT_setErrorText', res.data.result)
            commit('MUT_setImeiStep', 1)
          }
          else {
            setTimeout(() => {
              dispatch('ACT_getImeiRegStatus')
            }, 10000)
          }
          dispatch('ACT_getImei', getters.subscriberNo)
        })
      }
    },
    ACT_clearUserImeiInfo({ commit }) {
      commit('MUT_setUserImeiInfo', {})
    },
  },
}
