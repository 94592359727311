import { bus } from '@/main'

const initialData = {
  modalActive: false,
  modalName: '',
  pageNumber: 1,
  total: 0,
  size: 10,
  users: [],
  isUsersLoading: true,
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    users: state => state.users,
    pageNumber: state => state.pageNumber,
    size: state => state.size,
    total: state => state.total,
    isUsersLoading: state => state.isUsersLoading,
  },
  mutations: {
    MUT_setUsers(state, users) {
      state.users = users
    },
    MUT_setLoadingState(state, value) {
      state.isUsersLoading = value
    },
    MUT_setTotalItems(state, value) {
      state.total = value
    },
    MUT_toggleModal(state, payload) {
      state.modalActive = payload
    },
    MUT_setModalName(state, payload) {
      state.modalName = payload
    },
    MUT_setPageNumber(state, payload) {
      state.pageNumber = payload
    },
    MUT_resetState(state) {
      Object.assign(state, initialData)
    },
  },
  actions: {
    ACT_showModal({ commit }, payload) {
      commit('MUT_setModalName', payload)
      commit('MUT_toggleModal', true)
    },
    ACT_closeModal({ commit }) {
      commit('MUT_setModalName', '')
      commit('MUT_toggleModal', false)
    },
    async ACT_getUsersByHierarchy({ getters, commit, rootGetters }, payload) {
      commit('MUT_setLoadingState', true)

      if (payload.page)
        commit('MUT_setPageNumber', payload.page)

      const params = {
        page: getters.pageNumber - 1,
        roles:
          rootGetters['usersSearch/rolesFilter'].length > 0
            ? rootGetters['usersSearch/rolesFilter'].join(',')
            : rootGetters['usersSearch/accessByRole'].join(','),
        size: getters.size,
        ...(rootGetters['usersSearch/searchText'].length && {
          loginOrEmail: rootGetters['usersSearch/searchText'],
        }),
      }

      await bus
        .getUsersByHierarchy(params)
        .then((res) => {
          commit('MUT_setTotalItems', res.data.total)
          commit('MUT_setUsers', res.data.items)
          commit('MUT_setLoadingState', false)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
  },
}
