import { bus } from '@/main'
import i18n from '@/i18n'

const initialData = {
  budgetStep: 'bans',
  isBansToChangeLoading: false,
  selectedBudgetType: '',
  isRoaming: true,
  isCompanyAccural: false,
  startDate: '',
  endDate: '',
  availableBans: [],
  notAvailableBans: [],
  limitOptions: [],
  limitError: false,
  startDateError: false,
  dateErrorText: '',
  successfullyChangedBans: [],
  unableToChangeBans: [],
  budgetModalTitle: '',
  budgetModalText: '',
  isBudgetSuccess: false,
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    budgetModalTitle: state => state.budgetModalTitle,
    budgetModalText: state => state.budgetModalText,
    isBudgetSuccess: state => state.isBudgetSuccess,
    successfullyChangedBans: state => state.successfullyChangedBans,
    unableToChangeBans: state => state.unableToChangeBans,
    isBansToChangeLoading: state => state.isBansToChangeLoading,
    limitError: state => state.limitError,
    startDateError: state => state.startDateError,
    budgetStep: state => state.budgetStep,
    limitOptions: state => state.limitOptions,
    selectedBudgetType: state => state.selectedBudgetType,
    isRoaming: state => state.isRoaming,
    isCompanyAccural: state => state.isCompanyAccural,
    availableBans: state => state.availableBans,
    notAvailableBans: state => state.notAvailableBans,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    dateErrorText: state => state.dateErrorText,
  },
  mutations: {
    MUT_setBudgetStep(state, payload) {
      state.budgetStep = payload
    },
    MUT_setLimitOptions(state, options) {
      state.limitOptions = options
    },
    MUT_toggleRoaming(state) {
      state.isRoaming = !state.isRoaming
      if (!state.isRoaming) {
        state.startDate = ''
        state.endDate = ''
      }
    },
    MUT_toggleCompanyAccural(state) {
      state.isCompanyAccural = !state.isCompanyAccural
    },
    MUT_setStartDate(state, payload) {
      state.startDate = bus.dateFromTimeStamp(payload)
    },
    MUT_setEndDate(state, payload) {
      state.endDate = bus.dateFromTimeStamp(payload)
    },
    MUT_setBudgetType(state, value) {
      state.selectedBudgetType = value
      state.limitError = false
    },
    MUT_setAvailableBans(state, value) {
      state.availableBans = value
    },
    MUT_validateFields(state) {
      if (state.isRoaming && !state.startDate.length) {
        state.dateErrorText = i18n.t('budget.dateStartError')
        state.startDateError = true
      }
      else {
        state.startDateError = false
        if (
          state.isRoaming
          && new Date(state.startDate).getTime()
          > new Date(state.endDate).getTime()
        ) {
          state.dateErrorText = i18n.t('budget.incorrectDateError')
          state.startDateError = true
        }
        else {
          state.startDateError = false
        }
      }
      !state.selectedBudgetType.length
        ? (state.limitError = true)
        : (state.limitError = false)
    },
    MUT_setNotAvailableBans(state, value) {
      state.notAvailableBans = value
    },
    MUT_setBansLoading(state, value) {
      state.isBansToChangeLoading = value
    },
    MUT_setSuccessChangedBans(state, value) {
      state.successfullyChangedBans = value
    },
    MUT_setUnableToChangedBans(state, value) {
      state.unableToChangeBans = value
    },
    MUT_setBudgetModalTitle(state, value) {
      state.budgetModalTitle = value
    },
    MUT_setBudgetModalText(state, value) {
      state.budgetModalText = value
    },
    MUT_setBudgetResultStatus(state, value) {
      state.isBudgetSuccess = value
    },
    MUT_resetBudget(state) {
      state.startDate = ''
      state.endDate = ''
      state.isRoaming = true
      state.selectedBudgetType = ''
    },
  },
  actions: {
    async ACT_changeBudget({ commit }, data) {
      commit('ctns/MUT_setModal', true, { root: true })
      commit('ctns/MUT_setModalName', 'budget', { root: true })
      const items = data.map(item => ({
        ban: item.ban,
        subscriberNo: item.subscriberNo,
      }))
      commit('MUT_setBansLoading', true)

      await bus
        .checkBudgetToChange(items)
        .then(({ data }) => {
          const available = []
          const blocked = []

          data.forEach((item) => {
            (item.availableToChange ? available : blocked).push(item)
          })

          commit('MUT_setAvailableBans', available)
          commit('MUT_setNotAvailableBans', blocked)
        })
        .catch((error) => {
          return Promise.reject(error)
        })

      commit('MUT_setBansLoading', false)
    },
    ACT_toggleRoaming({ getters, commit }) {
      if (getters.isRoaming) {
        commit('MUT_setStartDate', '')
        commit('MUT_setEndDate', '')
      }
      commit('MUT_toggleRoaming')
    },
    async ACT_getLimitOptions({ commit }) {
      await bus
        .getLimitOptions()
        .then(({ data }) => {
          commit('MUT_setLimitOptions', data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })

      commit('MUT_setBudgetStep', 'select')
    },
    async ACT_setNewBudget({ getters, commit }) {
      commit('MUT_validateFields')

      const data = {
        amount: getters.selectedBudgetType,
        charges: getters.isCompanyAccural,
        ctns: getters.availableBans.map(({ subscriberNo, ban }) => ({
          subscriberNo,
          ban,
        })),
        effectiveDate: getters.startDate,
        expirationDate: getters.endDate,
        roaming: getters.isRoaming,
      }

      if (!getters.startDateError && !getters.limitError) {
        commit('MUT_setBansLoading', true)
        const res = await bus.changeBudget(data)
        commit('MUT_resetBudget')
        if (res.status === 200) {
          const changedBans = []
          const unchangedBans = []

          res.data.forEach((item) => {
            (item.successful ? changedBans : unchangedBans).push(item)
          })

          commit('MUT_setSuccessChangedBans', changedBans)
          commit('MUT_setUnableToChangedBans', unchangedBans)

          if (changedBans.length > 0) {
            commit('MUT_setBudgetModalTitle', i18n.t('budget.budgetChanged'))
            commit(
              'MUT_setBudgetModalText',
              `${i18n.t('budget.successChangebudget')} ${changedBans
                .map(e => e.subscriberNo)
                .join(', ')}.`,
            )
            commit('MUT_setBudgetResultStatus', true)
          }
          else {
            commit(
              'MUT_setBudgetModalTitle',
              i18n.t('budget.failedChangeBudget'),
            )
            commit(
              'MUT_setBudgetModalText',
              `${i18n.t('budget.cantChangeBudget')} ${getters.availableBans
                .map(e => e.subscriberNo)
                .join(', ')}. ${i18n.t('budget.removeNumberHelpText')}`,
            )
            commit('MUT_setBudgetResultStatus', false)
          }
        }
        else {
          commit(
            'MUT_setBudgetModalTitle',
            i18n.t('budget.failedChangeBudget'),
          )
          commit(
            'MUT_setBudgetModalText',
            `${i18n.t('budget.cantChangeBudget')} ${getters.availableBans
              .map(e => e.subscriberNo)
              .join(', ')}. ${i18n.t('budget.removeNumberHelpText')}`,
          )
          commit('MUT_setBudgetResultStatus', false)
        }

        commit('MUT_setBansLoading', false)
        commit('MUT_setBudgetStep', 'notification')
        commit('ctns/MUT_clearSelectedCtns', {}, { root: true })
      }
    },
    ACT_toggleCompanyAccural({ commit }) {
      commit('MUT_toggleCompanyAccural')
    },
    ACT_setStartDate({ commit }, payload) {
      commit('MUT_setStartDate', payload)
    },
    ACT_setEndDate({ commit }, payload) {
      commit('MUT_setEndDate', payload)
    },
  },
}
