import { bus } from '@/main'
import i18n from '@/i18n'

const initialData = {
  userInfo: {},
  modalActive: false,
  modalName: '',
  toDate: '',
  fromDate: '',
  startDate: null,
  endDate: null,
  reqErrorDate: false,
  reqErrorMsg: '',
  errorPhones: false,
  errorDate: false,
  typeError: false,
  selectedType: '',
  emailError: false,
  emailErrorMsg: '',
  phoneError: false,
  phoneErrorMsg: '',
  isSuccess: false,
  notificationTitle: '',
  notificationText: '',
  email: '',
  sections: [
    {
      id: 1,
      title: i18n.t('finance.invoices'),
      data: '',
    },
    {
      id: 2,
      title: i18n.t('finance.getToEmail'),
      data: 'delivery-email',
    },
    {
      id: 3,
      title: i18n.t('finance.acts'),
      data: 'report-reconciliation-act',
    },
  ],
  currentSection: 1,
  phonesArray: [
    {
      value: '',
    },
  ],
  types: [
    {
      name: i18n.t('reports.REPORT_TYPE_1'),
      value: 'REPORT_TYPE_1',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_3'),
      value: 'REPORT_TYPE_3',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_5'),
      value: 'REPORT_TYPE_5',
    },
    {
      name: i18n.t('reports.REPORT_TYPE_7'),
      value: 'REPORT_TYPE_7',
    },
  ],
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    phonesArray: state => state.phonesArray,
    errorPhones: state => state.errorPhones,
    errorDate: state => state.errorDate,
    toDate: state => state.toDate,
    fromDate: state => state.fromDate,
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    types: state => state.types,
    email: state => state.email,
    selectedType: state => state.selectedType,
    typeError: state => state.typeError,
    sections: state => state.sections,
    currentSection: state => state.currentSection,
    userBin: state => state.userInfo.bin,
    userEmail: state => state.userInfo.email,
    userPhone: state => state.userInfo.phoneNumber,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    reqErrorDate: state => state.reqErrorDate,
    reqErrorMsg: state => state.reqErrorMsg,
    emailError: state => state.emailError,
    emailErrorMsg: state => state.emailErrorMsg,
    phoneError: state => state.phoneError,
    phoneErrorMsg: state => state.phoneErrorMsg,
    notificationTitle: state => state.notificationTitle,
    notificationText: state => state.notificationText,
    isSuccess: state => state.isSuccess,
  },
  mutations: {
    MUT_setUserInfo(state, payload) {
      state.userInfo = payload
    },
    MUT_addPhoneInput(state) {
      state.phonesArray.push({ value: '' })
    },
    MUT_removePhoneInput(state, index) {
      state.phonesArray.splice(index, 1)
    },
    MUT_resetErrors(state) {
      state.errorPhones = false
      state.errorDate = false
    },
    MUT_setModalName(state, value) {
      state.modalName = value
    },
    MUT_setType(state, type) {
      state.selectedType = type
    },
    MUT_setEmail(state, email) {
      state.email = email
    },
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_clearPhonesArray(state) {
      state.phonesArray = [{ value: '', error: false }]
    },
    MUT_validateDate(state) {
      state.errorDate = !state.fromDate.length || !state.toDate.length
    },
    MUT_validatePhones(state) {
      state.phonesArray.forEach((phone) => {
        phone.value.length !== 18
          ? (state.errorPhones = true)
          : (state.errorPhones = false)
      })
    },
    MUT_validateType(state) {
      !state.selectedType.length
        ? (state.typeError = true)
        : (state.typeError = false)
    },
    MUT_setFromDate(state, date) {
      state.fromDate = bus.dateFromTimeStamp(date)
    },
    MUT_setToDate(state, date) {
      state.toDate = bus.dateFromTimeStamp(date)
    },
    MUT_setUserValue(state, [type, value]) {
      state.userInfo[type] = value
    },
    MUT_resetState(state) {
      state.toDate = ''
      state.fromDate = ''
      state.errorPhones = false
      state.typeError = false
      state.errorDate = false
      state.selectedType = ''
      state.startDate = ''
      state.endDate = ''
      state.types = [
        {
          name: i18n.t('reports.REPORT_TYPE_1'),
          value: 'REPORT_TYPE_1',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_3'),
          value: 'REPORT_TYPE_3',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_5'),
          value: 'REPORT_TYPE_5',
        },
        {
          name: i18n.t('reports.REPORT_TYPE_7'),
          value: 'REPORT_TYPE_7',
        },
      ]
    },
    MUT_validateFields(state) {
      // validate dates for request
      // const currentYear = new Date().getFullYear();
      // const currentMonth = new Date().getMonth();
      // const startYear = state.startDate.year;
      // const endYear = state.endDate.year;

      // if (!state.startDate || !state.endDate) {
      //   state.reqErrorDate = true;
      //   state.reqErrorMsg = i18n.t("errors.periodError");
      // } else {
      //   if (
      //     (startYear === endYear &&
      //       state.startDate.month > state.endDate.month) ||
      //     startYear < currentYear - 1 ||
      //     (startYear === currentYear - 1 &&
      //       state.startDate.month < currentMonth) ||
      //     endYear > currentYear ||
      //     (endYear === currentYear - 1 && state.endDate.month > currentMonth) ||
      //     state.endDate.month > currentMonth ||
      //     startYear > endYear
      //   ) {
      //     state.reqErrorDate = true;
      //     state.reqErrorMsg = i18n.t("errors.actError");
      //   } else {
      //     state.reqErrorDate = false;
      //   }
      // }

      // validate email for request
      if (
        state.userInfo.email.length === 0
        || !bus.validateEmail(state.userInfo.email)
      ) {
        state.emailError = true
        state.emailErrorMsg = i18n.t('errors.emailError')
      }
      else {
        state.emailError = false
      }

      // validate phone for request
      if (state.userInfo.phoneNumber.length < 18) {
        state.phoneError = true
        state.phoneErrorMsg = i18n.t('errors.phoneNumberError')
      }
      else {
        state.phoneError = false
      }
    },
    MUT_setStartDate(state, payload) {
      state.startDate = payload
    },
    MUT_setEndDate(state, payload) {
      state.endDate = payload
    },
    MUT_setCurrentSection(state, data) {
      state.currentSection = data
    },
    MUT_setNotificationTitle(state, text) {
      state.notificationTitle = text
    },
    MUT_setNotificationText(state, text) {
      state.notificationText = text
    },
    MUT_setNotificationType(state, payload) {
      state.isSuccess = payload
    },
  },
  actions: {
    async ACT_requestActInvoice({ getters, commit }) {
      commit('MUT_validateFields')

      if (!getters.emailError && !getters.phoneError && !getters.reqErrorDate) {
        const body = {
          email: getters.userEmail,
          contactNumber: `7${bus.formatPhoneToRaw(getters.userPhone)}`,
          dateFrom: `${getters.startDate.month + 1}.${getters.startDate.year}`,
          dateTo: `${getters.endDate.month + 1}.${getters.endDate.year}`,
        }

        const { status } = await bus.requestActReport(body)

        commit('MUT_setModal', true)
        commit('MUT_setModalName', 'base-notification')

        if (status === 200) {
          commit('MUT_setNotificationType', true)
          commit('MUT_setNotificationTitle', i18n.t('finance.actText'))
          commit('MUT_setNotificationText', '')
        }
        else {
          commit('MUT_setNotificationType', false)
          commit('MUT_setNotificationTitle', i18n.t('errors.title'))
          commit('MUT_setNotificationText', i18n.t('errors.tryAgainLater'))
        }
      }
    },
    ACT_setUserValue({ commit }, payload) {
      commit('MUT_setUserValue', [payload.type, payload.value])
    },
    ACT_setStartDate({ commit }, payload) {
      commit('MUT_setStartDate', payload)
    },
    ACT_setEndDate({ commit }, payload) {
      commit('MUT_setEndDate', payload)
    },
    async ACT_getProfile({ commit, dispatch }) {
      const { status, data } = await bus.getUserProfile()

      if (status === 200) {
        commit('MUT_setUserInfo', data)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }
    },
    async ACT_requestDoc({ getters, commit }, type) {
      commit('MUT_validatePhones')
      commit('MUT_validateDate')
      if (type === 'report')
        commit('MUT_validateType')

      if (!getters.errorPhones && !getters.errorDate && !getters.typeError) {
        const data = {
          fromDate: getters.fromDate,
          toDate: getters.toDate,
          numbers: getters.phonesArray.map(item =>
            bus.formatPhoneToRaw(item.value),
          ),
          ...(type === 'report' && {
            type: getters.selectedType,
          }),
        }
        if (type === 'invoice') {
          const result = await bus.requestInvoice(data)
          if (result.status === 200) {
            commit('MUT_setEmail', result.data.email)
            commit('MUT_setModalName', 'notification')
          }
          else {
            commit('MUT_setModalName', 'base-notification')
            commit('MUT_setNotificationType', false)
            commit('MUT_setNotificationTitle', i18n.t('errors.title'))
            commit('MUT_setNotificationText', i18n.t('errors.tryAgainLater'))
          }
        }
        else if (type === 'report') {
          const res = await bus.requestReport(data)
          if (res.status === 200) {
            commit('MUT_setEmail', res.data.email)
            commit('MUT_setModalName', 'notification')
            commit('reports/MUT_setModal', true, { root: true })
            commit('reports/MUT_setModalName', 'notification', { root: true })
          }
          else {
            commit('reports/MUT_setModal', true, { root: true })
            commit('MUT_setModalName', 'error')
            commit('reports/MUT_setModalName', 'error', {
              root: true,
            })
            commit('MUT_setNotificationType', false)
            commit('MUT_setNotificationTitle', i18n.t('errors.title'))
            commit('MUT_setNotificationText', i18n.t('errors.tryAgainLater'))
          }
        }
      }
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
      commit('MUT_clearPhonesArray')
    },
  },
}
