import { bus } from '@/main'
import i18n from '@/i18n'

function initialState() {
  return {
    userCreateStep: '',
    initialLogin: '',
    user: {
      role: {
        value: '',
        required: true,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      login: {
        value: '',
        required: true,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      firstName: {
        value: '',
        required: true,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      secondName: {
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      middleName: {
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      companyName: {
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      email: {
        value: '',
        required: true,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      hierarchyId: {
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      bin: {
        notResident: false,
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      phoneNumber: {
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.fieldEmpty'),
      },
      comment: {
        value: '',
        required: false,
        isError: false,
        errorMsg: i18n.t('errors.symbolsLengthError'),
      },
      status: {
        value: '',
      },
      showResetEntryCounter: {
        value: '',
      },
      showResetOtpCounter: {
        value: '',
      },
      reason: {
        value: '',
        isError: false,
        errorMsg: i18n.t('errors.reasonLengthError'),
      },
    },
    selectedHierachyId: '',
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    userId: null,
    userLogin: null,
    actionType: '',
    userActionName: '',
    ...initialState(),
  },
  getters: {
    user: state => state.user,
    userId: state => state.userId,
    userCreateStep: state => state.userCreateStep,
    userLogin: state => state.userLogin,
    actionType: state => state.actionType,
    userActionName: state => state.userActionName,
    initialLogin: state => state.initialLogin,
    filteredRoles: (state) => {
      let roles
      if (
        state.user.role.value === 'CLIENT'
        || state.user.role.value === 'CLIENT_ADMIN'
      )
        roles = ['CLIENT', 'CLIENT_ADMIN']
      else
        roles = ['STAFF', 'STAFF_ADMIN']

      return roles
    },
    firstName: state => state.user.firstName,
    secondName: state => state.user.secondName,
    middleName: state => state.user.middleName,
    email: state => state.user.email,
    login: state => state.user.login,
    userRole: state => state.user.role,
    phoneNumber: state => state.user.phoneNumber,
    status: state => state.user.status,
    reason: state => state.user.reason,
    hierarchyId: state => state.user.hierarchyId,
    selectedHierachyId: state => state.selectedUserHierachyId,
    bin: state => state.user.bin,
    companyName: state => state.user.companyName,
    showResetEntryCounter: state => state.user.showResetEntryCounter,
    showResetOtpCounter: state => state.user.showResetOtpCounter,
    comment: state => state.user.comment,
    isLoading: state => state.isLoading,
  },
  mutations: {
    MUT_setUserInfo(state, data) {
      for (const [key, value] of Object.entries(data))
        state.user[key].value = value

      state.user.phoneNumber.value = bus.formatPhone(
        state.user.phoneNumber.value,
      )

      state.initialLogin = data.login
      state.selectedHierachyId = state.hierarchyId
    },
    MUT_setRequiredField(state, [type, value]) {
      state.user[type].required = value
    },
    MUT_setUserBin(state, payload) {
      state.user.bin.value = payload
    },
    MUT_setUserNotResident(state, bool) {
      state.user.bin.notResident = bool
    },
    MUT_setActionType(state, payload) {
      state.actionType = payload
      state.userActionName = `${state.user.firstName.value} ${state.user.secondName.value} ${state.user.middleName.value}`
    },
    MUT_setCreateUserStep(state, payload) {
      state.userCreateStep = payload
    },
    MUT_setUserValue(state, [type, value]) {
      state.user[type].value = value
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setUserId(state, userId) {
      state.userId = userId
    },
    MUT_setUserLogin(state, login) {
      state.userLogin = login
    },
    MUT_clearUserData(state) {
      Object.assign(state, initialState())
    },
    MUT_checkHierarchyId(state, value) {
      state.user.hierarchyId.isError = value
    },
    MUT_setSelectedHierachyId(state, value) {
      state.selectedHierachyId = value
    },
    MUT_validateActionReason(state) {
      state.user.reason.isError
        = state.user.reason.value.length < 10
        || state.user.reason.value.length > 255
    },
    MUT_validateForm(state) {
      if (
        state.user.role.value === 'CLIENT'
        || state.user.role.value === 'CLIENT_ADMIN'
      ) {
        state.user.hierarchyId.required = true
        state.user.bin.required = true
      }

      // Validate for empty required fields
      const keys = Object.keys(state.user)

      keys.forEach((key) => {
        if (state.user[key].required) {
          !state.user[key].value
            ? (state.user[key].isError = true)
            : (state.user[key].isError = false)
        }
      })

      if (state.user.login.required) {
        // Validate user login
        if (
          state.user.login.value.length
          && !state.user.login.value.match(/^[0-9a-zA-Z]+$/)
        ) {
          state.user.login.isError = true
          state.user.login.errorMsg = i18n.t('errors.loginError')
        }
        else if (!state.user.login.value.length) {
          state.user.login.isError = true
          state.user.login.errorMsg = i18n.t('errors.fieldEmpty')
        }
      }

      if (state.user.bin.required) {
        // Validate user bin
        if (state.user.bin.value.length !== 12 && !state.user.bin.notResident) {
          state.user.bin.isError = true
          state.user.bin.errorMsg = i18n.t('errors.binError')
        }
      }

      // Validate user email
      if (
        state.user.email.value.length
        && !bus.validateEmail(state.user.email.value)
      ) {
        state.user.email.isError = true
        state.user.email.errorMsg = i18n.t('errors.emailError')
      }
      else if (!state.user.email.value.length) {
        state.user.email.isError = true
        state.user.email.errorMsg = i18n.t('errors.fieldEmpty')
      }

      // Validate user phoneNumber
      state.user.phoneNumber.value
      && state.user.phoneNumber.value.length > 0
      && state.user.phoneNumber.value.length < 18
        ? (state.user.phoneNumber.isError = true)
        : (state.user.phoneNumber.isError = false)

      // Validate user comment
      state.user.comment.value && state.user.comment.value.length > 255
        ? (state.user.comment.isError = true)
        : (state.user.comment.isError = false)
    },
  },
  actions: {
    async ACT_createUser({ getters, commit, dispatch, rootGetters }) {
      if (rootGetters['profile/role'] === 'CLIENT_ADMIN') {
        commit('MUT_setRequiredField', ['login', false])
        commit('MUT_setRequiredField', ['role', false])
      }

      commit('MUT_validateForm')

      if (!bus.validateObjectErrors(getters.user))
        return

      const data = {
        firstName: getters.firstName.value,
        middleName: getters.middleName.value,
        secondName: getters.secondName.value,
        comment: getters.comment.value,
        email: getters.email.value,
        phoneNumber: getters.phoneNumber.value
          ? bus.formatPhoneToRaw(getters.phoneNumber.value)
          : '',
        role:
          rootGetters['profile/role'] === 'CLIENT_ADMIN'
            ? 'CLIENT'
            : getters.userRole.value,
        ...(getters.login.value.length && { login: getters.login.value }),
        ...(getters.hierarchyId.value.length && {
          hierarchyId: getters.hierarchyId.value,
        }),
        ...(getters.bin.value.length && {
          bin: getters.bin.value,
        }),
      }

      const result = await bus.createUser(data)

      if (result.status === 200) {
        commit('MUT_setCreateUserStep', 'notify')
        dispatch('users/ACT_getUsersByHierarchy', {}, { root: true })
      }
      else if (result.data.message === 'login_exist') {
        commit('users/MUT_toggleModal', false, { root: true })
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.loginIsExist') },
          { root: true },
        )
      }
      else {
        commit('users/MUT_toggleModal', false, { root: true })
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }
    },
    async ACT_editUser({ getters, commit, dispatch, rootGetters }) {
      commit('MUT_validateForm')

      if (!bus.validateObjectErrors(getters.user))
        return

      const data = {
        id: getters.userId,
        comment: getters.comment.value,
        email: getters.email.value,
        firstName: getters.firstName.value,
        middleName: getters.middleName.value,
        secondName: getters.secondName.value,
        ...(getters.phoneNumber.value && {
          phoneNumber: bus.formatPhoneToRaw(getters.phoneNumber.value),
        }),
        ...(getters.bin.value && {
          bin: getters.bin.value,
        }),
        role: getters.userRole.value,
        ...(getters.initialLogin !== getters.login.value && {
          login: getters.login.value,
        }),
        ...(rootGetters['profile/role'] === 'STAFF_SUPER_ADMIN' && {
          hierarchyId: getters.hierarchyId.value,
        }),
      }

      const result = await bus.editUser(data)

      if (result.status === 200) {
        dispatch(
          'ACT_setNotification',
          {
            type: 'success',
            text: i18n.t('user.label.profileChangeSuccess'),
          },
          { root: true },
        )
        dispatch('users/ACT_getUsersByHierarchy', {}, { root: true })
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.profileChangeError') },
          { root: true },
        )
      }

      commit('users/MUT_toggleModal', false, { root: true })
    },
    async ACT_getBin({ getters, commit, dispatch }) {
      if (getters.hierarchyId.value.length) {
        commit('MUT_checkHierarchyId', false)

        const { status, data } = await bus.getUserBin(
          getters.hierarchyId.value,
        )

        if (status === 200) {
          commit('MUT_setUserBin', data.bin)
        }
        else {
          dispatch(
            'ACT_setNotification',
            { type: 'error', text: i18n.t('errors.serverError') },
            { root: true },
          )
        }
      }
      else {
        commit('MUT_checkHierarchyId', true)
      }
    },
    async ACT_deleteUser({ commit, getters, dispatch }) {
      const result = await bus.deleteUser(getters.userId)

      if (result.status === 200) {
        dispatch(
          'ACT_setNotification',
          { type: 'success', text: i18n.t('user.label.userIsDeleted') },
          { root: true },
        )
        commit('users/MUT_setPageNumber', 1, { root: true })
        dispatch('users/ACT_getUsersByHierarchy', {}, { root: true })
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.userDeleteError') },
          { root: true },
        )
      }

      commit('users/MUT_toggleModal', false, { root: true })
    },
    ACT_setUserValue({ commit }, payload) {
      if (payload.type === 'login' || payload.type === 'email') {
        commit('MUT_setUserValue', [
          payload.type,
          payload.value.replace(/\s/g, ''),
        ])
      }
      else {
        commit('MUT_setUserValue', [payload.type, payload.value])
      }
      if (payload.type === 'reason')
        commit('MUT_validateActionReason')
    },
    ACT_setUserCreateRole({ commit }) {
      commit('MUT_setCreateUserStep', 'form')
    },
    ACT_setUserToDisplay({ commit, dispatch }, payload) {
      dispatch('ACT_getUser', payload.id)
      commit('MUT_setUserId', payload.id)
      dispatch('users/ACT_showModal', payload.modalName, { root: true })
    },
    ACT_setUserToDelete({ commit, dispatch }, payload) {
      commit('MUT_setUserId', payload.id)
      dispatch('users/ACT_showModal', payload.modalName, { root: true })
    },
    ACT_setUserCreateForm({ commit, dispatch, rootGetters }) {
      dispatch('users/ACT_showModal', 'create_user', { root: true })
      if (
        rootGetters['profile/role'] === 'STAFF_SUPER_ADMIN'
        || rootGetters['profile/role'] === 'STAFF_ADMIN'
      )
        commit('MUT_setCreateUserStep', 'role')
      else
        commit('MUT_setCreateUserStep', 'form')
    },
    ACT_setUserAction({ commit, dispatch }, payload) {
      payload.id
        ? commit('MUT_setUserId', payload.id)
        : commit('MUT_setUserLogin', payload.login)
      commit('MUT_setUserId', payload.id)
      commit('MUT_setActionType', payload.type)
      dispatch('users/ACT_showModal', payload.modalName, { root: true })
    },
    async ACT_resetUserOTP({ getters, commit, dispatch }) {
      if (getters.reason.isError)
        return

      const { status } = await bus.resetOTP({
        comment: getters.reason.value,
        login: getters.userLogin,
      })

      if (status === 200) {
        dispatch(
          'ACT_setNotification',
          { type: 'success', text: i18n.t('user.label.otpIsReset') },
          { root: true },
        )
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.otpResetError') },
          { root: true },
        )
      }

      commit('users/MUT_toggleModal', false, { root: true })
    },
    async ACT_resetUserCounter({ getters, commit, dispatch }) {
      if (getters.reason.isError)
        return

      const { status } = await bus.resetCounter({
        comment: getters.reason.value,
        login: getters.userLogin,
      })

      if (status === 200) {
        dispatch(
          'ACT_setNotification',
          { type: 'success', text: i18n.t('user.label.counterIsReset') },
          { root: true },
        )
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.counterResetError') },
          { root: true },
        )
      }

      commit('users/MUT_toggleModal', false, { root: true })
    },
    async ACT_blockUser({ getters, commit, dispatch }) {
      if (getters.reason.isError)
        return

      const { status } = await bus.blockUser({
        comment: getters.reason.value,
        id: getters.userId,
      })

      if (status === 200) {
        dispatch(
          'ACT_setNotification',
          { type: 'success', text: i18n.t('user.label.userIsBlocked') },
          { root: true },
        )
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.userBlockedError') },
          { root: true },
        )
      }

      commit('users/MUT_toggleModal', false, { root: true })
    },
    async ACT_unblockUser({ getters, commit, dispatch }) {
      if (getters.reason.isError)
        return

      const { status } = await bus.unblockUser({
        comment: getters.reason.value,
        id: getters.userId,
      })

      if (status === 200) {
        dispatch(
          'ACT_setNotification',
          { type: 'success', text: i18n.t('user.label.userIsUnblocked') },
          { root: true },
        )
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.userUnblockedError') },
          { root: true },
        )
      }

      commit('users/MUT_toggleModal', false, { root: true })
    },
    async ACT_getUser({ commit }, id) {
      commit('MUT_setLoading', true)

      const { data } = await bus.getUserById(id)
      commit('MUT_setUserInfo', data)

      commit('MUT_setLoading', false)
    },
    ACT_clearUserData({ commit }) {
      commit('MUT_clearUserData')
    },
  },
}
