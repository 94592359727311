<template>
  <div>
    <div class="global_notification_box">
      <div class="icon-close" @click="ACT_closeAddModal" />
      <div class="global_notification_box__head">
        <h2 class="global_notification_box__title">
          {{ $t("globalNotifications.notifications") }}
        </h2>
      </div>
      <div class="global_notification_form">
        <div class="title-input">
          <BeeInput
            class="notif_ttl"
            :clearable="true"
            :counter="true"
            :has-error="titleLengthError || notificationTitle.length > 100"
            :label="`${$t('globalNotifications.titlePlaceholder')} *`"
            :max="100"
            size="s"
            type="text"
            @input="MUT_setNotificationTitle"
          />
        </div>
        <VueEditor
          v-model="content"
          :editor-toolbar="customToolbar"
        />
      </div>

      <div class="send_notification">
        <BeeButton
          class="btn"
          :disabled="!notificationTitle.length || !notificationBody.length"
          :label="$t('globalNotifications.send')"
          size="m"
          @click.native.stop="ACT_SendNotification"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      name: 'Modal',
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        ['blockquote', 'code-block'],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['link'],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ],
    }
  },
  methods: {
    ...mapMutations('globalNotifications', [
      'MUT_setNotificationTitle',
      'MUT_setNotificationBody',
    ]),
    ...mapActions('globalNotifications', [
      'ACT_closeAddModal',
      'ACT_SendNotification',
    ]),
  },
  computed: {
    ...mapGetters('globalNotifications', [
      'titleLengthError',
      'notificationTitle',
      'notificationBody',
    ]),
    content: {
      get() {
        return this.notificationBody
      },
      set(value) {
        this.MUT_setNotificationBody(value)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.icon-close {
  width: 14px;
  height: 14px;
  background: url("../../assets/icons/xmark.svg");
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 26px;
}
.global_notification_box {
  width: 720px;
  padding: 40px;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #10083f;
    margin-bottom: 32px;
  }
  .title-input {
    margin-bottom: 20px;
  }
  .send_notification {
    margin-top: 20px;
  }
  .nofitication_send_status {
    padding: 20px 20px 20px 52px;
    margin-top: 18px;
    border-radius: 12px;
    border: 1px solid;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    background-position: 18px 50%;
    background-repeat: no-repeat;
    &.success {
      background-image: url("../../assets/icons/checkmark-success.svg");
      background-color: rgba(97, 202, 69, 0.1);
      border-color: rgba(97, 202, 69, 0.5);
    }
    &.error {
      background-image: url("../../assets/icons/xmark-error.svg");
      background-color: rgba(255, 90, 67, 0.1);
      border-color: rgba(255, 90, 67, 0.5);
    }
  }
  .btn {
    font-family: "Roboto", sans-serif;
  }
}

@media (max-width: 992px) {
  .global_notification_box {
    width: 100%;
  }
}
</style>
