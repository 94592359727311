import { bus } from '@/main'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    promoList: [
      {
        id: 1,
        title: i18n.t('promo.promo1.title'),
        secTitle: i18n.t('promo.promo1.secTitle'),
        desc: i18n.t('promo.promo1.desc'),
        gradient: 'linear-gradient(149deg, #E9C825 0%, #F9F3D7 100%)',
        features: [
          {
            title: i18n.t('promo.promo1.features1.title'),
            desc: i18n.t('promo.promo1.features1.desc'),
          },
          {
            title: i18n.t('promo.promo1.features2.title'),
            desc: i18n.t('promo.promo1.features2.desc'),
          },
          {
            title: i18n.t('promo.promo1.features3.title'),
            desc: i18n.t('promo.promo1.features3.desc'),
          },
          {
            title: i18n.t('promo.promo1.features4.title'),
            desc: i18n.t('promo.promo1.features4.desc'),
          },
        ],
      },
      {
        id: 2,
        title: i18n.t('promo.promo2.title'),
        secTitle: i18n.t('promo.promo2.secTitle'),
        desc: i18n.t('promo.promo2.desc'),
        gradient: 'linear-gradient(206deg, #E9C825 0%, #FBF6D8 100%)',
        features: [
          {
            title: i18n.t('promo.promo2.features1.title'),
            desc: i18n.t('promo.promo2.features1.desc'),
          },
          {
            title: i18n.t('promo.promo2.features2.title'),
            desc: i18n.t('promo.promo2.features2.desc'),
          },
          {
            title: i18n.t('promo.promo2.features3.title'),
            desc: i18n.t('promo.promo2.features3.desc'),
          },
          {
            title: i18n.t('promo.promo2.features4.title'),
            desc: i18n.t('promo.promo2.features4.desc'),
          },
        ],
      },
      {
        id: 3,
        title: i18n.t('promo.promo3.title'),
        secTitle: i18n.t('promo.promo3.secTitle'),
        desc: i18n.t('promo.promo3.desc'),
        gradient: 'linear-gradient(149deg, #EAC926 0%, #FCF6D9 72.78%)',
        features: [
          {
            title: i18n.t('promo.promo3.features1.title'),
            desc: i18n.t('promo.promo3.features1.desc'),
          },
          {
            title: i18n.t('promo.promo3.features2.title'),
            desc: i18n.t('promo.promo3.features2.desc'),
          },
          {
            title: i18n.t('promo.promo3.features3.title'),
            desc: i18n.t('promo.promo3.features3.desc'),
          },
          {
            title: i18n.t('promo.promo3.features4.title'),
            desc: i18n.t('promo.promo3.features4.desc'),
          },
        ],
      },
      {
        id: 4,
        title: i18n.t('promo.promo4.title'),
        gradient: 'linear-gradient(219deg, #ECCE37 0%, #FDF7DA 100%)',
        features: [],
      },
      {
        id: 5,
        title: i18n.t('promo.promo5.title'),
        secTitle: i18n.t('promo.promo5.secTitle'),
        desc: i18n.t('promo.promo5.desc'),
        gradient: 'linear-gradient(174deg, #EED348 0%, #FEF8DB 100%)',
        features: [
          {
            title: i18n.t('promo.promo5.features1'),
            desc: '',
          },
          {
            title: i18n.t('promo.promo5.features2'),
            desc: '',
          },
          {
            title: i18n.t('promo.promo5.features3'),
            desc: '',
          },
          {
            title: i18n.t('promo.promo5.features4'),
            desc: '',
          },
        ],
      },
    ],
    curentModal: 'promo',
    selectedPromo: {},
    selectedTariff: '',
    promoName: '',
    promoPhone: '',
    promoEmail: '',
    promoBin: '',
    promoCompanyName: '',
    showModal: false,
    modalTitle: '',
    modalDesc: '',
    modalSuccess: true,
  },
  getters: {
    promoList: state => state.promoList,
    curentModal: state => state.curentModal,
    selectedPromo: state => state.selectedPromo,
    showModal: state => state.showModal,
    selectedTariff: state => state.selectedTariff,
    promoName: state => state.promoName,
    promoPhone: state => state.promoPhone,
    promoEmail: state => state.promoEmail,
    promoBin: state => state.promoBin,
    promoCompanyName: state => state.promoCompanyName,
    modalTitle: state => state.modalTitle,
    modalDesc: state => state.modalDesc,
    modalSuccess: state => state.modalSuccess,
  },
  mutations: {
    MUT_setCurentModal(state, val) {
      state.curentModal = val
    },
    MUT_setModalTitle(state, val) {
      state.modalTitle = val
    },
    MUT_setModalDesc(state, val) {
      state.modalDesc = val
    },
    MUT_setModalSuccess(state, val) {
      state.modalSuccess = val
    },
    MUT_setSelectedPromo(state, val) {
      state.selectedPromo = val
    },
    MUT_setShowModal(state, val) {
      state.showModal = val
    },
    MUT_setSelectedTariff(state, val) {
      state.selectedTariff = val
    },
    MUT_setPromoName(state, val) {
      state.promoName = val
    },
    MUT_setPromoPhone(state, val) {
      state.promoPhone = val
    },
    MUT_setPromoEmail(state, val) {
      state.promoEmail = val
    },
    MUT_setPromoBin(state, val) {
      state.promoBin = val
    },
    MUT_setPromoCompanyName(state, val) {
      state.promoCompanyName = val
    },
  },
  actions: {
    ACT_sendPromoForm({ getters, commit }) {
      const data = {
        name: getters.promoName,
        phoneNumber: getters.promoPhone,
        email: getters.promoEmail,
        binIin: getters.promoBin,
        organizationName: getters.promoCompanyName,
        productName: getters.selectedPromo.title,
      }
      if (getters.selectedPromo.id === 4) {
        data.productName
          = `${getters.selectedPromo.title} ${getters.selectedTariff}`
      }
      bus.sendPromoForm(data).then((res) => {
        commit('MUT_setCurentModal', 'notification')
        if (res.status === 200) {
          commit('MUT_setModalTitle', i18n.t('promo.formSuccess'))
          commit('MUT_setModalDesc', i18n.t('promo.formSuccessDesc'))
          commit('MUT_setModalSuccess', true)
        }
        else {
          commit('MUT_setModalTitle', i18n.t('errors.title'))
          commit('MUT_setModalDesc', i18n.t('errors.tryAgainLater'))
          commit('MUT_setModalSuccess', false)
        }
      })
    },
  },
}
