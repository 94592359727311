const helperMixin = {
  methods: {
    getInitials(firstName, secondName) {
      if (!firstName)
        return

      const first = firstName.split('')[0]
      const second = secondName.split('')[0]

      return (first + (second || '')).toUpperCase()
    },
    validateLatinChar(value) {
      return /(?=.*[a-z,A-Z])/g.test(value)
    },
    validateObjectErrors(obj) {
      const errors = []
      Object.keys(obj).forEach((key) => {
        errors.push(obj[key].isError)
      })

      return !errors.includes(true)
    },
    formatPhone(phone) {
      if (!phone)
        return

      phone = phone.toString()

      return `+7 (${phone.substring(0, 3)}) ${phone.substring(
        3,
        6,
      )} ${phone.substring(6, 8)} ${phone.substring(8)}`
    },
    formatPhoneToRaw(phone) {
      return phone
        .replace(/\D+| |\(|\)/g, '')
        .trim()
        .substring(1, phone.length)
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,9}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/,
        )
    },
    dateFromTimeStamp(timestamp) {
      const date = new Date(timestamp)

      const day = (`0${date.getDate()}`).slice(-2)
      const month = (`0${date.getMonth() + 1}`).slice(-2)
      const year = date.getFullYear()

      return `${year}-${month}-${day}`
    },
    dateToDDMMYY(date) {
      const day = (`0${date.getDate()}`).slice(-2)
      const month = (`0${date.getMonth() + 1}`).slice(-2)
      const year = date.getFullYear()

      return `${day}.${month}.${year}`
    },
    dateToDDMMYYTT(date) {
      const d = new Date(date)
      const day = (`0${d.getDate()}`).slice(-2)
      const month = (`0${d.getMonth() + 1}`).slice(-2)
      const year = d.getFullYear()
      const time = `${(`0${d.getHours()}`).slice(-2)}:${(
        `0${d.getMinutes()}`
      ).slice(-2)}:${(`0${d.getSeconds()}`).slice(-2)}`

      return `${day}.${month}.${year} | ${time}`
    },
  },
}

export { helperMixin }
