import { bus } from '@/main'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    notificationsList: [],
    notificationCounter: 0,
    notificationTitle: '',
    notificationBody: '',
    titleLengthError: false,
    modalActive: false,
    isAddModalActive: false,
    isDeleteModalActive: false,
    isLoading: false,
    singleModalActive: false,
  },
  getters: {
    step: state => state.step,
    notificationsList: state => state.notificationsList,
    notificationCounter: state => state.notificationCounter,
    notificationTitle: state => state.notificationTitle,
    notificationBody: state => state.notificationBody,
    titleLengthError: state => state.titleLengthError,
    isModalActive: state => state.modalActive,
    isDeleteModalActive: state => state.isDeleteModalActive,
    isAddModalActive: state => state.isAddModalActive,
    isLoading: state => state.isLoading,
    singleModalActive: state => state.singleModalActive,
  },
  mutations: {
    MUT_setNotificationsList(state, array) {
      state.notificationsList = array
    },
    MUT_setNotifiCationcounter(state, val) {
      state.notificationCounter = val
    },
    MUT_setNotificationTitle(state, title) {
      state.notificationTitle = title
      state.titleLengthError = false
    },
    MUT_setNotificationBody(state, body) {
      state.notificationBody = body
    },
    MUT_setTitleLengthError(state, error) {
      state.titleLengthError = error
    },
    MUT_setModal(state, bool) {
      state.modalActive = bool
    },
    MUT_setAddModal(state, bool) {
      state.isAddModalActive = bool
    },
    MUT_setDeleteModal(state, bool) {
      state.isDeleteModalActive = bool
    },
    MUT_setSingleModalActive(state, bool) {
      state.singleModalActive = bool
    },
  },
  actions: {
    ACT_closeAddModal({ commit }) {
      commit('MUT_setAddModal', false)
      commit('MUT_setNotificationBody', '')
      commit('MUT_setNotificationTitle', '')
      commit('MUT_setTitleLengthError', false)
    },
    async ACT_deleteNotification({ commit, dispatch }, id) {
      const { status } = await bus.deleteGlobalNotification(id)
      commit('MUT_setDeleteModal', false)
      if (status === 200) {
        dispatch(
          'ACT_setNotification',
          {
            type: 'success',
            text: i18n.t('globalNotifications.successDeleteText'),
          },
          { root: true },
        )
      }
      else {
        dispatch(
          'ACT_setNotification',
          {
            type: 'error',
            text: i18n.t('errors.serverError'),
          },
          { root: true },
        )
      }
      dispatch('ACT_getNotificationsList')
    },
    async ACT_getNotificationsList({ commit, dispatch }) {
      await bus
        .getGlobalNotificationsList()
        .then((res) => {
          commit('MUT_setNotificationsList', res.data)
          const unreaded = res.data.filter((item) => {
            return item.viewed === false
          })
          if (!res.data[0].viewed)
            commit('MUT_setSingleModalActive', true)

          commit('MUT_setNotifiCationcounter', unreaded.length)
          setTimeout(() => {
            dispatch('ACT_getNotificationsList')
          }, 120000)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_SendNotification({ dispatch, commit, getters }) {
      if (
        getters.notificationTitle.length > 0
        && getters.notificationTitle.length <= 100
      ) {
        const body = {
          title: getters.notificationTitle,
          description: getters.notificationBody,
        }
        await bus
          .addGlobalNotifivation(body)
          .then((res) => {
            if (res.status === 200) {
              dispatch('ACT_getNotificationsList')
              commit('MUT_setNotificationBody', '')
              commit('MUT_setNotificationTitle', '')
              commit('MUT_setTitleLengthError', false)
              dispatch(
                'ACT_setNotification',
                {
                  type: 'success',
                  text: i18n.t('globalNotifications.successText'),
                },
                { root: true },
              )
            }
            else {
              dispatch(
                'ACT_setNotification',
                {
                  type: 'error',
                  text: i18n.t('globalNotifications.errorText'),
                },
                { root: true },
              )
            }
          })
          .catch((error) => {
            return Promise.reject(error)
          })
        commit('MUT_setAddModal', false)
      }
      else {
        if (
          getters.notificationTitle.length < 1
          && getters.notificationTitle.length > 100
        )
          commit('MUT_setTitleLengthError', true)
      }
    },
    async ACT_SetNotificationsReaded({ dispatch, getters, commit }) {
      commit('MUT_setModal', false)
      commit('MUT_setNotificationBody', '')
      commit('MUT_setNotificationTitle', '')
      const body = {
        ids: [],
      }
      getters.notificationsList.map((item) => {
        body.ids.push(item.id)
      })
      await bus
        .setGlobalNotifivationsReaded(body)
        .then(() => {
          dispatch('ACT_getNotificationsList')
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_setSingleNotificationsReaded({ dispatch, getters, commit }) {
      commit('MUT_setModal', false)
      commit('MUT_setNotificationBody', '')
      commit('MUT_setNotificationTitle', '')
      const body = {
        ids: [getters.notificationsList[0].id],
      }
      await bus
        .setGlobalNotifivationsReaded(body)
        .then(() => {
          dispatch('ACT_getNotificationsList')
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
  },
}
