import i18n from '@/i18n'

let data = []

if (i18n.locale === 'ru') {
  data = [
    {
      title: 'Бизнес Виза S',
      price: '0₸',
      soc: 'RMNEW2',
      e2e: 'visa-s-connect-button',
      info: [
        {
          name: 'В СНГ и Дальнем зарубежье',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '2 000 ₸/сут',
              descriptions: [
                {
                  text: 'Внутри пакета',
                  details: '400 Мб',
                },
              ],
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '2 000 ₸/сут',
              descriptions: [
                {
                  text: 'Входящие, исходящие в РК и по стране  пребывания',
                  details: '40 мин',
                },
                {
                  text: 'Сверх пакета',
                  details: '70 ₸/мин',
                },
                {
                  text: 'На международные направления',
                  details: '1 050 ₸/мин',
                },
              ],
            },
          ],
        },
        {
          name: 'В прочих странах',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
    {
      title: 'Бизнес Виза L',
      price: '0₸',
      soc: 'BVIS_L2',
      e2e: 'visa-l-connect-button',
      info: [
        {
          name: 'В СНГ',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '2 500 ₸/сут',
              descriptions: [
                {
                  text: 'Внутри пакета',
                  details: '600 Мб',
                },
                {
                  text: 'Сверх пакета',
                  details: '5 ₸/Мб',
                },
              ],
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '2 500 ₸/сут',
              descriptions: [
                {
                  text: 'Входящие, исходящие в РК и по стране  пребывания',
                  details: '60 мин',
                },
                {
                  text: 'Сверх пакета',
                  details: '70 ₸/мин',
                },
                {
                  text: 'На международные направления',
                  details: '1 050 ₸/мин',
                },
              ],
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '500 ₸/сут',
              descriptions: [
                {
                  text: 'SMS в РК и по стране  пребывания',
                  details: '30 SMS',
                },
                {
                  text: 'Сверх пакета',
                  details: '70 ₸/SMS',
                },
                {
                  text: 'На международные направления',
                  details: '120 ₸/SMS',
                },
              ],
            },
          ],
        },
        {
          name: 'В дальнем зарубежье',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '3 000 ₸/сут',
              descriptions: [
                {
                  text: 'Внутри пакета',
                  details: '600 Мб',
                },
                {
                  text: 'Сверх пакета',
                  details: '5 ₸/Мб',
                },
              ],
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '3 000 ₸/сут',
              descriptions: [
                {
                  text: 'Входящие, исходящие в РК и по стране  пребывания',
                  details: '60 мин',
                },
                {
                  text: 'Сверх пакета',
                  details: '100 ₸/мин',
                },
                {
                  text: 'На международные направления',
                  details: '1 050 ₸/мин',
                },
              ],
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '600 ₸/сут',
              descriptions: [
                {
                  text: 'SMS в РК и по стране  пребывания',
                  details: '30 SMS',
                },
                {
                  text: 'Сверх пакета',
                  details: '100 ₸/SMS',
                },
                {
                  text: 'На международные направления',
                  details: '120 ₸/SMS',
                },
              ],
            },
          ],
        },
        {
          name: 'В прочих странах',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
    {
      title: 'Бизнес роуминг 9900',
      price: '9 900₸',
      soc: 'RM9900G',
      e2e: 'roaming-9-connect-button',
      info: [
        {
          name: 'В СНГ и Дальнем зарубежье',
          services: [
            {
              name: 'Безлимитный интернет',
              type: 'internet',
              descriptions: [
                {
                  text: 'На высокой скорости',
                  details: '3 Гб',
                },
                {
                  text: 'Сверх пакета',
                  details: '125 Кбит/сек',
                },
              ],
            },
            {
              name: 'Звонки',
              type: 'calls',
              descriptions: [
                {
                  text: 'Внутри пакета',
                  details: '60 мин',
                },
                {
                  text: 'Сверх пакета в СНГ',
                  details: '39 ₸/мин',
                },
                {
                  text: 'Сверх пакета в Дальнем зарубежье',
                  details: '69 ₸/мин',
                },
              ],
            },
          ],
        },
        {
          name: 'В прочих странах',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
    {
      title: 'Бизнес роуминг 14900',
      price: '14 900₸',
      soc: 'RM14900G',
      e2e: 'roaming-14-connect-button',
      info: [
        {
          name: 'В СНГ и Дальнем зарубежье',
          services: [
            {
              name: 'Безлимитный интернет',
              type: 'internet',
              descriptions: [
                {
                  text: 'На высокой скорости',
                  details: '6 Гб',
                },
                {
                  text: 'Сверх пакета',
                  details: '125 Кбит/сек',
                },
              ],
            },
            {
              name: 'Звонки',
              type: 'calls',
              descriptions: [
                {
                  text: 'Внутри пакета',
                  details: '120 мин',
                },
                {
                  text: 'Сверх пакета в СНГ',
                  details: '39 ₸/мин',
                },
                {
                  text: 'Сверх пакета в Дальнем зарубежье',
                  details: '69 ₸/мин',
                },
              ],
            },
          ],
        },
        {
          name: 'В прочих странах',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Звонки',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
  ]
}
else {
  data = [
    {
      title: 'Бизнес Виза S',
      price: '0₸',
      soc: 'RMNEW2',
      e2e: 'visa-s-connect-button',
      info: [
        {
          name: ' ТМД және алыс шет елдерде ',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '2 000 ₸/күніне',
              descriptions: [
                {
                  text: 'Топтама ішінде',
                  details: '400 Мб',
                },
              ],
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              details: '2 000 ₸/күніне',
              descriptions: [
                {
                  text: 'ҚР және келген елдердің кіріс / шығыс қоңырауларына',
                  details: '40 мин',
                },
                {
                  text: 'Топтама сыртында',
                  details: '70 ₸/мин',
                },
                {
                  text: 'Халықаралық бағыттарға',
                  details: '1 050 ₸/мин',
                },
              ],
            },
          ],
        },
        {
          name: 'Басқа шет елдерде',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
    {
      title: 'Бизнес Виза L',
      price: '0₸',
      soc: 'BVIS_L2',
      e2e: 'visa-l-connect-button',
      info: [
        {
          name: 'ТМД-ДА',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '2 500 ₸/күніне',
              descriptions: [
                {
                  text: 'Топтама ішінде',
                  details: '600 Мб',
                },
                {
                  text: 'Топтама сыртында',
                  details: '5 ₸/Мб',
                },
              ],
            },
            {
              name: ' Қоңыраулар ',
              type: 'calls',
              details: '2 500 ₸/күніне',
              descriptions: [
                {
                  text: 'ҚР және келген елдердің кіріс / шығыс қоңырауларына',
                  details: '60 мин',
                },
                {
                  text: ' Топтама сыртында',
                  details: '70 ₸/мин',
                },
                {
                  text: 'Халықаралық бағыттарға',
                  details: '1 050 ₸/мин',
                },
              ],
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '500 ₸/күніне',
              descriptions: [
                {
                  text: 'ҚР және болған еліңіз бойынша SMS',
                  details: '30 SMS',
                },
                {
                  text: 'Топтама сыртында',
                  details: '70 ₸/SMS',
                },
                {
                  text: 'Халықаралық бағыттарға',
                  details: '120 ₸/SMS',
                },
              ],
            },
          ],
        },
        {
          name: 'Алыс шетелде',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '3 000 ₸/күніне',
              descriptions: [
                {
                  text: 'Топтама ішінде',
                  details: '600 Мб',
                },
                {
                  text: 'Топтама сыртында',
                  details: '5 ₸/Мб',
                },
              ],
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              details: '3 000 ₸/күніне',
              descriptions: [
                {
                  text: 'ҚР және келген елдердің кіріс / шығыс қоңырауларына',
                  details: '60 мин',
                },
                {
                  text: 'Топтама сыртында',
                  details: '100 ₸/мин',
                },
                {
                  text: 'Халықаралық бағыттарға',
                  details: '1 050 ₸/мин',
                },
              ],
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '600 ₸/күніне',
              descriptions: [
                {
                  text: 'ҚР және болған еліңіз бойынша SMS',
                  details: '30 SMS',
                },
                {
                  text: 'Топтама сыртында',
                  details: '100 ₸/SMS',
                },
                {
                  text: 'Халықаралық бағыттарға',
                  details: '120 ₸/SMS',
                },
              ],
            },
          ],
        },
        {
          name: 'Басқа елде',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
    {
      title: 'Бизнес роуминг 9900',
      price: '9 900₸',
      soc: 'RM9900G',
      e2e: 'roaming-9-connect-button',
      info: [
        {
          name: 'ТМД-да және алыс шетелде',
          services: [
            {
              name: 'Шексіз Интернет',
              type: 'internet',
              descriptions: [
                {
                  text: 'Жоғары жылдамдықта',
                  details: '1.5 Гб',
                },
                {
                  text: 'Топтама сыртында',
                  details: '125 Кбит/сек',
                },
              ],
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              descriptions: [
                {
                  text: 'Топтама ішінде',
                  details: '30 мин',
                },
                {
                  text: 'ТМД-дағы пакеттен тыс',
                  details: '39 ₸/мин',
                },
                {
                  text: 'Топтама сыртында Алыс шетелде',
                  details: '69 ₸/мин',
                },
              ],
            },
          ],
        },
        {
          name: 'Басқа елде',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
    {
      title: 'Бизнес роуминг 14900',
      price: '14 900₸',
      soc: 'RM14900G',
      e2e: 'roaming-14-connect-button',
      info: [
        {
          name: 'ТМД-да және алыс шетелде',
          services: [
            {
              name: 'Шексіз Интернет',
              type: 'internet',
              descriptions: [
                {
                  text: 'Жоғары жылдамдықта',
                  details: '6 Гб',
                },
                {
                  text: 'Топтама сыртында',
                  details: '125 Кбит/сек',
                },
              ],
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              descriptions: [
                {
                  text: 'Топтама ішінде',
                  details: '120 мин',
                },
                {
                  text: 'ТМД-дағы пакеттен тыс',
                  details: '39 ₸/мин',
                },
                {
                  text: 'Топтама сыртында Алыс шетелде',
                  details: '69 ₸/мин',
                },
              ],
            },
          ],
        },
        {
          name: 'Басқа елде',
          services: [
            {
              name: 'Интернет',
              type: 'internet',
              details: '4 200 ₸/Мб',
            },
            {
              name: 'Қоңыраулар',
              type: 'calls',
              details: '1 050 ₸/мин',
            },
            {
              name: 'SMS',
              type: 'sms',
              details: '120 ₸/SMS ',
            },
          ],
        },
      ],
    },
  ]
}

export { data }
