export default {
  namespaced: true,
  state: {
    searchText: '',
    rolesFilter: [],
    accessByRole: [
      {
        name: 'CLIENT_ADMIN',
        roles: ['CLIENT'],
      },
      {
        name: 'STAFF_SUPER_ADMIN',
        roles: ['CLIENT', 'CLIENT_ADMIN', 'STAFF', 'STAFF_ADMIN'],
      },
      {
        name: 'STAFF_ADMIN',
        roles: ['CLIENT', 'CLIENT_ADMIN', 'STAFF'],
      },
      {
        name: 'STAFF',
        roles: ['CLIENT', 'CLIENT_ADMIN'],
      },
    ],
  },
  getters: {
    accessByRole: (state, getters, rootState, rootGetters) => {
      const { roles } = state.accessByRole.find(
        x => x.name === rootGetters['profile/role'],
      )

      return roles
    },
    rolesFilter: state => state.rolesFilter,
    searchText: state => state.searchText,
  },
  mutations: {
    MUT_setSearchText(state, value) {
      state.searchText = value
    },
    MUT_setRolesFilter(state, array) {
      state.rolesFilter = array
    },
  },
  actions: {
    ACT_setSearchText({ commit, dispatch }, payload) {
      commit('MUT_setSearchText', payload)
      if (!payload.length)
        dispatch('users/ACT_getUsersByHierarchy', { page: 1 }, { root: true })
    },
    ACT_searchUsers({ dispatch }) {
      dispatch('users/ACT_getUsersByHierarchy', { page: 1 }, { root: true })
    },
    ACT_setRolesFilter({ commit, dispatch }, array) {
      commit('MUT_setRolesFilter', array)
      dispatch('users/ACT_getUsersByHierarchy', { page: 1 }, { root: true })
    },
    ACT_clearSearch({ commit }) {
      commit('MUT_setSearchText', '')
      commit('MUT_setRolesFilter', [])
    },
  },
}
