import { DELETE, GET, PUT } from '@/helpers/base'

const endpoints = {
  socService: '/api/v1/soc',
  checkSocStatus: '/api/v1/soc/status',
  getAvailableList: '/api/v1/soc/availableList',
  getTariffDetails: '/api/v1/soc/pricePlan',
  getServicesList: '/api/v1/soc/list',
  getAgreementsList: '/api/v1/soc/agreementList',
  getAvailableAgreementList: '/api/v1/soc/availableAgreementList',
  addSocAgreement: '/api/v1/soc/agreement',
  checkFeatureConflicts: '/api/v1/soc/feature-conflicts',
}

const SocApi = {
  methods: {
    checkFeatureConflicts(query) {
      const endpoint = endpoints.checkFeatureConflicts

      return GET(`${endpoint}`, query)
    },
    addSocService(data) {
      const endpoint = endpoints.socService

      return PUT(`${endpoint}`, data)
    },
    addSocAgreement(data) {
      const endpoint = endpoints.addSocAgreement

      return PUT(`${endpoint}`, data)
    },
    removeSocService(data) {
      const endpoint = endpoints.socService

      return DELETE(`${endpoint}`, data)
    },
    getTariffDetails(data) {
      const endpoint = endpoints.getTariffDetails

      return GET(`${endpoint}`, data)
    },
    getServicesList(data) {
      const endpoint = endpoints.getServicesList

      return GET(`${endpoint}`, data)
    },
    getAgreementsList(data) {
      const endpoint = endpoints.getAgreementsList

      return GET(`${endpoint}`, data)
    },
    getAvailableAgreementList(data) {
      const endpoint = endpoints.getAvailableAgreementList
      return GET(`${endpoint}`, data)
    },
    checkSocStatus(subscriberNo) {
      const endpoint = endpoints.checkSocStatus

      return GET(`${endpoint}`, subscriberNo)
    },
    getAvailableList(data) {
      const endpoint = endpoints.getAvailableList

      return GET(`${endpoint}`, data)
    },
  },
}

export { SocApi }
