<template>
  <div>
    <div v-if="isModalActive" class="notifications-list">
      <div class="notifications-block-ttl">
        {{ $t("globalNotifications.notifications") }}
      </div>
      <div
        class="notifications-close"
        data-e2e="notification-close-btn"
        @click="ACT_SetNotificationsReaded"
      >
        &times;
      </div>
      <div class="notifications-tabs">
        <div class="notifications-tabs__item">
          {{ $t("globalNotifications.notificationsList") }}
        </div>
        <div
          class="notifications-tabs__item create-new"
          @click="
            MUT_setAddModal(true);
            MUT_setModal(false);
          "
        >
          <div v-if="role === 'STAFF_SUPER_ADMIN'">
            {{ $t("globalNotifications.createNewnNotification") }}
          </div>
        </div>
      </div>
      <div v-if="notificationsList.length > 0">
        <div v-for="(notification, i) in notificationsList" :key="i">
          <div
            class="notifications-list__item"
            :class="{ unread: !notification.viewed }"
          >
            <div class="notifications-list__item__date">
              {{ dateToDDMMYYTT(new Date(notification.createdAt)) }}
              <div
                v-if="role === 'STAFF_SUPER_ADMIN'"
                class="icon-delete"
                @click="deleteNotification(notification.id)"
              />
            </div>
            <div class="notifications-list__item__ttl">
              {{ notification.title }}
            </div>
            <div
              class="notifications-list__item__body"
              v-html="notification.description"
            />
            <div class="notifications-list__item__creator">
              <div class="notifications-list__item__creator__ttl">
                {{ $t("globalNotifications.created") }}:
              </div>
              <div class="notifications-list__item__creator__user">
                {{ notification.createdBy }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Transition name="fade">
      <Modal v-if="isAddModalActive">
        <AddGlobalNotificationForm />
      </Modal>
      <Modal v-if="isDeleteModalActive">
        <NotificationDelete :id="id" />
      </Modal>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Modal from '@/components/modal/Modal.vue'
import addGlobalNotificationForm from '@/components/globalNotifications/addGlobalNotificationForm'
import notificationDelete from '@/components/globalNotifications/notificationDelete'

export default {
  data() {
    return {
      name: 'Modal',
      id: '',
    }
  },
  mounted() {
    this.ACT_getNotificationsList()
  },
  methods: {
    deleteNotification(id) {
      this.MUT_setDeleteModal(true)
      this.id = id
    },
    ...mapActions('globalNotifications', [
      'ACT_getNotificationsList',
      'ACT_closeAddModal',
      'ACT_SetNotificationsReaded',
    ]),
    ...mapMutations('globalNotifications', [
      'MUT_setModal',
      'MUT_setAddModal',
      'MUT_setDeleteModal',
    ]),
  },
  computed: {
    ...mapGetters('globalNotifications', [
      'notificationsList',
      'isModalActive',
      'isDeleteModalActive',
      'step',
      'isAddModalActive',
    ]),
    ...mapGetters('profile', ['role']),
  },
  components: {
    Modal,
    AddGlobalNotificationForm: addGlobalNotificationForm,
    NotificationDelete: notificationDelete,
  },
}
</script>

<style scoped lang="scss">
.modal-mask {
  overflow-y: auto !important;
}
.notifications-block-ttl {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #10083f;
  margin-bottom: 26px;
}
.notifications-tabs {
  margin-bottom: 21px;
  border-bottom: 1px solid #a8a6b3;
  padding-bottom: 13px;
  @include flexbox;
  @include justify-content(space-between);
  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #10083f;
    position: relative;
    &:first-child:after {
      display: block;
      content: "";
      height: 3px;
      background: #ffd400;
      border-radius: 2px;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
    }
    &.create-new {
      color: #0c4dce;
      cursor: pointer;
    }
  }
}
.notifications-list {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9996;
  width: 675px;
  background: $color-white;
  height: 100vh;
  padding: 24px;
  overflow-y: auto;
  .notifications-close {
    position: absolute;
    right: 26px;
    top: 22px;
    color: #a8a6b3;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 24px;
    line-height: 20px;
    cursor: pointer;
  }
  &__item {
    padding: 18px 24px;
    border: 1px solid #e2e1e8;
    border-radius: 12px;
    margin-bottom: 10px;
    &.unread {
      background: #f4f4f6;
    }
    &__date {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #858295;
      margin-bottom: 16px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
    }
    &__ttl {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #10083f;
      margin-bottom: 10px;
      word-break: break-word;
    }
    &__body {
      color: #504d64;
      margin-bottom: 14px;
      word-break: break-word;
    }
    &__creator {
      @include flexbox;
      @include align-items(center);
      &__ttl {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
      &__user {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px;
        background: #f1f1f4;
        border-radius: 12px;
        margin-left: 8px;
      }
    }
  }
}
.icon-delete {
  width: 24px;
  height: 24px;
  background: url("../../assets/icons/trash.svg");
  cursor: pointer;
}
@media (max-width: 992px) {
  .notifications-list {
    width: 100%;
  }
}
</style>
