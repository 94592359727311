<template>
  <div id="app">
    <Transition name="fade">
      <div v-if="isModalActive" class="notifications-list-mask" />
    </Transition>
    <Transition name="slide-left">
      <GlobalNotificationsList v-if="isUserLoggedIn" />
    </Transition>
    <TheHeader v-if="$router.currentRoute.path !== '/login'" />
    <Transition name="slide-fade">
      <Notification
        v-if="isNotificationActive"
        :text="notificationText"
        :type="notificationType"
        @closeNotification="ACT_closeNotification"
      />
    </Transition>
    <Transition mode="out-in" name="fade">
      <RouterView />
    </Transition>
    <TheFooter v-if="$router.currentRoute.path !== '/login'" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TheHeader from '@/layouts/TheHeader.vue'
import TheFooter from '@/layouts/TheFooter.vue'
import notification from '@/components/notification/Notification.vue'
import globalNotificationsList from '@/components/globalNotifications/globalNotificationsList'

export default {
  computed: {
    ...mapGetters([
      'isNotificationActive',
      'notificationType',
      'notificationText',
    ]),
    ...mapGetters('profile', ['isUserLoggedIn']),
    ...mapGetters('globalNotifications', ['isModalActive']),
  },
  methods: {
    ...mapActions(['ACT_closeNotification']),
  },
  components: {
    TheHeader,
    TheFooter,
    Notification: notification,
    GlobalNotificationsList: globalNotificationsList,
  },
}
</script>

<style>
body {
  background-color: #f8f8f8;
  margin: 0;
  position: relative;
  font-family: "Roboto", sans-serif !important;
}
</style>
