import { bus } from '@/main'
import i18n from '@/i18n'

const initialData = {
  step: '',
  type: '',
  isLoading: true,
  available: [],
  blocked: [],
  actvDate: '',
  changedBans: [],
  rejectedBans: [],
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    step: state => state.step,
    type: state => state.type,
    isLoading: state => state.isLoading,
    available: state => state.available,
    blocked: state => state.blocked,
    actvDate: state => state.actvDate,
    changedBans: state => state.changedBans,
    rejectedBans: state => state.rejectedBans,
  },
  mutations: {
    MUT_setStep(state, value) {
      state.step = value
    },
    MUT_setType(state, value) {
      state.type = value
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setAvailableBans(state, value) {
      state.available = value
    },
    MUT_setBlockedBans(state, value) {
      state.blocked = value
    },
    MUT_setActivationDate(state, date) {
      date
        ? (state.actvDate = bus.dateFromTimeStamp(date))
        : (state.actvDate = '')
    },
    MUT_setChangedBans(state, bans) {
      state.changedBans = bans
    },
    MUT_setRejectedBans(state, bans) {
      state.rejectedBans = bans
    },
    MUT_resetState(state) {
      Object.assign(state, initialData)
    },
  },
  actions: {
    ACT_sortBans({ commit }, payload) {
      const bansSuccess = []
      const bansRejected = []

      payload.result.forEach((item) => {
        (item.state === 'SUCCESS' ? bansSuccess : bansRejected).push(item)
      })

      commit('MUT_setChangedBans', bansSuccess)
      commit('MUT_setRejectedBans', bansRejected)

      if (payload.status === 200)
        commit('MUT_setStep', 'success')
      else
        commit('MUT_setStep', 'error')
    },
    ACT_sortCtns({ commit }, payload) {
      let available = []
      const blocked = []

      payload.response.forEach((item) => {
        (item.available ? available : blocked).push(item)
      })

      available = payload.data
        .filter(o1 =>
          available.some(o2 => o1.subscriberNo === o2.subscriberNo),
        )
        .map(item => ({
          ban: item.ban,
          subscriberNo: item.subscriberNo,
        }))

      commit('MUT_setLoading', false)
      commit('MUT_setAvailableBans', available)
      commit('MUT_setBlockedBans', blocked)
    },
    ACT_setActivationDate({ commit }, date) {
      commit('MUT_setActivationDate', date)
    },
    async ACT_blockCtns({ commit, dispatch }, data) {
      commit('MUT_setLoading', true)
      commit('MUT_setStep', 'block')
      commit('MUT_setType', 'block')

      let numbersArray = data.map(item => item.subscriberNo)
      numbersArray = numbersArray.join(',')

      await bus
        .checkCtnsToSuspend({ subscriberNo: numbersArray })
        .then((res) => {
          dispatch('ACT_sortCtns', {
            data,
            response: res.data,
          })
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_unblockCtns({ commit, dispatch }, data) {
      commit('MUT_setLoading', true)
      commit('MUT_setStep', 'unblock')
      commit('MUT_setType', 'unblock')

      let numbersArray = data.map(item => item.subscriberNo)
      numbersArray = numbersArray.join(',')

      await bus
        .checkCtnsToRestore({ subscriberNo: numbersArray })
        .then((res) => {
          dispatch('ACT_sortCtns', {
            data,
            response: res.data,
          })
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },

    async ACT_blockBans({ getters, dispatch, commit }) {
      await bus
        .ctnsBlock({
          actvDate: getters.actvDate,
          ctns: [...getters.available],
        })
        .then((result) => {
          dispatch('ACT_sortBans', {
            status: result.status,
            result: result.data,
          })
          commit('ctns/MUT_clearSelectedCtns', {}, { root: true })
        })
        .catch((error) => {
          dispatch('ctnInfo/ACT_closeModal', '', { root: true })
          commit('ctns/MUT_setModal', false, { root: true })
          dispatch(
            'ACT_setNotification',
            { type: 'error', text: i18n.t('errors.serviceUnavailableError') },
            { root: true },
          )
          return Promise.reject(error)
        })
    },
    async ACT_unblockBans({ getters, dispatch, commit }) {
      await bus
        .ctnsRestore({
          actvDate: getters.actvDate,
          ctns: [...getters.available],
        })
        .then((result) => {
          dispatch('ACT_sortBans', {
            status: result.status,
            result: result.data,
          })
          commit('ctns/MUT_clearSelectedCtns', {}, { root: true })
        })
        .catch((error) => {
          dispatch('ctnInfo/ACT_closeModal', '', { root: true })
          commit('ctns/MUT_setModal', false, { root: true })
          dispatch(
            'ACT_setNotification',
            { type: 'error', text: i18n.t('errors.serviceUnavailableError') },
            { root: true },
          )
          return Promise.reject(error)
        })
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
  },
}
