<template>
  <div class="header">
    <div class="container">
      <div class="nav">
        <div class="nav__menu">
          <RouterLink
            class="logo"
            :src="logoURL"
            tag="img"
            to="/"
            width="177px"
          />
          <ul v-if="isUserLoggedIn" class="nav__links">
            <RouterLink
              v-for="(link, index) in links"
              :key="index"
              active-class="active"
              :data-e2e="link.data"
              exact-path
              tag="li"
              :to="link.linkTo"
            >
              {{ link.name }}
            </RouterLink>
          </ul>
        </div>
        <div class="nav__right">
          <RouterLink
            v-if="isUserLoggedIn"
            active-class="btn_faq__active"
            class="btn_faq"
            data-e2e="help-btn"
            exact-path
            tag="button"
            to="/faq"
            @click.native="ACT_setFaqMark"
          >
            {{ $t("faq.linkTitle") }}
          </RouterLink>

          <GlobalNotificationsBell v-if="isUserLoggedIn" />

          <select
            class="lang"
            name="lang"
            @change="switchLocale"
          >
            <option
              v-for="locale in locales"
              :key="locale"
              :selected="locale === lang"
              :value="locale"
            >
              {{ locale === "ru" ? "Рус" : "Қаз" }}
            </option>
          </select>
          <div
            v-if="isUserLoggedIn"
            class="avatar"
            @click.stop="isMenuActive = !isMenuActive"
          >
            {{ getInitials(firstName, secondName) }}
            <ul
              v-if="isMenuActive"
              v-click-outside="hideMenu"
              class="user_nav"
            >
              <RouterLink tag="li" to="/profile-edit">
                <div class="icon-user" />
                {{ $t("nav.profile") }}
              </RouterLink>
              <li class="logout" @click="logout">
                <div class="icon-power" />
                {{ $t("nav.logout") }}
              </li>
            </ul>
          </div>
          <div
            v-if="isUserLoggedIn"
            class="icon-hamburger"
            @click="mobileNavActive = true"
          />
        </div>
      </div>
    </div>
    <Transition name="fade">
      <div v-if="mobileNavActive" class="layout" />
    </Transition>
    <Transition name="slide-right">
      <div
        v-if="mobileNavActive"
        v-click-outside="closeNav"
        class="mobile-nav"
      >
        <div class="mobile-nav__head">
          <RouterLink
            class="logo"
            heigth="34px"
            :src="logoURL"
            tag="img"
            to="/"
            width="98px"
          />
          <div class="icon-exit" @click="closeNav" />
        </div>
        <ul class="mobile-nav__body">
          <RouterLink
            v-for="(link, index) in links"
            :key="index"
            active-class="active-nav"
            exact-path
            tag="li"
            :to="link.linkTo"
            @click="closeNav"
          >
            {{ link.name }}
          </RouterLink>
          <RouterLink
            active-class="active-nav"
            class="mobile_faq"
            exact-path
            tag="li"
            to="/faq"
            @click="closeNav"
          >
            {{ $t("faq.linkTitle") }}
          </RouterLink>
        </ul>
      </div>
    </Transition>
    <Transition name="fade">
      <Modal v-if="singleModalActive">
        <SingleGlobalNotification
          v-click-outside="closeModal"
          @closeModal="closeModal"
        />
      </Modal>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import globalNotificationsBell from '@/components/globalNotifications/globalNotificationsBell'
import singleGlobalNotification from '@/components/globalNotifications/singleGlobalNotification'
import Modal from '@/components/modal/Modal.vue'
import logo from '@/assets/logo.png'

export default {
  data() {
    return {
      isMenuActive: false,
      mobileNavActive: false,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      lang: this.$i18n.locale,
    }
  },
  watch: {
    $route() {
      if (this.mobileNavActive)
        this.mobileNavActive = false
    },
  },
  computed: {
    ...mapGetters('profile', [
      'isUserLoggedIn',
      'firstName',
      'secondName',
      'isHistoryAvailable',
      'role',
    ]),
    ...mapGetters('globalNotifications', ['singleModalActive']),
    logoURL() {
      return logo
    },
    links() {
      let links = []
      if (this.isHistoryAvailable && this.$route) {
        if (localStorage.getItem('impersonalize')) {
          links = [
            {
              name: this.$i18n.t('nav.main'),
              linkTo: '/',
              data: 'main-header-btn',
            },
            {
              name: this.$i18n.t('nav.ctns'),
              linkTo: '/ctns',
              data: 'number-nanagement-header-btn',
            },
            {
              name: this.$i18n.t('nav.requestsHistory'),
              linkTo: '/requests-history',
              data: 'history-header-btn',
            },
            {
              name: this.$i18n.t('nav.reports'),
              linkTo: '/treports',
              data: 'report-header-btn',
            },
          ]
        }
        else {
          links = [
            {
              name: this.$i18n.t('nav.main'),
              linkTo: '/',
              data: 'main-header-btn',
            },
            {
              name: this.$i18n.t('nav.reports'),
              linkTo: '/treports',
              data: 'report-header-btn',
            },
          ]
        }
      }
      else if (this.role === 'STAFF') {
        links = [
          {
            name: this.$i18n.t('nav.main'),
            linkTo: '/',
            data: 'main-header-btn',
          },
        ]
      }
      else if (this.role === 'CLIENT_ADMIN') {
        links = [
          {
            name: this.$i18n.t('nav.main'),
            linkTo: '/',
            data: 'main-header-btn',
          },
          {
            name: this.$i18n.t('nav.ctns'),
            linkTo: '/ctns',
            data: 'number-nanagement-header-btn',
          },
          {
            name: this.$i18n.t('nav.finance'),
            linkTo: '/finance',
            data: 'financial-information-btn',
          },
          {
            name: this.$i18n.t('nav.reports'),
            linkTo: '/treports',
            data: 'report-header-btn',
          },
        ]
      }
      else {
        links = [
          {
            name: this.$i18n.t('nav.main'),
            linkTo: '/',
            data: 'main-header-btn',
          },
          {
            name: this.$i18n.t('nav.ctns'),
            linkTo: '/ctns',
            data: 'number-nanagement-header-btn',
          },
          {
            name: this.$i18n.t('nav.finance'),
            linkTo: '/finance',
            data: 'financial-information-btn',
          },
        ]
      }

      return links
    },
  },
  components: {
    GlobalNotificationsBell: globalNotificationsBell,
    SingleGlobalNotification: singleGlobalNotification,
    Modal,
  },
  methods: {
    ...mapActions('profile', ['logout']),
    ...mapActions('faq', ['ACT_setFaqMark']),
    ...mapActions('globalNotifications', ['ACT_setSingleNotificationsReaded']),
    ...mapMutations('globalNotifications', ['MUT_setSingleModalActive']),
    switchLocale(e) {
      const locale = e.target.value

      if (this.$i18n.locale !== locale)
        this.$i18n.locale = locale

      localStorage.setItem('lang', e.target.value)

      window.location.reload()
    },
    hideMenu() {
      this.isMenuActive = false
    },
    closeNav() {
      this.mobileNavActive = false
    },
    notificationsToggle() {
      this.isNotificationActive = !this.isNotificationActive
    },
    closeModal() {
      this.MUT_setSingleModalActive(false)
      this.ACT_setSingleNotificationsReaded()
    },
  },
}
</script>

<style scoped lang="scss">
.btn_faq {
  margin-right: 16px;
  background: #e8e8eb;
  padding: 8px 10px;
  border: none;
  font-size: 12px;
  line-height: 16px;
  transition: 0.4s ease-out;
  cursor: pointer;
  border-radius: 12px;
  &:hover {
    background: $color-yellow;
  }
  &__active {
    background: $color-yellow;
  }
}
.layout {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9998;
  background: rgba(35, 31, 32, 0.5);
}
.mobile-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 5%;
  background: $color-white;
  border-radius: 12px 12px 0px 0px;
  z-index: 9999;
  &__head {
    padding: 20px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
  }
  &__body {
    list-style-type: none;
    li {
      padding: 14px;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      line-height: 20px;
      color: $color-grey;
    }
  }
}

.user_nav {
  position: absolute;
  filter: drop-shadow(0px 4px 16px rgba(16, 8, 63, 0.12));
  width: 164px;
  border-radius: 4px;
  z-index: 999;
  top: 35px;
  right: 0;
  background-color: $color-white;
  list-style-type: none;
  li {
    @include flexbox;
    @include align-items(center);
    cursor: pointer;
    padding: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    transition: 0.3s ease-out;
    color: $color-black;
    &:hover {
      background-color: #e8e8eb;
    }
  }
}
.logout {
  color: $color-error !important;
}
.avatar {
  margin-left: 8px;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  background: linear-gradient(
    117.58deg,
    $color-yellow 16.48%,
    $color-yellow 94.28%
  );
  width: 36px;
  height: 36px;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #13152d;
}
.lang {
  padding: 7px 25px 7px 15px;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #e8e8eb url("../assets/icons/arrow_down.svg") no-repeat;
  background-position-x: 85%;
  background-position-y: 8px;
}
.logo {
  cursor: pointer;
  margin-right: 10px;
}
.header {
  padding: 13px;
  background: $color-white;
  max-height: 60px;
}
.nav {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  &__links {
    list-style-type: none;
    @include flexbox;
    @include justify-content(space-between);
    li {
      cursor: pointer;
      margin-left: 38px;
      position: relative;
      color: $color-grey;
    }
  }
  &__right {
    @include flexbox;
    @include align-items(center);
  }
  &__menu {
    @include flexbox;
    @include align-items(center);
    .report-li {
      list-style: none;
      cursor: pointer;
      margin-left: 38px;
      position: relative;
      color: #858295;
    }
  }
}
.active {
  color: $color-black !important;
  &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: $color-yellow;
  }
}
.active-nav {
  color: $color-black !important;
  position: relative;
  border-left: 4px solid $color-yellow;
}
.icon-user {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: url("../assets/icons/person.svg");
}
.icon-hamburger {
  margin-left: 8px;
  width: 36px;
  height: 36px;
  display: none;
  cursor: pointer;
  background: url("../assets/icons/hamburger.svg");
}
.icon-power {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: url("../assets/icons/power.svg");
}
.icon-exit {
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: url("../assets/icons/exit.svg");
}
@media (max-width: 992px) {
  .btn_faq {
    display: none;
  }
  .icon-hamburger {
    display: block;
  }
  .nav {
    &__links {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .header {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .logo {
    max-width: calc(100% - 10px);
  }
}
</style>
