import { DELETE, GET, POST } from '@/helpers/base'

const endpoints = {
  getGlobalNotificationsList: '/api/v1/notification',
  addGlobalNotifivation: '/api/v1/notification/create',
  setGlobalNotifivationsReaded: '/api/v1/notification/close',
}

const GlovalNotificationsApi = {
  methods: {
    getGlobalNotificationsList() {
      const endpoint = endpoints.getGlobalNotificationsList

      return GET(`${endpoint}`)
    },
    deleteGlobalNotification(id) {
      const endpoint = endpoints.getGlobalNotificationsList

      return DELETE(`${endpoint}/${id}`)
    },
    addGlobalNotifivation(body) {
      const endpoint = endpoints.addGlobalNotifivation

      return POST(`${endpoint}`, body)
    },
    setGlobalNotifivationsReaded(body) {
      const endpoint = endpoints.setGlobalNotifivationsReaded

      return POST(`${endpoint}`, body)
    },
  },
}

export { GlovalNotificationsApi }
