/* eslint-disable */
import { Buffer } from 'buffer'
import router from '../../../router'
import { bus } from '@/main'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    step: 'login',
    warning: {
      isActive: false,
      text: '',
    },
    sitekey: '6Le5kJAeAAAAAJcCSf_No9bF4xy1amL_cM5zP89p',
    recaptcha: '',
    recaptchaVerified: false,
    isFirstEntry: false,
    isLoading: false,
    isCodeExpired: false,
    login: '',
    password: '',
    userEmail: '',
    emailCode: '',
    emailCounter: 0,
    emailCounterStatus: '',
    modalActive: false,
    modalTitle: '',
    entryErrorText: '',
  },
  getters: {
    step: state => state.step,
    warningMsg: state => state.warning.text,
    isWarning: state => state.warning.isActive,
    entryErrorText: state => state.entryErrorText,
    sitekey: state => state.sitekey,
    recaptchaVerified: state => state.recaptchaVerified,
    recaptcha: state => state.recaptcha,
    login: state => state.login,
    password: state => state.password,
    isLoading: state => state.isLoading,
    isFirstEntry: state => state.isFirstEntry,
    emailCode: state => state.emailCode,
    userEmail: state => state.userEmail,
    isCodeExpired: state => state.isCodeExpired,
    emailCounter: state => state.emailCounter,
    emailCounterStatus: state => state.emailCounterStatus,
    isAccountPhone: (state) => {
      return (
        state.login[0] === '7'
        || state.login[0] === '+'
        || state.login[0] === '8'
      )
    },
    modalActive: state => state.modalActive,
    modalTitle: state => state.modalTitle,
  },
  mutations: {
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setIscodeExpired(state, value) {
      state.isCodeExpired = value
    },
    MUT_setLogin(state, value) {
      if (value === '8' || value === '7' || state.login === '+')
        state.login = '+7 ('

      state.login = value.toUpperCase()
    },
    MUT_setEmailCode(state, value) {
      state.emailCode = value
    },
    MUT_setPassword(state, value) {
      state.password = value
    },
    MUT_setEntryStatus(state, value) {
      state.isFirstEntry = value
    },
    MUT_setStep(state, value) {
      state.step = value
    },
    MUT_setUserEmail(state, value) {
      state.userEmail = value
    },
    MUT_setEmailCounter(state, value) {
      state.emailCounter = value
    },
    MUT_setEmailCounterStatus(state, status) {
      state.emailCounterStatus = status
    },
    MUT_toggleModal(state, value) {
      state.modalActive = value
    },
    MUT_setModalTitle(state, text) {
      state.modalTitle = text
    },
    MUT_setRecaptcha(state, recaptcha) {
      state.recaptcha = recaptcha
      state.recaptchaVerified = true
    },
    MUT_setWarningMsg(state, payload) {
      state.warning.text = payload
    },
    MUT_setWarning(state, payload) {
      state.warning.isActive = payload
    },
    MUT_setEntryErrorText(state, payload) {
      state.entryErrorText = payload
    },
  },
  actions: {
    ACT_setEntryErrorText({ commit }, payload) {
      commit('MUT_setEntryErrorText', payload)
    },
    ACT_setWarning({ commit }, payload) {
      commit('MUT_setWarning', true)
      commit('MUT_setWarningMsg', payload)
    },
    ACT_setLogin({ commit }, payload) {
      commit('MUT_setLogin', payload)
      commit('MUT_setWarning', false)
    },
    ACT_setPassword({ commit }, payload) {
      commit('MUT_setPassword', payload)
    },
    ACT_setEmailCode({ commit }, payload) {
      commit('MUT_setEmailCode', payload)
    },
    ACT_setStep({ commit }, payload) {
      commit('MUT_setStep', payload)
    },
    ACT_toggleModal({ commit }, payload) {
      commit('MUT_toggleModal', payload)
    },
    ACT_setRecaptcha({ commit }, payload) {
      commit('MUT_setRecaptcha', payload)
    },
    async ACT_checkLoginStatus({ getters, commit, dispatch }) {
      commit('MUT_setLoading', true)
      await bus
        .isExist({
          login: getters.login,
          recaptcha: getters.recaptcha,
        })
        .then((res) => {
          if (res.data.status === 'ACTIVE') {
            commit('MUT_setStep', 'password')
          }
          else if (res.data.status === 'BLOCKED') {
            dispatch('ACT_setWarning', i18n.t('errors.authBlocked'))
          }
          else {
            if (res.data.status === 'FIRST_ENTRY_MIGRATED')
              commit('MUT_setEntryStatus', true)

            dispatch('ACT_sendOtp', { login: getters.login, type: 'EMAIL' })
          }
          commit('MUT_setLoading', false)
        })
        .catch((error) => {
          commit('MUT_setLoading', false)
          return Promise.reject(error)
        })
    },
    ACT_forgotCredentials({ commit }, text) {
      commit('MUT_setModalTitle', text)
      commit('MUT_toggleModal', true)
    },
    ACT_forgotPassword({ getters, dispatch }) {
      dispatch('ACT_sendOtp', { login: getters.login, type: 'EMAIL' })
    },
    async ACT_signIn({ getters, commit, dispatch }, payload) {
      commit('MUT_setLoading', true)
      await bus
        .signIn({
          data: payload,
          recaptcha: getters.recaptcha,
        })
        .then(() => {
          commit('profile/MUT_setUserLoggedIn', true, { root: true })
          commit('MUT_setLoading', false)
          router.push('/')
        })
        .catch((err) => {
          if (
            err.response.status === 400
            && err.response.data.message === 'login_limit_exceeded'
          ) {
            dispatch('ACT_setStep', 'entry_error')
            dispatch('ACT_setEntryErrorText', i18n.t('errors.entryErrorDesc'))
          }
          return Promise.reject(err)
        })
    },
    async ACT_sendOtp({ getters, commit, dispatch }, data) {
      await bus
        .sendOtp(data)
        .then((res) => {
          commit('MUT_setUserEmail', res.data.message)
          commit('MUT_setEmailCounter', res.data.counter)
          res.data.status
            ? commit('MUT_setEmailCounterStatus', res.data.status)
            : commit('MUT_setEmailCounterStatus', '')
          if (
            getters.step !== 'confirm'
            && getters.emailCounterStatus === 'LIMIT_EXCEEDED'
          ) {
            dispatch('ACT_setStep', 'entry_error')
            dispatch('ACT_setEntryErrorText', i18n.t('errors.otpEmailError'))
          }
          else {
            dispatch('ACT_setStep', 'confirm')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_createNewPassword({ getters, dispatch }, payload) {
      await bus
        .resetCredentials(payload)
        .then(() => {
          if (!getters.recaptcha.length) {
            window.location.href = '/'
          }
          else {
            dispatch('ACT_signIn', {
              login: getters.login,
              authType: 'LOGIN',
              password: payload.newPassword,
            })
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    async ACT_checkKey({ commit, dispatch }, payload) {
      await bus
        .checkUserKey({ key: payload })
        .then(({ data }) => {
          if (data === 'OK') {
            commit('MUT_setLoading', false)
            // Decode to base 64
            const base64 = Buffer.from(
              payload.split('/')[0],
              'base64',
            ).toString('ascii')
            commit('MUT_setLogin', base64)

            dispatch(
              'auth/ACT_sendOtp',
              { login: base64, type: 'EMAIL' },
              { root: true },
            )
          }
          else if (data === 'EXPIRED') {
            commit('MUT_setLoading', false)
            commit('MUT_setIscodeExpired', true)
          }
          else if (data === 'INCORRECT') {
            commit('MUT_setLoading', false)
            router.push('/404')
          }
        })
        .catch((error) => {
          commit('MUT_setLoading', false)
          switch (error.response.status) {
            case 400:
              router.push('/404')
              break
            case 500:
              router.push('/404')
              break
          }
        })
    },
  },
}
