import { bus } from '@/main'
import i18n from '@/i18n'

function initialState() {
  return {
    pageNumber: 1,
    total: 0,
    size: 10,
    events: [],
    actionsFilter: [],
    statusesFilter: [],
    mobileFilterActive: false,
    isDetailsLoading: true,
    requestDetails: {},
    sort: {
      name: '',
      by: '',
    },
    actions: [
      'LOGIN',
      'RESET_CREDENTIALS',
      'CREATE_USER',
      'EDIT_USER',
      'EDIT_PROFILE',
      'DELETE_USER',
      'BLOCK_USER',
      'UNBLOCK_USER',
      'RESET_OTP_COUNTER',
      'RESET_ENTRY_COUNTER',
      'CHANGE_CREDIT_LIMIT',
      'SUSPEND_CTNS',
      'RESTORE_CTNS',
      'REPLACE_SIM',
      'TURN_OFF_SOC',
      'TURN_ON_SOC',
      'TURN_ON_AGREEMENT',
      'REQUEST_REPORT',
      'REQUEST_INVOICE',
    ],
    statuses: ['SUCCESSFUL', 'NOT_SUCCESSFUL'],
    searchText: '',
    endDate: '',
    startDate: '',
  }
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    ...initialState(),
  },
  getters: {
    searchText: state => state.searchText,
    requestDetails: state => state.requestDetails,
    isDetailsLoading: state => state.isDetailsLoading,
    pageNumber: state => state.pageNumber,
    sort: state => state.sort,
    total: state => state.total,
    size: state => state.size,
    events: state => state.events,
    statuses: state => state.statuses,
    requestStatus: state => state.requestDetails.status,
    requestLogin: state => state.requestDetails.login,
    requestAction: state => state.requestDetails.action,
    requestComment: state => state.requestDetails.comment,
    createDate: state => state.requestDetails.createDate,
    numbers: state => state.requestDetails.numbers,
    errorReason: state => state.requestDetails.errorReason,
    actions: state => state.actions,
    isLoading: state => state.isLoading,
    endDate: state => state.endDate,
    startDate: state => state.startDate,
    actionsFilter: state => state.actionsFilter,
    statusesFilter: state => state.statusesFilter,
    mobileFilterActive: state => state.mobileFilterActive,
  },
  mutations: {
    MUT_setSearchText(state, value) {
      state.searchText = value.toUpperCase()
    },
    MUT_setPageNumber(state, value) {
      state.pageNumber = value
    },
    MUT_setDetailsLoading(state, value) {
      state.isDetailsLoading = value
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setStartDate(state, value) {
      state.startDate = bus.dateFromTimeStamp(value)
    },
    MUT_setEndDate(state, value) {
      state.endDate = bus.dateFromTimeStamp(value)
    },
    MUT_setTotalEvents(state, value) {
      state.total = value
    },
    MUT_setEvents(state, value) {
      state.events = value
    },
    MUT_setActionsFilter(state, value) {
      state.actionsFilter = value
    },
    MUT_setStatusesFilter(state, value) {
      state.statusesFilter = value
    },
    MUT_setRequestDetails(state, value) {
      state.requestDetails = value
    },
    MUT_setMobileFilter(state, value) {
      state.mobileFilterActive = value
    },
    MUT_setSortBy(state, value) {
      state.sort.by = value
    },
    MUT_setSortName(state, value) {
      state.sort.name = value
    },
    MUT_resetState(state) {
      Object.assign(state, initialState())
    },
  },
  actions: {
    ACT_setActionsFilterMobile({ commit }, payload) {
      commit('MUT_setActionsFilter', payload)
    },
    ACT_setStatusesFilterMobile({ commit }, payload) {
      commit('MUT_setStatusesFilter', payload)
    },
    ACT_setEndDateMobile({ commit }, payload) {
      commit('MUT_setEndDate', payload)
    },
    ACT_setActionsFilter({ commit, dispatch }, payload) {
      commit('MUT_setActionsFilter', payload)
      dispatch('ACT_setPageNumber', 1)
      dispatch('ACT_getEvents')
    },
    ACT_setStatusesFilter({ commit, dispatch }, payload) {
      commit('MUT_setStatusesFilter', payload)
      dispatch('ACT_setPageNumber', 1)
      dispatch('ACT_getEvents')
    },
    ACT_setStartDate({ commit }, payload) {
      commit('MUT_setStartDate', payload)
    },
    ACT_setEndDate({ getters, commit, dispatch }, payload) {
      commit('MUT_setEndDate', payload)
      if (getters.startDate.length && getters.endDate.length) {
        dispatch('ACT_setPageNumber', 1)
        dispatch('ACT_getEvents')
      }
    },
    ACT_setSearchText({ commit, dispatch }, payload) {
      commit('MUT_setSearchText', payload)
      if (!payload.length)
        dispatch('ACT_setPageNumber', 1)
    },
    ACT_applyFilters({ commit, dispatch }) {
      commit('MUT_setMobileFilter', false)
      dispatch('ACT_setPageNumber', 1)
      dispatch('ACT_getEvents')
    },
    async ACT_getEvents({ getters, commit, dispatch }) {
      commit('MUT_setLoading', true)

      const data = {
        page: getters.pageNumber - 1,
        size: getters.size,
        ...(getters.sort.name.length && {
          sort: `${getters.sort.name},${getters.sort.by}`,
        }),
        actionByLogin: getters.searchText,
        actions: getters.actionsFilter.length
          ? getters.actionsFilter.join(',')
          : getters.actions.join(','),
        statuses: getters.statusesFilter.length
          ? getters.statusesFilter.join(',')
          : getters.statuses.join(','),
        startDate: getters.startDate,
        endDate: getters.endDate,
        actionWithLogin: JSON.parse(localStorage.getItem('impersonalize'))
          .login,
      }

      const result = await bus.getAllUserEvents(data)

      if (result.status === 200) {
        commit('MUT_setTotalEvents', result.data.total)
        commit('MUT_setEvents', result.data.items)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }

      commit('MUT_setLoading', false)
    },
    async ACT_setPageNumber({ commit, dispatch }, value) {
      commit('MUT_setPageNumber', value)
      await dispatch('ACT_getEvents')
    },
    async ACT_getRequestDetails({ commit, dispatch }, id) {
      const result = await bus.getUserEventById(id)
      if (result.status === 200) {
        commit('MUT_setRequestDetails', result.data)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }

      commit('MUT_setDetailsLoading', false)
    },
    ACT_toggleMobileFilter({ getters, commit }) {
      commit('MUT_setMobileFilter', !getters.mobileFilterActive)
    },
    ACT_applySortFilter({ commit, dispatch }, data) {
      commit('MUT_setSortName', data.name)
      commit('MUT_setSortBy', data.value)
      dispatch('ACT_getEvents')
    },
    ACT_hideMobileFilter({ commit }) {
      commit('MUT_setMobileFilter', false)
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
  },
}
