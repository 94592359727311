import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './modules/auth/auth'
import profile from './modules/profile/profile'
import user from './modules/users/user'
import users from './modules/users/users'
import usersSearch from './modules/users/usersSearch'
import ctns from './modules/ctnsManagement/ctns'
import changeSim from './modules/ctnsManagement/changeSim'
import blockUnblockCtn from './modules/ctnsManagement/blockUnblockCtn'
import detalization from './modules/ctnsManagement/detalization'
import roaming from './modules/ctnsManagement/roaming'
import ctnInfo from './modules/ctnsManagement/ctnInfo'
import services from './modules/ctnsManagement/services'
import finance from './modules/finance/finance'
import budget from './modules/budget/budget'
import faq from './modules/faq/faq'
import requestsHistory from './modules/requestsHistory/requestsHistory'
import globalNotifications from './modules/globalNotifications/globalNotifications'
import reports from './modules/reports/reports'
import promo from './modules/promo/promo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isNotificationActive: false,
    notificationText: '',
    notificationType: 'error',
  },
  getters: {
    isNotificationActive: state => state.isNotificationActive,
    notificationText: state => state.notificationText,
    notificationType: state => state.notificationType,
  },
  mutations: {
    MUT_setNotification(state, { type, text }) {
      state.notificationText = text
      state.notificationType = type
      state.isNotificationActive = true
    },
    MUT_closeNotification(state) {
      state.isNotificationActive = false
    },
  },
  actions: {
    ACT_setNotification({ commit }, payload) {
      commit('MUT_setNotification', payload)
    },
    ACT_closeNotification({ commit }) {
      commit('MUT_closeNotification')
    },
  },
  modules: {
    auth,
    profile,
    user,
    ctns,
    users,
    usersSearch,
    budget,
    detalization,
    changeSim,
    blockUnblockCtn,
    roaming,
    faq,
    ctnInfo,
    services,
    finance,
    requestsHistory,
    globalNotifications,
    reports,
    promo,
  },
})
