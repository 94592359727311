import { bus } from '@/main'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    currentSection: {},
    modalActive: false,
    modalName: '',
    sections: [],
    sectionForAll: [
      {
        id: 6,
        title: i18n.t('reports.REPORT_TYPE_5'),
        name: 'REPORT_TYPE_5',
      },
    ],
    customerHierarchyId: '',
    dateYear: '',
    dateMonth: '',
    reportId: '',
    ban: '',
    subscriberNo: '',
    hierarchyIdError: false,
    isWaitingReport: false,
    reportReady: false,
    abortReq: false,
  },
  getters: {
    sections: state => state.sections,
    sectionForAll: state => state.sectionForAll,
    currentSection: state => state.currentSection,
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    customerHierarchyId: state => state.customerHierarchyId,
    hierarchyIdError: state => state.hierarchyIdError,
    dateYear: state => state.dateYear,
    dateMonth: state => state.dateMonth,
    reportId: state => state.reportId,
    canDownloadAcIsInReport: state => state.canDownloadAcIsInReport,
    isWaitingReport: state => state.isWaitingReport,
    reportReady: state => state.reportReady,
    abortReq: state => state.abortReq,
    ban: state => state.ban,
    subscriberNo: state => state.subscriberNo,
  },
  mutations: {
    MUT_setCurrentSection(state, obj) {
      state.currentSection = obj
    },
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_setModalName(state, value) {
      state.modalName = value
    },
    MUT_setCustomerHierarchyId(state, value) {
      state.customerHierarchyId = value
    },
    MUT_setDateYear(state, value) {
      state.dateYear = value
    },
    MUT_setDateMonth(state, value) {
      state.dateMonth = value
    },
    MUT_setReportId(state, value) {
      state.reportId = value
    },
    MUT_setHierarchyIdError(state, value) {
      state.hierarchyIdError = value
    },
    MUT_setWaitingReport(state, bool) {
      state.isWaitingReport = bool
    },
    MUT_setReportReady(state, bool) {
      state.reportReady = bool
    },
    MUT_setAbortReq(state, bool) {
      state.abortReq = bool
      if (bool)
        state.isWaitingReport = false
    },
    MUT_setBan(state, bool) {
      state.reportReady = bool
    },
    MUT_setSubscriberNo(state, bool) {
      state.reportReady = bool
    },
    MUT_setAllSections(state, data) {
      state.sections = data
    },
  },
  actions: {
    ACT_getReport({ getters, commit, dispatch }, reportType) {
      commit('MUT_setWaitingReport', true)
      commit('MUT_setAbortReq', false)
      if (
        getters.customerHierarchyId !== null
        && getters.customerHierarchyId.length > 0
      ) {
        const body = {
          hierarchyId: getters.customerHierarchyId,
          year: getters.dateYear,
          month: getters.dateMonth,
        }
        if (reportType !== 'R001') {
          delete body.year
          delete body.month
        }
        bus.requestReportId(reportType, body).then((res) => {
          if (res.status === 200) {
            commit('MUT_setReportId', res.data.id)
            dispatch('ACT_requesReportStatus')
          }
          else {
            commit('MUT_setModal', true)
            commit('MUT_setModalName', 'error')
            commit('MUT_setWaitingReport', false)
          }
        })
      }
      else {
        commit('MUT_setHierarchyIdError', true)
      }
    },
    ACT_requesReportStatus({ getters, commit, dispatch }) {
      if (!getters.abortReq) {
        bus.requesReportStatus(getters.reportId).then((res) => {
          if (res.data.status === 'DONE' && !getters.abortReq) {
            commit('MUT_setWaitingReport', false)
            commit('MUT_setReportReady', true)
          }
          else {
            setTimeout(() => {
              dispatch('ACT_requesReportStatus')
            }, 10000)
          }
        })
      }
      else {
        commit('MUT_setWaitingReport', false)
      }
    },
    ACT_getAllReports({ commit }) {
      bus.getAllReportS().then((res) => {
        if (res.status === 200) {
          commit('MUT_setAllSections', res.data)
          commit('MUT_setCurrentSection', res.data[0])
        }
      })
    },
  },
}
