<template>
  <div class="notification">
    <div class="notification__status">
      <div class="icon-toast" :class="`icon-toast--${type}`" />
      {{ text }}
    </div>
    <div class="icon-close" @click="$emit('closeNotification')" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      required: true,
      type: String,
      validator: value => ['success', 'error'].includes(value),
      default: 'success',
    },
    text: {
      required: true,
      type: String,
    },
  },
  created() {
    setTimeout(() => this.$emit('closeNotification'), 3000)
  },
}
</script>

<style lang="scss" scoped>
.notification {
  background: $color-white;
  box-shadow: 0px 32px 64px rgba(16, 8, 63, 0.12);
  border-radius: 12px;
  width: 454px;
  z-index: 99999;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  padding: 12px 26px 12px 16px;
  &__status {
    @include flexbox;
    @include align-items(center);
    font-weight: bold;
    font-size: 16px;
    column-gap: 12px;
    line-height: 22px;
    color: $color-black;
  }
}
.icon-close {
  width: 14px;
  height: 14px;
  background: url("../../assets/icons/xmark.svg");
  cursor: pointer;
}
.icon-toast {
  width: 42px;
  height: 42px;
  &--success {
    background: url("../../assets/icons/toast-success.svg");
  }
  &--error {
    background: url("../../assets/icons/toast-error.svg");
  }
}
@media (max-width: 768px) {
  .notification {
    width: 95%;
    margin: 0 auto;
    position: fixed;
    top: 70px;
  }
}
</style>
