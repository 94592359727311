import { data } from './data/tariffs'
import { bus } from '@/main'
import i18n from '@/i18n'
import router from '@/router'

const initialData = {
  roamingList: [],
  roamingText: '',
  roamingArray: data,
  isRoamLoading: false,
  isListChecked: false,
  modalActive: false,
  modalName: '',
  paymentType: null,
  notificationType: false,
  modalTitle: '',
  tariffCode: '',
  modalText: '',
  tariffName: '',
  disableText: '',
  disableTitle: '',
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    disableText: state => state.disableText,
    disableTitle: state => state.disableTitle,
    tariffName: state => state.tariffName,
    tariffCode: state => state.tariffCode,
    modalTitle: state => state.modalTitle,
    modalText: state => state.modalText,
    notificationType: state => state.notificationType,
    paymentType: state => state.paymentType,
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    roamingText: state => state.roamingText,
    roamingList: state => state.roamingList,
    roamingArray: state => state.roamingArray,
    isRoamLoading: state => state.isRoamLoading,
    isListChecked: state => state.isListChecked,
  },
  mutations: {
    MUT_setRoamingList(state, payload) {
      state.roamingList = payload
    },
    MUT_setRoamingInfo(state, value) {
      state.roamingText = value.description
    },
    MUT_setNotificationType(state, payload) {
      state.notificationType = payload
    },
    MUT_setLoading(state, value) {
      state.isRoamLoading = value
    },
    MUT_setModalActive(state, payload) {
      state.modalActive = payload
    },
    MUT_setModalName(state, payload) {
      state.modalName = payload
    },
    MUT_setRoamingSocs(state, array) {
      state.roamingArray = state.roamingArray.filter(item =>
        array.includes(item.soc),
      )
      state.isListChecked = true
    },
    MUT_setTariffName(state, payload) {
      state.tariffName = payload
    },
    MUT_changePaymentType(state, payload) {
      state.paymentType = payload
    },
    MUT_setModalTitle(state, payload) {
      state.modalTitle = payload
    },
    MUT_setModalText(state, payload) {
      state.modalText = payload
    },
    MUT_setTariffCode(state, payload) {
      state.tariffCode = payload
    },
    MUT_setDisableTitle(state, payload) {
      state.disableTitle = payload
    },
    MUT_setDisableText(state, payload) {
      state.disableText = payload
    },
  },
  actions: {
    ACT_removeRoaming({ commit, getters }) {
      commit('ctnInfo/MUT_setModal', true, { root: true })
      commit('ctnInfo/MUT_setModalName', 'roaming', { root: true })
      if (!getters.roamingList[0].archival) {
        commit('MUT_setDisableTitle', i18n.t('roaming.disconnectTitle'))
        commit('MUT_setDisableText', i18n.t('roaming.disconnectText'))
      }
      else {
        commit('MUT_setDisableTitle', i18n.t('roaming.archiveTitle'))
        commit('MUT_setDisableText', i18n.t('roaming.archiveText'))
      }
    },
    async ACT_disableRoaming({ getters, commit }, payload) {
      commit('MUT_setLoading', true)
      const body = {
        ...payload,
        tariffPlanCode: getters.roamingList[0].soc,
      }

      const { status } = await bus.disableRoaming(body)

      if (status === 200) {
        setTimeout(() => {
          commit('MUT_setLoading', false)
          commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
          commit('ctnInfo/MUT_setModalType', true, { root: true })
          commit(
            'ctnInfo/MUT_setErrorTitle',
            i18n.t('roaming.disconnectSuccess'),
            {
              root: true,
            },
          )
          commit(
            'ctnInfo/MUT_setErrorText',
            `${i18n.t('roaming.disconnectSuccessText')} ${
              payload.subscriberNo
            }`,
            {
              root: true,
            },
          )
        }, 3000)
      }
      else {
        commit('MUT_setLoading', false)
        commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
        commit('ctnInfo/MUT_setModalType', false, { root: true })
        commit('ctnInfo/MUT_setErrorTitle', i18n.t('errors.title'), {
          root: true,
        })
        commit('ctnInfo/MUT_setErrorText', i18n.t('errors.managerContact'), {
          root: true,
        })
      }
    },
    async ACT_addRoaming({ getters, commit, rootGetters }, payload) {
      commit('MUT_setLoading', true)
      const body = {
        ...payload,
        charges: getters.paymentType,
        tariffPlanCode: getters.tariffCode,
      }

      const { status, data } = await bus.connectRoaming(body)
      if (status === 200) {
        setTimeout(() => {
          commit('MUT_setLoading', false)
          commit('MUT_setModalName', 'notification')
          commit('MUT_setNotificationType', true)
          commit('MUT_setModalTitle', i18n.t('roaming.connectSuccess'))
          if (
            getters.tariffCode === 'RM9900G'
            || getters.tariffCode === 'RM14900G'
          ) {
            commit(
              'MUT_setModalText',
              `${i18n.t('roaming.connectSuccessText')} ${
                payload.subscriberNo
              }. ${i18n.t('roaming.serviceNotice')}.`,
            )
          }
          else {
            commit(
              'MUT_setModalText',
              `${i18n.t('roaming.connectSuccessText')} ${payload.subscriberNo}`,
            )
          }
        }, 3000)
      }
      else {
        commit('MUT_setModalName', 'notification')
        commit('MUT_setNotificationType', false)
        if (data.message === 'not_enough_credit_limit') {
          if (
            rootGetters['ctnInfo/accountType'] === '16'
            || rootGetters['ctnInfo/accountType'] === '216'
            || rootGetters['ctnInfo/accountType'] === '416'
          ) {
            commit('MUT_setModalTitle', i18n.t('roaming.creditLimitError'))
            commit(
              'MUT_setModalText',
              i18n.t('roaming.creditLimitBalanceError'),
            )
          }
          else {
            commit('MUT_setModalTitle', i18n.t('roaming.creditLimitError'))
            commit('MUT_setModalText', i18n.t('roaming.creditLimitErrorDesc'))
          }
        }
        else {
          commit('MUT_setModalTitle', i18n.t('errors.title'))
          commit('MUT_setModalText', i18n.t('roaming.roamingConnnectError'))
        }
        commit('MUT_setLoading', false)
      }
    },
    ACT_connectRoaming({ commit, rootGetters }, payload) {
      commit('MUT_setTariffCode', payload.tariffPlanCode)
      commit('MUT_setTariffName', payload.name)
      commit('MUT_setModalActive', true)
      if (
        rootGetters['ctnInfo/accountType'] === '16'
        || rootGetters['ctnInfo/accountType'] === '216'
        || rootGetters['ctnInfo/accountType'] === '416'
      )
        commit('MUT_setModalName', 'type')
      else
        commit('MUT_setModalName', 'roaming')
    },
    ACT_changePaymentView({ commit }) {
      commit('MUT_setModalName', 'roaming')
    },
    ACT_closeAction({ commit }) {
      commit('MUT_changePaymentType', null)
      commit('MUT_setModalActive', false)
    },
    async ACT_checkAvailableList({ commit }, payload) {
      const { data, status } = await bus.checkAvailableList(payload)

      if (status === 200) {
        commit('MUT_setRoamingSocs', data)
        if (!router.currentRoute.path.includes('/roaming')) {
          await router.push({
            name: 'Roaming',
            params: { id: payload.subscriberNo },
            query: { ban: payload.ban },
          })
        }
      }
      else {
        commit('ctnInfo/MUT_setModalName', 'notification', { root: true })
        commit('ctnInfo/MUT_setModalType', false, { root: true })
        commit('ctnInfo/MUT_setErrorTitle', i18n.t('roaming.errorTitle'), {
          root: true,
        })
        commit('ctnInfo/MUT_setErrorText', i18n.t('roaming.errorDesc'), {
          root: true,
        })
        commit('ctnInfo/MUT_setModal', true, { root: true })
      }
    },
    async ACT_getRoamingList({ commit }, payload) {
      const { data } = await bus.getRoamingList(payload)

      commit('MUT_setRoamingList', data)
    },
    ACT_setRoamingInfo({ commit }, payload) {
      commit('ctnInfo/MUT_setModal', true, { root: true })
      commit('ctnInfo/MUT_setModalName', 'roaming_info', { root: true })
      commit('MUT_setRoamingInfo', payload)
    },
    ACT_closeModal({ commit }) {
      commit('MUT_setModalActive', false)
    },
    ACT_changePaymentType({ commit }, payload) {
      commit('MUT_changePaymentType', payload)
    },
  },
}
