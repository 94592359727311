import { GET, POST } from '@/helpers/base'

const endpoints = {
  getRoamingList: '/api/v1/roaming/soc/list',
  checkAvailableList: '/api/v1/roaming/enable/available',
  connectRoaming: '/api/v1/roaming/enable',
  disableRoaming: '/api/v1/roaming/disable',
}

const RoamingApi = {
  methods: {
    getRoamingList(data) {
      const endpoint = endpoints.getRoamingList

      return GET(`${endpoint}`, data)
    },
    checkAvailableList(data) {
      const endpoint = endpoints.checkAvailableList

      return GET(`${endpoint}`, data)
    },
    connectRoaming(data) {
      const endpoint = endpoints.connectRoaming

      return POST(`${endpoint}`, data)
    },
    disableRoaming(data) {
      const endpoint = endpoints.disableRoaming

      return POST(`${endpoint}`, data)
    },
  },
}

export { RoamingApi }
