import axios from 'axios'
import i18n from '../i18n'
import { getAccessToken } from '@/helpers/security'

const endpoints = {
  getDetalisationReport: '/reports/ctns/usages',
  getCtnsDetalisationReport: '/reports/ctns',
  requestAcIsInReport: '/reports',
}

const FileApi = {
  methods: {
    async getDetalisationReport(body) {
      const headers = {
        'Authorization': `Bearer ${getAccessToken()}`,
        'content-type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Accept-language': i18n.locale,
      }
      const endpoint = endpoints.getDetalisationReport

      const response = await axios
        .get(
          `${endpoint}?ban=${body.ban}&subscriberNo=${body.subscriberNo}&year=${body.year}&month=${body.month}`,
          { headers, responseType: 'blob' },
        )
        .catch(e => Promise.reject(e.response || e))

      return response
    },
    async getCtnsDetalisationReport(params) {
      const headers = {
        'Authorization': `Bearer ${getAccessToken()}`,
        'content-type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Accept-language': i18n.locale,
      }
      const endpoint = endpoints.getCtnsDetalisationReport

      const response = await axios
        .get(endpoint, { params, headers, responseType: 'blob' })
        .catch(e => Promise.reject(e.response || e))

      return response
    },
    async getReport(id) {
      const headers = {
        'Authorization': `Bearer ${getAccessToken()}`,
        'content-type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Accept-language': i18n.locale,
      }
      const endpoint = endpoints.requestAcIsInReport

      const response = await axios
        .get(`${endpoint}/${id}/download`, { headers, responseType: 'blob' })
        .catch(e => Promise.reject(e.response || e))

      return response
    },
  },
}

export { FileApi }
