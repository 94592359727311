import Vue from 'vue'
import axios from 'axios'
import VueMask from 'v-mask'
import {
  Button,
  Checkbox,
  Datepicker,
  DatepickerOutline,
  FieldOutline,
  Pagination,
  RadioButton,
  Select,
  Statuses,
  Textarea,
} from 'beeline-design-system'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vClickOutside from './plugins/v-click-outside/index'

// Mixins
import { helperMixin } from './mixins/helper'
import { AuthApi } from './mixins/AuthApi'
import { OtpApi } from './mixins/OtpApi'
import { CtnApi } from './mixins/CtnApi'
import { UserApi } from './mixins/UserApi'
import { CreditLimitApi } from './mixins/CreditLimitApi'
import { SimApi } from './mixins/SimApi'
import { SocApi } from './mixins/SocApi'
import { ReportsApi } from './mixins/ReportsApi'
import { GlovalNotificationsApi } from './mixins/GlovalNotificationsApi'
import { BudgetApi } from './mixins/BudgetApi'
import { FileApi } from './mixins/FileApi'
import { ESimApi } from './mixins/ESimApi'
import { RoamingApi } from './mixins/RoamingApi'
import { PromoApi } from './mixins/PromoApi'

//  API Files
Vue.mixin(helperMixin)
Vue.mixin(BudgetApi)
Vue.mixin(AuthApi)
Vue.mixin(OtpApi)
Vue.mixin(ESimApi)
Vue.mixin(CtnApi)
Vue.mixin(UserApi)
Vue.mixin(CreditLimitApi)
Vue.mixin(SimApi)
Vue.mixin(SocApi)
Vue.mixin(ReportsApi)
Vue.mixin(RoamingApi)
Vue.mixin(GlovalNotificationsApi)
Vue.mixin(FileApi)
Vue.mixin(PromoApi)

Vue.use(VueMask)
Vue.use(vClickOutside)

// Beeline Design System Components
Vue.component('BeeSelect', Select)
Vue.component('BeeDatepicker', Datepicker)
Vue.component('BeeDatepickerOutline', DatepickerOutline)
Vue.component('BeeRadioButton', RadioButton)
Vue.component('BeeCheckbox', Checkbox)
Vue.component('BeeInput', FieldOutline)
Vue.component('BeeButton', Button)
Vue.component('BeePagination', Pagination)
Vue.component('BeeStatus', Statuses)
Vue.component('BeeTextarea', Textarea)

Vue.config.productionTip = false

export const bus = new Vue()

// Base URL for axios
axios.defaults.baseURL = process.env.VUE_APP_URL

Vue.filter('highlight', (word: any, query: any) => {
  const check = new RegExp(query, 'ig')
  return word.toString().replace(check, (matchedText: any) => {
    return `<span class='highlight'>${matchedText}</span>`
  })
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
