import { bus } from '@/main'
import i18n from '@/i18n'
import router from '@/router'

const initialData = {
  step: 'selectSimType',
  simSerialNo: '',
  modalActive: false,
  isLoading: false,
  isAgreed: false,
  modalName: '',
  modalType: '',
  esimStatus: '',
  esim: {
    step1: {
      active: true,
      confirmed: false,
    },
    step2: {
      active: false,
      confirmed: false,
    },
    step3: {
      active: false,
      confirmed: false,
      isSuccess: true,
      isLoading: false,
    },
    step4: {
      active: false,
      confirmed: false,
      isSuccess: true,
      isLoading: false,
    },
    step5: {
      active: false,
      confirmed: false,
    },
  },
  error: {
    errorTitle: '',
    errorText: '',
  },
}

export default {
  namespaced: true,
  state: {
    ...initialData,
  },
  getters: {
    step: state => state.step,
    esim: state => state.esim,
    isAgreed: state => state.isAgreed,
    simSerialNo: state => state.simSerialNo,
    errorTitle: state => state.error.errorTitle,
    errorText: state => state.error.errorText,
    modalActive: state => state.modalActive,
    modalName: state => state.modalName,
    modalType: state => state.modalType,
    esimStatus: state => state.esimStatus,
    isLoading: state => state.isLoading,
  },
  mutations: {
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_setModalName(state, name) {
      state.modalName = name
    },
    MUT_changeStep(state, name) {
      state.step = name
    },
    MUT_setSimSerialNo(state, number) {
      state.simSerialNo = number
    },
    MUT_setError(state, data) {
      state.error.errorTitle = data.title
      state.error.errorText = data.text
    },
    MUT_setModalType(state, value) {
      state.modalType = value
    },
    MUT_setErrorText(state, text) {
      state.errorText = text
    },
    MUT_setLoading(state, value) {
      state.isLoading = value
    },
    MUT_setEsimStatus(state, status) {
      state.esimStatus = status
    },
    MUT_setStepConfirmed(state, [number, value]) {
      state.esim[number].confirmed = value
    },
    MUT_setStepLoading(state, value) {
      state.esim.step3.isLoading = value
    },
    MUT_setActiveStep(state, [number, value]) {
      state.esim[number].active = value
    },
    MUT_setSuccess(state, [payload, value]) {
      state.esim[payload].isSuccess = value
    },
    MUT_changeAgreement(state, value) {
      state.isAgreed = value
    },
    MUT_resetState(state) {
      Object.assign(state, initialData)
    },
    MUT_resetEsimSteps(state) {
      state.esim = {
        step1: {
          active: true,
          confirmed: false,
        },
        step2: {
          active: false,
          confirmed: false,
        },
        step3: {
          active: false,
          confirmed: false,
          isSuccess: true,
          isLoading: false,
        },
        step4: {
          active: false,
          confirmed: false,
          isSuccess: true,
          isLoading: false,
        },
        step5: {
          active: false,
          confirmed: false,
        },
      }
    },
  },
  actions: {
    async ACT_checkEsimStatus({ commit, dispatch }, number) {
      commit('MUT_setLoading', true)

      const { status, data } = await bus.checkEsimStatus(number)

      if (status === 200) {
        commit('MUT_setEsimStatus', 'CONTINUE')
      }
      else if (status === 404 || status === 403) {
        commit('MUT_setEsimStatus', 'START')
      }
      else {
        commit('MUT_setEsimStatus', 'START')
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }

      if (!router.history.current.path.includes('change-esim'))
        return

      if (status === 200) {
        if (data.status === 'RESERVED') {
          commit('MUT_setStepConfirmed', ['step1', true])
          commit('MUT_setActiveStep', ['step1', false])
          commit('MUT_setActiveStep', ['step2', true])
          commit('MUT_changeAgreement', true)
        }
        else if (data.status === 'ERROR_REPLACE') {
          commit('MUT_setStepConfirmed', ['step1', true])
          commit('MUT_setStepConfirmed', ['step2', true])
          commit('MUT_changeAgreement', true)
          commit('MUT_setActiveStep', ['step1', false])
          commit('MUT_setActiveStep', ['step3', true])
          commit('MUT_setSuccess', ['step3', false])
        }
        else if (data.status === 'REPLACED') {
          commit('MUT_setStepConfirmed', ['step1', true])
          commit('MUT_setStepConfirmed', ['step2', true])
          commit('MUT_setStepConfirmed', ['step3', true])
          commit('MUT_setSuccess', ['step3', true])
          commit('MUT_changeAgreement', true)
          commit('MUT_setStepConfirmed', ['step4', true])
          commit('MUT_setActiveStep', ['step1', false])
          commit('MUT_setActiveStep', ['step2', false])
          commit('MUT_setActiveStep', ['step3', false])
          commit('MUT_setActiveStep', ['step5', true])
          commit('MUT_setModal', true)
          commit('MUT_setModalName', 'qrSent')
        }
      }

      commit('MUT_setLoading', false)
    },
    async ACT_confirmInstruction({ commit, dispatch }, payload) {
      commit('MUT_setLoading', true)

      const { status } = await bus.confirmEsimInstruction(payload)

      if (status === 200) {
        commit('MUT_setStepConfirmed', ['step1', true])
        commit('MUT_setActiveStep', ['step1', false])
        commit('MUT_setActiveStep', ['step2', true])
        commit('MUT_changeAgreement', true)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }

      commit('MUT_setLoading', false)
    },
    async ACT_confirmEmail({ commit, dispatch }, payload) {
      const { status } = await bus.confirmEsimEmail(payload)

      commit('MUT_setStepConfirmed', ['step1', true])
      commit('MUT_setStepConfirmed', ['step2', true])
      commit('MUT_setActiveStep', ['step1', false])
      commit('MUT_setActiveStep', ['step2', false])
      commit('MUT_setActiveStep', ['step3', true])
      commit('MUT_changeAgreement', true)
      commit('MUT_setStepLoading', true)

      if (status === 200) {
        dispatch('ACT_checkEsimStatus', { ctn: payload.ctn })
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }

      commit('MUT_setStepLoading', false)
    },
    ACT_changeInfoModal({ commit }, name) {
      commit('MUT_setModalName', name)
      commit('MUT_setModal', true)
    },
    async ACT_sendQr({ commit, dispatch }, payload) {
      const { status } = await bus.sendQr(payload)

      if (status === 200) {
        commit('MUT_setSuccess', ['step4', true])
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }
      dispatch('ACT_checkEsimStatus', { ctn: payload.ctn })
    },
    ACT_setCustomer({ commit, dispatch }, obj) {
      commit('MUT_setModalName', 'notification')
      commit('MUT_setModal', true)

      if (obj.status === 200) {
        commit('MUT_setModalType', true)
        commit('MUT_setError', {
          title: i18n.t('ctnInfo.userChangeInfoSuccessTitle'),
          text: `${i18n.t('ctnInfo.userChangeInfoSuccessDesc')} ${
            obj.subscriberNo
          }`,
        })

        dispatch('ctnInfo/ACT_getCustomerInfo', obj, { root: true })
      }
      else {
        commit('MUT_setModalType', false)
        commit('MUT_setError', {
          title: i18n.t('errors.title'),
          text: i18n.t('errors.dataChangeError'),
        })
      }
    },
    ACT_changeStep({ commit }, value) {
      commit('MUT_changeStep', value)
    },
    async ACT_qrReceived({ commit, dispatch }, payload) {
      const { status } = await bus.qrReceived(payload)

      if (status === 200) {
        commit('MUT_resetState')
        commit('MUT_resetEsimSteps')
        router.go(-1)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }
    },
    async ACT_cancelEsim({ commit, dispatch }, value) {
      const { status } = await bus.cancelEsim(value)

      if (status === 200) {
        commit('MUT_resetState')
        commit('MUT_resetEsimSteps')
        router.go(-1)
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.serverError') },
          { root: true },
        )
      }
    },
    ACT_closeModal({ commit }) {
      commit('MUT_setModal', false)
    },
    async ACT_replaceSimSerialNo({ getters, commit, rootGetters }) {
      const { status } = await bus.replaceSim({
        ban: rootGetters['ctnInfo/ban'],
        simSerialNo: getters.simSerialNo,
        subscriberNo: rootGetters['ctnInfo/subscriberNo'],
      })
      if (status === 200) {
        commit('MUT_changeStep', 'success')
      }
      else {
        commit('MUT_changeStep', 'error')
        commit('MUT_setError', {
          title: i18n.t('errors.simChangeError'),
          text: i18n.t('errors.contactManagerDesc'),
        })
      }
    },
    ACT_changeAgreement({ commit }, value) {
      commit('MUT_changeAgreement', value)
    },
    ACT_setLoading({ commit }, value) {
      commit('MUT_setLoading', value)
    },
    ACT_resetState({ commit }) {
      commit('MUT_resetState')
    },
  },
}
