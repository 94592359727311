import { POST } from '@/helpers/base'

const endpoints = {
  logPromo: '/api/v1/banners/details',
  sendPromoForm: '/api/v1/banners/applications',
}

const PromoApi = {
  methods: {
    logPromo(data) {
      const endpoint = endpoints.logPromo

      return POST(`${endpoint}`, data)
    },
    sendPromoForm(data) {
      const endpoint = endpoints.sendPromoForm

      return POST(`${endpoint}`, data)
    },
  },
}

export { PromoApi }
