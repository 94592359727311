<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'Modal',
    }
  },
}
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  overflow-y: scroll;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(38, 36, 36, 0.7);
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  max-height: 90vh;
}
</style>
