<template>
  <div class="singe-global">
    <div class="singe-global__image">
      <img src="../../assets/images/single-global-notif.png">
    </div>
    <div class="singe-global__body">
      <div class="singe-global__title">
        {{ notificationsList[0].title }}
        <div class="close" @click="$emit('closeModal')">
          &times;
        </div>
      </div>
      <div
        class="singe-global__desc"
        v-html="notificationsList[0].description"
      />
      <BeeButton
        class="btn"
        :label="$t('btn.understand')"
        size="m"
        @onClick="$emit('closeModal')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('globalNotifications', ['notificationsList']),
  },
}
</script>

<style scoped lang="scss">
.singe-global {
  @include flexbox;
  max-width: 760px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  &__image {
    @include flexbox;
    @include align-items(center);
    background: #fff1b4;
    img {
      display: block;
      max-width: 360px;
    }
  }
  &__body {
    padding: 14px 24px;
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #10083f;
    padding: 24px 71px 14px 0;
    position: relative;
  }
  &__desc {
    font-size: 18px;
    line-height: 24px;
    color: #403965;
    margin-bottom: 24px;
    max-height: 380px;
    overflow-y: auto;
    min-height: 240px;
    min-width: 320px;
    p {
      margin: 0;
    }
  }
  .btn {
    margin-left: auto;
    font-weight: 600;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    width: 212px;
  }
  .close {
    width: 32px;
    height: 32px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    position: absolute;
    right: -15px;
    top: -5px;
    color: #706b8c;
    cursor: pointer;
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .singe-global {
    width: 95%;
    max-width: 95%;
    margin: auto;
    &__image {
      display: none;
    }
    &__desc {
      min-width: 280px;
    }
    &__body {
      flex-grow: 1;
      max-width: 100%;
      .btn {
        margin: auto;
      }
    }
  }
}
</style>
