import { GET, POST, PUT } from '@/helpers/base'

const endpoints = {
  getCtns: '/api/v1/ctns',
  getCities: '/api/v1/dictionary/marketcodes',
  checkCtnsToRestore: '/api/v1/ctns/check-to-restore',
  checkCtnsToSuspend: '/api/v1/ctns/check-to-suspend',
  ctnsRestore: '/api/v1/ctns/restore',
  ctnsBlock: '/api/v1/ctns/suspend',
  getCtnBalance: '/balance',
  getPriInfo: '/api/v1/bans/',
  getCustomerInfo: '/api/v1/ctns/',
  getCtnUsages: '/api/v1/ctns/usages',
  logDetalization: '/api/v1/ctns/mark-view-usages',
  getBudget: '/api/v1/ctn-budgets',
  imei: '/api/v1/imei/',
}

const CtnApi = {
  methods: {
    getCtnInfo(subscriberNo, ban) {
      const endpoint = endpoints.getCtns

      return GET(`${endpoint}/${subscriberNo}/info?ban=${ban}`)
    },
    getCtnRests(subscriberNo) {
      const endpoint = endpoints.getCtns

      return GET(`${endpoint}/${subscriberNo}/rests`)
    },
    getCtnBalance(subscriberNo, ban) {
      const endpoint = endpoints.getCtns

      const endpoint1 = endpoints.getCtnBalance

      return GET(`${endpoint}/${subscriberNo}${endpoint1}?ban=${ban}`)
    },
    ctnsRestore(data) {
      const endpoint = endpoints.ctnsRestore

      return PUT(`${endpoint}`, data)
    },
    ctnsBlock(data) {
      const endpoint = endpoints.ctnsBlock

      return PUT(`${endpoint}`, data)
    },
    checkCtnsToRestore(query) {
      const endpoint = endpoints.checkCtnsToRestore

      return GET(`${endpoint}`, query)
    },
    checkCtnsToSuspend(query) {
      const endpoint = endpoints.checkCtnsToSuspend

      return GET(`${endpoint}`, query)
    },
    getCtns(query) {
      const endpoint = endpoints.getCtns

      return GET(`${endpoint}`, query)
    },
    getCities() {
      const endpoint = endpoints.getCities

      return GET(`${endpoint}`)
    },
    getPriInfo(ban) {
      const endpoint = endpoints.getPriInfo

      return GET(`${endpoint}${ban}/agreements`)
    },
    getCustomerInfo(subscriberNo, login) {
      const endpoint = endpoints.getCustomerInfo

      return GET(`${endpoint}${subscriberNo}/customInfo?login=${login}`)
    },
    setCustomerInfo(subscriberNo, login, body) {
      const endpoint = endpoints.getCustomerInfo

      return POST(`${endpoint}${subscriberNo}/customInfo?login=${login}`, body)
    },
    getDeviceInfo(subscriberNo) {
      const endpoint = endpoints.getCtns

      return GET(`${endpoint}/${subscriberNo}/devices`)
    },
    getCtnUsages(data) {
      const endpoint = endpoints.getCtnUsages

      return GET(`${endpoint}`, data)
    },
    logDetalization(data) {
      const endpoint = endpoints.logDetalization

      return POST(
        `${endpoint}?ban=${data.ban}&subscriberNo=${data.subscriberNo}`,
      )
    },
    getBudget(data) {
      const endpoint = endpoints.getBudget

      return GET(`${endpoint}`, data)
    },
    getPinPuk(subscriberNo, ban) {
      const endpoint = endpoints.getCtns

      return GET(`${endpoint}/${subscriberNo}/pinPuk?ban=${ban}`)
    },
    getImei(subscriberNo) {
      const endpoint = endpoints.imei

      return GET(`${endpoint}${subscriberNo}`)
    },
    getUserImeiInfo(subscriberNo) {
      const endpoint = endpoints.imei

      return GET(`${endpoint}${subscriberNo}/info`)
    },
    imeiRegAction(data) {
      const endpoint = endpoints.imei

      return POST(`${endpoint}`, data)
    },
    getImeiRegStatus(subscriberNo) {
      const endpoint = endpoints.imei

      return GET(`${endpoint}${subscriberNo}/status`)
    },
    imeiRegStatusReaded(subscriberNo) {
      const endpoint = endpoints.imei

      return PUT(`${endpoint}${subscriberNo}/read`)
    },
  },
}

export { CtnApi }
