import { DELETE, GET, POST, PUT } from '@/helpers/base'

const endpoints = {
  user: '/api/v1/user',
  userProfile: '/api/v1/user/profile',
  getUsersByHierarchy: '/api/v1/user/all/by-hierarchy',
  getUserEventById: '/api/v1/user/event',
  getAllUserEvents: '/api/v1/user/events',
  setFaqMark: '/api/v1/faq/mark',
  getUserBin: '/api/v1/hierarchy',
}

const UserApi = {
  methods: {
    getUserBin(id) {
      const endpoint = endpoints.getUserBin

      return GET(`${endpoint}/${id}/bin`)
    },
    getUserProfile() {
      const endpoint = endpoints.userProfile

      return GET(`${endpoint}`)
    },
    getAllUserEvents(data) {
      const endpoint = endpoints.getAllUserEvents

      return GET(`${endpoint}`, data)
    },
    getUserEventById(id) {
      const endpoint = endpoints.getUserEventById

      return GET(`${endpoint}/${id}`)
    },
    editUserProfile(data) {
      const endpoint = endpoints.userProfile

      return PUT(`${endpoint}`, data)
    },
    getUserById(id) {
      const endpoint = endpoints.user

      return GET(`${endpoint}/${id}`)
    },
    createUser(data) {
      const endpoint = endpoints.user

      return POST(`${endpoint}`, data)
    },
    setFaqMark() {
      const endpoint = endpoints.setFaqMark

      return POST(`${endpoint}`)
    },
    resetOTP(data) {
      const endpoint = endpoints.user

      return POST(`${endpoint}/reset-otp-counter`, data)
    },
    resetCounter(data) {
      const endpoint = endpoints.user

      return POST(`${endpoint}/reset-entry-counter`, data)
    },
    blockUser(data) {
      const endpoint = endpoints.user

      return POST(`${endpoint}/block`, data)
    },
    unblockUser(data) {
      const endpoint = endpoints.user

      return POST(`${endpoint}/unblock`, data)
    },
    editUser(data) {
      const endpoint = endpoints.user

      return PUT(`${endpoint}`, data)
    },
    deleteUser(id) {
      const endpoint = endpoints.user

      return DELETE(`${endpoint}/${id}`)
    },
    getUsersByHierarchy(query) {
      const endpoint = endpoints.getUsersByHierarchy

      return GET(`${endpoint}`, query)
    },
  },
}

export { UserApi }
