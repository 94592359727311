import { bus } from '@/main'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    modalActive: false,
    errors: {
      firstNameError: false,
      emailError: false,
      phoneNumberError: false,
    },
    isUserLoggedIn: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')) || {},
  },
  getters: {
    modalActive: state => state.modalActive,
    isUserLoggedIn: state => state.isUserLoggedIn,
    errors: state => state.errors,
    user: state => state.user,
    email: state => state.user.email,
    bin: state => state.user.bin,
    firstName: state => state.user.firstName,
    secondName: state => state.user.secondName,
    middleName: state => state.user.middleName,
    role: state => state.user.role,
    phoneNumber: state => state.user.phoneNumber,
    login: state => state.user.login,
    hierarchyId: state => state.user.hierarchyId,
    companyName: state => state.user.companyName,
    comment: state => state.user.comment,
    isStaff: (state) => {
      return (
        state.user.role === 'STAFF'
        || state.user.role === 'STAFF_ADMIN'
        || state.user.role === 'STAFF_SUPER_ADMIN'
      )
    },
    isHistoryAvailable: (state) => {
      return (
        state.user.role === 'STAFF_ADMIN'
        || state.user.role === 'STAFF_SUPER_ADMIN'
      )
    },
  },
  mutations: {
    MUT_setUserProfile(state, user) {
      state.user = user
    },
    MUT_setUserLoggedIn(state, value) {
      state.isUserLoggedIn = value
    },
    MUT_setProfileValue(state, [type, value]) {
      state.user[type] = value
    },
    MUT_setModal(state, value) {
      state.modalActive = value
    },
    MUT_closeModal(state) {
      state.modalActive = false
    },
    MUT_validateForm(state) {
      state.errors.firstNameError = !state.user.firstName.length

      // Validate email
      !bus.validateEmail(state.user.email) || !state.user.email.length
        ? (state.errors.emailError = true)
        : (state.errors.emailError = false)

      // Validate phoneNumber
      state.user.phoneNumber
      && state.user.phoneNumber.length > 0
      && state.user.phoneNumber.length < 18
        ? (state.errors.phoneNumberError = true)
        : (state.errors.phoneNumberError = false)
    },
  },
  actions: {
    ACT_setProfileValue({ commit }, payload) {
      commit('MUT_setProfileValue', [payload.type, payload.value])
    },
    ACT_resetPassword({ getters, commit, dispatch }) {
      commit('MUT_setModal', true)
      dispatch(
        'auth/ACT_sendOtp',
        { login: getters.login, type: 'EMAIL' },
        { root: true },
      )
    },
    async ACT_editProfile({ commit, getters, dispatch }) {
      commit('MUT_validateForm')

      if (
        getters.errors.firstNameError
        || getters.errors.phoneNumberError
        || getters.errors.emailError
      )
        return

      const result = await bus.editUserProfile({
        email: getters.email,
        firstName: getters.firstName,
        middleName: getters.middleName,
        phoneNumber:
          getters.phoneNumber && getters.phoneNumber.length > 0
            ? bus.formatPhoneToRaw(getters.phoneNumber)
            : '',
        secondName: getters.secondName,
      })

      if (result.status === 200) {
        dispatch(
          'ACT_setNotification',
          { type: 'success', text: i18n.t('user.label.profileChangeSuccess') },
          { root: true },
        )
        dispatch('ACT_getUserProfile')
      }
      else {
        dispatch(
          'ACT_setNotification',
          { type: 'error', text: i18n.t('errors.profileChangeError') },
          { root: true },
        )
      }
    },
    async ACT_getUserProfile({ commit }) {
      await bus
        .getUserProfile()
        .then((res) => {
          const user = res.data
          user.phoneNumber = bus.formatPhone(user.phoneNumber)

          localStorage.setItem('user', JSON.stringify(user))
          commit('MUT_setUserProfile', user)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ACT_closeModal({ commit }) {
      commit('MUT_closeModal')
    },
    async logout() {
      await bus
        .logOut({ token: localStorage.getItem('refresh') })
        .catch((e) => {
          return Promise.reject(e)
        })
      localStorage.removeItem('user')
      localStorage.removeItem('impersonalize')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      location.reload()
    },
  },
}
