import { GET, POST } from '@/helpers/base'

const endpoints = {
  requestInvoice: '/api/v1/info/invoice',
  requestReport: '/api/v1/info/report',
  requestActReport: '/api/v1/reports/act-reconciliations',
  reports: '/reports/',
}

const ReportsApi = {
  methods: {
    getAllReportS() {
      const endpoint = endpoints.reports

      return GET(`${endpoint}`)
    },
    requestInvoice(data) {
      const endpoint = endpoints.requestInvoice

      return POST(`${endpoint}`, data)
    },
    requestReport(data) {
      const endpoint = endpoints.requestReport

      return POST(`${endpoint}`, data)
    },
    requestActReport(data) {
      const endpoint = endpoints.requestActReport

      return POST(`${endpoint}`, data)
    },
    requestReportId(reportType, data) {
      const endpoint = endpoints.reports

      return POST(`${endpoint}${reportType}`, data)
    },
    requesReportStatus(id) {
      const endpoint = endpoints.reports

      return GET(`${endpoint}${id}/status`)
    },
  },
}

export { ReportsApi }
