import { GET, POST, PUT } from '@/helpers/base'

const endpoints = {
  checkBudgetToChange: '/api/v1/ctn-budgets/check-to-change',
  getLimitOptions: '/api/v1/ctn-budgets/limit-options',
  changeBudget: '/api/v1/ctn-budgets/limits',
}

const BudgetApi = {
  methods: {
    checkBudgetToChange(data) {
      const endpoint = endpoints.checkBudgetToChange

      return POST(`${endpoint}`, data)
    },
    getLimitOptions() {
      const endpoint = endpoints.getLimitOptions

      return GET(`${endpoint}`)
    },
    changeBudget(data) {
      const endpoint = endpoints.changeBudget

      return PUT(`${endpoint}`, data)
    },
  },
}

export { BudgetApi }
