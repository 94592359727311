import axios from 'axios'
import i18n from '../i18n'

import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './security'

async function updateUserTokens() {
  await axios
    .post('api/v1/auth/refresh', {
      token: getRefreshToken(),
    })
    .then(res => setUserData(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        localStorage.removeItem('user')
        localStorage.removeItem('impersonalize')
        location.reload()
      }
      else {
        console.error(err)
      }
    })
}

async function setUserData(response) {
  const accessToken = response.accessToken
  const refreshToken = response.refreshToken

  setAccessToken(accessToken)
  setRefreshToken(refreshToken)
}

async function makeRequest(url, params) {
  let response
  if (params.method === 'GET') {
    response = await axios
      .get(url, { headers: params.headers, params: params.searchParams })
      .catch(e => e.response)
  }
  else if (params.method === 'POST') {
    response = await axios
      .post(url, params.body, { headers: params.headers })
      .catch(e => e.response)
  }
  else if (params.method === 'PUT') {
    response = await axios
      .put(url, params.body, { headers: params.headers })
      .catch(e => e.response)
  }
  else if (params.method === 'DELETE') {
    response = await axios
      .delete(url, { data: params.body, headers: params.headers })
      .catch(e => e.response)
  }
  if (response.status === 200) {
    return response
  }
  else if (response.status === 401) {
    await updateUserTokens()
    const newParams = Object.assign({}, params)
    newParams.headers.Authorization = `Bearer ${await getAccessToken()}`

    let response
    if (params.method === 'GET') {
      response = await axios
        .get(url, { headers: params.headers, params: params.searchParams })
        .catch(e => e.response)
    }
    else if (params.method === 'POST') {
      response = await axios
        .post(url, params.body, { headers: params.headers })
        .catch(e => e.response)
    }
    else if (params.method === 'PUT') {
      response = await axios
        .put(url, params.body, { headers: params.headers })
        .catch(e => e.response)
    }
    else if (params.method === 'DELETE') {
      response = await axios
        .delete(url, { data: params.body, headers: params.headers })
        .catch(e => e.response)
    }
    if (response.status === 200)
      return response
  }
  else {
    return response
  }
  return response
}

async function GET(url, searchParams) {
  const headers = {
    'Authorization': `Bearer ${getAccessToken()}`,
    'Accept-language': i18n.locale,
  }

  const params = {
    method: 'GET',
    headers,
    searchParams,
  }

  return await makeRequest(url, params)
}

async function POST(url, body) {
  const headers = {
    'Authorization': `Bearer ${getAccessToken()}`,
    'Content-Type': 'application/json;charset=UTF-8;',
    'Accept-language': i18n.locale,
  }

  const params = {
    method: 'POST',
    headers,
    body,
  }
  return await makeRequest(url, params)
}

async function DELETE(url, body) {
  const headers = {
    'Authorization': `Bearer ${getAccessToken()}`,
    'Accept-language': i18n.locale,
  }

  const params = {
    method: 'DELETE',
    headers,
    body,
  }
  return await makeRequest(url, params)
}

async function PUT(url, body) {
  const headers = {
    'Authorization': `Bearer ${getAccessToken()}`,
    'Content-Type': 'application/json;charset=UTF-8;',
    'Accept-language': i18n.locale,
  }

  const params = {
    method: 'PUT',
    headers,
    body,
  }
  return await makeRequest(url, params)
}

export { makeRequest, setUserData, GET, POST, PUT, DELETE }
