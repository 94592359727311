<template>
  <div
    class="user-notifications-box"
    data-e2e="notification-btn"
    @click="MUT_setModal(true)"
  >
    <div
      class="notifirations-bell"
      :class="{ 'has-notifications': notificationCounter > 0 }"
    />
    <div v-if="notificationCounter > 0" class="notification-counter">
      {{ notificationCounter }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      name: 'Modal',
    }
  },
  methods: {
    ...mapMutations('globalNotifications', ['MUT_setModal']),
  },
  computed: {
    ...mapGetters('globalNotifications', [
      'notificationCounter',
      'isAddModalActive',
      'isModalActive',
    ]),
  },
}
</script>

<style scoped lang="scss">
.user-notifications-box {
  cursor: pointer;
  padding-right: 18px;
  margin-right: 16px;
  border-right: 1px solid #e8e8eb;
  position: relative;
  .notifirations-bell {
    background: url("../../assets/icons/bell.svg") no-repeat 50% 50%;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    &.has-notifications {
      animation: bell 2s infinite;
    }
  }
  .notification-counter {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #10083f;
    background: #ffd400;
    width: 16px;
    height: 16px;
    text-align: center;
    position: absolute;
    right: 14px;
    top: -5px;
    border-radius: 50%;
  }
}
@keyframes bell {
  0%,
  4%,
  10%,
  14%,
  20%,
  24%,
  30%,
  100% {
    transform: rotate(0);
  }
  2%,
  12%,
  22% {
    transform: rotate(10deg);
  }
  8%,
  18%,
  28% {
    transform: rotate(-10deg);
  }
}
</style>
